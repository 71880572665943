/**
 * @copyright 2019 @ DigiNet
 * @author THANHTRAM
 * @create 2019/09/05 14:00
 * @update 2019/09/05 14:00
 */

import React from 'react';
import {Row, FormGroup, Form} from 'react-bootstrap';
import notify from 'devextreme/ui/notify';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import ButtonSave from "../common/button/button-save";
import ButtonNotSave from "../common/button/button-notsave";
import Config from '../../config/index';
import {Col, Input} from "reactstrap";
import {CheckBox} from 'devextreme-react';
import PropTypes from 'prop-types';
import EventTracking from "../common/tracking/event";
import * as w17f1510Actions from "../../actions/w17/w17f1510/w17f1510-actions";
import {PhotoshopPicker} from 'react-color';

class W17F1511Page extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            isDisabled: false,
            displayColorPicker: false,
            background: this.props.data.Color,
            Disabled: !!this.props.data.Disabled,
            EvaluateTypeID: this.props.data.EvaluateTypeID,
            EvaluateTypeName: this.props.data.EvaluateTypeName,
            Remark: this.props.data.Remark,
            mode: this.props.data.mode,
        };
        this.timer = null;
        this.timeDeplay = Config.getSetting("TIME_SUBMIT_DELAY") || 2000;
        this.vlColor = this.props.data.Color;
    };

    componentWillUnmount() {
        if (this.timer) clearTimeout(this.timer);
    }

    componentDidMount() {
        this.setState({loading: true});
        this.timer = setTimeout(() => {
            this.setState({loading: false});
        }, this.timeDeplay);
    }

    onDisabledChanged(e) {
        this.setState({
            Disabled: e.value
        });
    }

    onHide() {
        if (this.props.onHide) {
            this.props.onHide();
        }
    }

    onReloadGrid() {
        if (this.props.onReloadGrid) {
            this.props.onReloadGrid();
        }
    }

    onSaveW17F1510() {
        const {data} = this.props;

        const evaluateTypeID = document.getElementById("EvaluateTypeID").value;
        const evaluateTypeName = document.getElementById("EvaluateTypeName").value;
        const remark = document.getElementById("Remark").value;
        const color = this.state.background;
        const disabled = !this.state.Disabled ? '0' : '1';

        if (data.mode === 'add') {
            if (!evaluateTypeID) {
                Config.popup.show('INFO', `${Config.lang("CRM_Ma_loai_danh_gia_khong_duoc_de_trong")}`);
                document.getElementById("EvaluateTypeID").focus();
                return false;
            }
            if (!evaluateTypeName) {
                Config.popup.show('INFO', `${Config.lang("CRM_Ten_loai_khong_duoc_de_trong")}`);
                document.getElementById("EvaluateTypeName").focus();
                return false;
            } else {
                const param = {
                    "EvaluateTypeID": evaluateTypeID,
                    "EvaluateTypeName": evaluateTypeName,
                    "Remark": remark,
                    "Disabled": disabled,
                    "Color": color,
                };
                this.props.w17f1510Actions.addW17F1511(param, (error, data) => {
                    if (error) {
                        let errorCode = error.code || null;
                        let message = "";
                        switch (errorCode) {
                            case "F1511E004":
                                message = Config.lang("CRM_Ma_nay_da_ton_tai");
                                Config.popup.show('INFO', message);
                                break;
                            default:
                                message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                                Config.popup.show('INFO', message);
                                break;
                        }
                        this.setState({isSubmit: false, message: message, loading: false});
                        return false;
                    } else {
                        this.setState({isSubmit: false, isDisabled: true});
                        notify(Config.lang("CRM_Luu_thanh_cong"), "success", 200);
                        clearTimeout(this.timer);
                        this.timer = setTimeout(() => {
                            this.setState({isSubmit: false});
                            this.onHide();
                            this.onReloadGrid();
                        }, this.timeDeplay);
                    }
                });
            }
        } else {
            const param = {
                "EvaluateTypeID": evaluateTypeID,
                "EvaluateTypeName": evaluateTypeName,
                "Remark": remark,
                "Disabled": disabled,
                "Color": color,
            };
            this.props.w17f1510Actions.editW17F1511(param, (error, data) => {
                if (error) {
                    let message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                    this.setState({isSubmit: false});
                    Config.popup.show("INFO", message);
                    return false;
                } else {
                    this.setState({isSubmit: false});
                    notify(Config.lang("CRM_Luu_thanh_cong"), "success", 200);
                    clearTimeout(this.timer);
                    this.timer = setTimeout(() => {
                        this.setState({isSubmit: false});
                        this.onHide();
                        this.onReloadGrid();
                    }, this.timeDeplay);
                }
            });
        }
    }

    handleClick = () => {
        this.setState({
            displayColorPicker: true
        });
    };

    handleClose = (color) => {
        this.setState({
            background: color ? color : this.state.background,
            displayColorPicker: false
        })
    };

    handleChangeComplete = (color) => {
        this.vlColor = color.hex;
    };

    render() {
        const {data} = this.props;
        return (
            <div className="page-container">
                {Config && Config.profile &&
                <EventTracking category={"TRACKING_USER"} action={"W18F1041"} label={Config.profile.UserID}/>}

                <Form horizontal={true} className={'mgt5'}>
                    <FormGroup>
                        <Col xs={12} sm={12} md={3} lg={2} xl={2}>
                            <label className={'control-label'}>{Config.lang("CRM_Ma_loai_danh_gia")}<i
                                className="error">*</i></label>
                        </Col>
                        <Col xs={12} sm={12} md={3} lg={4} xl={4}>
                            <Input id={'EvaluateTypeID'}
                                   onPaste={(e) => {
                                       document.getElementById('EvaluateTypeID').value = Config.replaceSymbol(e.target.value);
                                   }}

                                   onChange={(e) => {
                                       document.getElementById('EvaluateTypeID').value = Config.replaceSymbol(e.target.value);
                                   }}
                                   disabled={data && (data.mode === 'detail' || data.mode === 'edit')}
                                   defaultValue={this.state.EvaluateTypeID}/>
                        </Col>
                        <Col xs={12} sm={12} md={3} lg={2} xl={2}>
                            <label className={'control-label'}>{Config.lang("CRM_Ten_loai_danh_gia")}<i
                                className="error">*</i></label>
                        </Col>
                        <Col xs={12} sm={12} md={3} lg={4} xl={4}>
                            <Input id={'EvaluateTypeName'}
                                   disabled={data.mode === 'detail'}
                                   defaultValue={this.state.EvaluateTypeName}
                            />
                        </Col>

                    </FormGroup>

                    <Row className="form-group">
                        <Col xs={4} sm={4} md={3} lg={2} xl={2}>
                            <label className={'control-label'}>{Config.lang("CRM_Mau_sac")}</label>
                        </Col>
                        <Col xs={4} sm={4} md={3} lg={2} xl={2} className="mgb15 valign-top group-dropdown">
                            <div>
                                <div className="evaluateTypeColor" style={{backgroundColor: this.state.background}}
                                     onClick={this.handleClick}
                                />
                            </div>
                            <div className="evaluateTypeHandle">
                                <img className="evaluateTypeImg" alt=''
                                     style={{position:'relative'}}
                                     src={require('../../images/color.jpg')}
                                     onClick={this.handleClick}
                                />
                                {this.state.displayColorPicker &&
                                    <div style={{
                                        position: 'absolute',
                                        zIndex: 2
                                    }}>
                                        <div style={{cursor: 'pointer', zIndex:1}}>
                                            <PhotoshopPicker color={this.vlColor}
                                                             onChangeComplete={this.handleChangeComplete}
                                                             onAccept={()=>this.handleClose(this.vlColor)}
                                                             onCancel={this.handleClose}
                                            />
                                        </div>
                                    </div>
                                }
                            </div>
                        </Col>
                        <Col xs={4} sm={4} md={3} lg={2} xl={2}>
                            <CheckBox
                                elementAttr={{
                                    class: "control-label text-bold"
                                }}
                                width={200}
                                text={Config.lang("CRM_Khong_su_dung")}
                                id={"Disabled"}
                                disabled={data.mode === 'detail'}
                                onValueChanged={(e) => this.onDisabledChanged(e)}
                                value={this.state.Disabled}
                            />
                        </Col>
                    </Row>
                    <FormGroup>
                        <Col xs={12} sm={12} md={3} lg={2} xl={2}>
                            <label className={'control-label'}>{Config.lang("CRM_Mo_ta")}</label>
                        </Col>
                        <Col xs={12} sm={12} md={9} lg={10} xl={10}>
                            <Input id={'Remark'} type={'textarea'} rows={4}
                                   disabled={data.mode === 'detail'}
                                   defaultValue={this.state.Remark}/>
                        </Col>
                    </FormGroup>
                    <FormGroup className={"jus-end"}>
                        <div>
                            <ButtonSave disabled={data.mode === 'detail' || this.state.isDisabled}
                                        onClick={() => this.onSaveW17F1510()}
                                        className={"mgr5"}
                                        name={Config.lang("CRM_Luu")}
                            />
                            <ButtonNotSave className={'mgr20'}
                                           disabled={data && data.mode === 'detail'}
                                           name={Config.lang("CRM_Khong_luu")}
                                           onClick={() => {
                                               this.props.onHide()
                                           }}
                            />
                        </div>
                    </FormGroup>
                </Form>
            </div>
        )
    }
}

W17F1511Page.propTypes = {
    onHide: PropTypes.func,
    onReloadGrid: PropTypes.func
};

export default connect(state => ({
        addW17F1511: state.w17f1510.addW17F1511,
        editW17F1511: state.w17f1510.editW17F1511,
    }),
    (dispatch) => ({
        w17f1510Actions: bindActionCreators(w17f1510Actions, dispatch)
    })
)(W17F1511Page);
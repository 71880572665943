/**
 * @copyright 2021 @ DigiNet
 * @author ANHTAI
 * @create 08/03/2021
 * @update 15/03/2021
 */
import { Column, Lookup } from "devextreme-react/data-grid";
import _ from "lodash";
import PropTypes from 'prop-types';
import React from 'react';
import notify from "devextreme/ui/notify";
import { Col, Form, FormGroup } from 'react-bootstrap';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import getCboUnit from "../../actions/w17/w17F2042/cbounit-async";
import * as w17F2042Actions from "../../actions/w17/w17F2042/w17f2042-actions";
import Config from "../../config";
import ButtonSave from "../common/button/button-save";
import Combo from "../common/dropdown/combo";
import GridContainer from "../common/grid-container/grid-container";
import EventTracking from "../common/tracking/event";
import PopupScroll from "../popup/popup-scroll";

class W17F2042Page extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            totalDataGrid: 0,
            dataGrid: [],
            dataCboUnitID: [],
            dataCboInventoryID: [],
        }
        this.filter = {
            skip: 0,
            limit: 10,
        };
        this.Language = Config.language || "84";
    }

    componentDidMount = () => {
        this.loadDataGrid();
    }

    loadDataGrid = () => {
        const { divisionID = "", formID = "", type = "", voucherID = "", linkMaster = "" } = this.props;
        const paramGrid = {
            Type: type,
            FormID: formID,
            VoucherID: voucherID,
            DivisionID: divisionID,
            LinkMaster: linkMaster,
            Language: this.Language,
        };
        this.setState({ loading: true });
        this.props.w17F2042Actions.loadGridW17F2042(paramGrid, (error, data) => {
            if (error) {
                this.setState({ loading: false });
                const message = error.message || "Lỗi chưa xác định";
                Config.popup.show("INFO", message);
            } else if (data) {
                this.setState({
                    dataGrid: data,
                    totalDataGrid: data.length || 0,
                    loading: false
                });
                this.loadComboInventoryID(); // Load Cbo Inventory
            }
        })
    }

    loadComboInventoryID = () => {
        const { divisionID = "", formID = "", type = "", voucherID = "" } = this.props;
        const paramGrid = {
            Type: type,
            FormID: formID,
            VoucherID: voucherID,
            DivisionID: divisionID,
            Language: this.Language,
        };
        this.props.w17F2042Actions.loadComboInventoryID(paramGrid, (error, data) => {
            if (error) {
                const message = error.message || "Lỗi chưa xác định";
                Config.popup.show("INFO", message);
            } else if (data) {
                const dataCboCustom = data.map(item => {
                    const line = !_.isEmpty(item.InventoryName) ? "-" : "";
                    return ({ ...item, InventoryCustomName: `${item.InventoryID} ${line} ${item.InventoryName}` });
                });
                this.setState({
                    dataCboInventoryID: dataCboCustom
                });
            }
        })
    }

    onHide = (reloadStatus = false) => {
        if (this.props.onHide) this.props.onHide(reloadStatus);
    }

    onChangePage = (page) => {
        this.filter.skip = page * this.filter.limit;
        this.loadDataGrid();
    }

    onChangePerPage = (per) => {
        this.filter.skip = 0;
        this.filter.limit = per;
        this.loadDataGrid();
    }

    saveData = () => {
        const { dataGrid = [] } = this.state;
        let checkReqiredColumn = false;
        dataGrid.forEach(item => {
            const { InventoryID, UnitID } = item;
            if (_.isEmpty(InventoryID) || _.isEmpty(UnitID)) {
                checkReqiredColumn = true;
            }
        });
        if (checkReqiredColumn) {
            Config.popup.show("INFO", Config.lang("CRM_Vui_long_chon_ma_hang_va_don_vi_tinh"));
            return false;
        }
        const { divisionID = "", formID = "", type = "", voucherID = "" } = this.props;
        const paramGrid = {
            Type: type,
            FormID: formID,
            VoucherID: voucherID,
            DivisionID: divisionID,
            Language: this.Language,
            data: JSON.stringify(dataGrid)
        };
        this.setState({ loading: true });
        this.props.w17F2042Actions.saveData(paramGrid, (error, data) => {
            this.setState({ loading: false });
            if (error) {
                const message = error.message || "Lỗi chưa xác định";
                Config.popup.show("INFO", message);
            } else if (data) {
                if (data.Status === 0) { // Lưu thành công 
                    const message = data.Message || Config.lang("CRM_Luu_thanh_cong");
                    notify(message, "success", 400);
                    this.onHide(true); // Đóng form và reLoad Form
                } else if (data.Status === 1 && data.Message) { // Xuất message
                    const message = data.Message || Config.lang("CRM_Loi_chua_xac_dinh");
                    Config.popup.show("INFO", message);
                }
            }
        })
    }

    renderCboUnit = (e, paramUnitID) => {
        if (!e.row) return;
        if (e.dataField === "UnitID") {
            const { row } = e;
            let id = e.dataField;
            let name = `${e.dataField}Name}`;
            e.editorOptions.onValueChanged = (arg) => {
                row.data[id] = arg.value;
                const dataSource = arg.component.option("dataSource");
                const nameFilter = dataSource.filter((value) => {
                    return value[id] === arg.value;
                });
                if (nameFilter.length > 0) {
                    row.data[name] = nameFilter[0][name];
                }
            };
            e.editorOptions.onInitialized = async function (arg) {
                let loadDataSource;
                if (id === "UnitID" && row.data.InventoryID) {
                    paramUnitID.InventoryID = row.data.InventoryID || "";
                    loadDataSource = await getCboUnit(paramUnitID);
                }
                if (loadDataSource && loadDataSource.code === 200) {
                    let data = loadDataSource.data;
                    arg.component.option("dataSource", data);
                }
            }
        }
    }

    render() {
        const { dataGrid, totalDataGrid, dataCboInventoryID, dataCboUnitID, loading } = this.state;
        const { divisionID = "", formID = "", type = "", voucherID = "" } = this.props;
        const paramUnitID = {
            DivisionID: divisionID,
            FormID: formID,
            VoucherID: voucherID,
            Type: type,
            Language: this.Language,
        };
        return (
            <PopupScroll
                show={true}
                animation={true}
                className={"popup-size-80"}
                title={Config.lang("CRM_Cap_nhat_ma_hang")}
                button={
                    <span style={{ marginLeft: 30 }}>
                        <ButtonSave
                            name={Config.lang("CRM_Luu")}
                            disabled={loading}
                            className={"mgr5"}
                            onClick={() => this.saveData()} />
                    </span>
                }
                onHide={this.onHide}>
                {Config && Config.profile &&
                    <EventTracking category={"TRACKING_USER"} action={"W17F2042"} label={Config.profile.UserID} />}
                <div className={"page-container"} id={"frmW17F2042"}>
                    <Form horizontal={true}>
                        <FormGroup>
                            <Col md={12} sm={12} xs={12} lg={12}>
                                <GridContainer
                                    columnAutoWidth={true}
                                    loading={loading}
                                    dataSource={dataGrid}
                                    itemPerPage={this.filter.limit}
                                    totalItems={totalDataGrid}
                                    onEditorPreparing={(e) => {
                                        this.renderCboUnit(e, paramUnitID);
                                    }}
                                    onChangePage={(page) => {
                                        this.onChangePage(page)
                                    }}
                                    onChangePerPage={(per) => {
                                        this.onChangePerPage(per)
                                    }}

                                    editing={{
                                        mode: "cell",
                                        refreshMode: "reshape",
                                        allowUpdating: true,
                                        texts: { confirmDeleteMessage: "" },
                                        startEditAction: "click",
                                    }} >
                                    <Column
                                        dataField={"InventoryName"}
                                        caption={Config.lang("CRM_Ten_hang")}
                                        allowSorting={true}
                                        allowEditing={false}
                                        allowReordering={false}
                                    />
                                    <Column
                                        dataField={"Notes"}
                                        caption={Config.lang("CRM_Dien_giai")}
                                        allowEditing={false}
                                        width={200}
                                    />
                                    <Column
                                        width={250}
                                        dataField={"InventoryID"}
                                        caption={Config.lang("CRM_Ma_hang")}
                                        editCellRender={(e) => {
                                            return (
                                                <Combo
                                                    wordWrapEnabled={true}
                                                    showHeader={false}
                                                    virtual={true}
                                                    placeholder={""}
                                                    dataSource={Config.storeDataSoureDevExtreme(dataCboInventoryID)}
                                                    displayExpr={"InventoryID"}
                                                    value={e.value}
                                                    onActionWhenSelectChange={(rs) => {
                                                        e.setValue(rs && rs.InventoryID ? rs.InventoryID : "");
                                                        e.component.cellValue(e.rowIndex, "InventoryID", rs && rs.InventoryID ? rs.InventoryID : "");
                                                        e.component.cellValue(e.rowIndex, "UnitID", ""); // Clear Data CboUnitID
                                                    }}
                                                >
                                                    <Column
                                                        dataField={"InventoryCustomName"}
                                                        width={"auto"}
                                                        dataType={"string"} />
                                                </Combo>
                                            );
                                        }}
                                    />


                                    <Column
                                        dataField={"UnitID"}
                                        caption={Config.lang("CRM_DVT")}
                                        width={450}
                                        cellRender={e => {
                                            return (
                                                <span>{e.data.UnitID}</span>
                                            )
                                        }}
                                    >
                                        <Lookup
                                            valueExpr={"UnitID"}
                                            displayExpr={"UnitID"}
                                            dataSource={dataCboUnitID}
                                        />
                                    </Column>
                                </GridContainer>
                            </Col>
                        </FormGroup>
                    </Form>
                </div>
            </PopupScroll>
        )
    }
}

W17F2042Page.propTypes = {
    mode: PropTypes.string,
    formID: PropTypes.string,
    dataType: PropTypes.string,
    type: PropTypes.string,
    tranTypeID: PropTypes.string,
    linkMaster: PropTypes.string,
    onHide: PropTypes.func,
};
export default connect(state => ({}),
    (dispatch) => ({
        w17F2042Actions: bindActionCreators(w17F2042Actions, dispatch)
    }))(W17F2042Page);
/**
 * @copyright 2021 @ DigiNet
 * @author ANHTAI
 * @create 13/08/2021
 * @update 13/08/2021
 * @file src/components/header/header.js
 */
import { withStyles } from "@material-ui/styles";
import { Column } from "devextreme-react/data-grid";
import notify from "devextreme/ui/notify";
import _ from "lodash";
import "moment/locale/vi";
import PropTypes from "prop-types";
import React from "react";
import { Col, FormGroup, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import FileDeleteAsync from "../../actions/file-delete-async";
import FileRemoveAsync from "../../actions/file-remove-async";
import FileUploadAsync from "../../actions/file-upload-new-async";
import * as w17f2035Actions from "../../actions/w17/w17F2035/w17F2035-actions";
import Config from "../../config";
import ButtonSave from "../common/button/button-save";
import GridContainer from "../common/grid-container/grid-container";
import { Loading } from "../common/loading/loading";
import PopupScroll from "../popup/popup-scroll";

const styles = {
    ellipsis: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
};

class W17F2035 extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            gridLoading: false,
            disabled: false,
            totalGrid: 0,
            dataGrid: [],
        };
        this.filter = {
            Skip: 0,
            Limit: 10,
            Language: Config.language || "84",
            FormID: _.get(props, "formID", ""),
            Type: _.get(props, "type", ""),
            VoucherID: _.get(props, "voucherID", ""),
            InventoryID: _.get(props, "inventoryID", ""),
        }
        this.gridContainer = null;
        this.oldDataAttached = [];
        this.dataAttachDeleted = [];
    };

    componentWillUnmount = () => {
        this.setState = (state, callback) => {
            return;
        };
    };

    componentDidMount = () => {
        this.loadGridImage();
    };

    loadGridImage = () => {
        const { Language, FormID, Type, VoucherID, InventoryID, Skip, Limit } = this.filter;
        const param = {
            Language,
            FormID,
            Type,
            VoucherID,
            InventoryID,
            Skip,
            Limit
        };
        this.setState({ gridLoading: true });
        this.props.w17f2035Actions.loadGridImage(param, (error, data) => {
            this.setState({ gridLoading: false });
            if (error) {
                Config.popup.show("INFO", _.get(error, "message", Config.lang("Loi_chua_xac_dinh")));
                return false;
            } else if (data) {
                let dataGrid = [...data.detail];
                let dataAttached = [...data.attached];
                if (!_.isEmpty(dataAttached)) {
                    this.oldDataAttached = dataAttached;
                    dataGrid.forEach(item => {
                        item.dataImage = dataAttached.filter(att => att.Key2ID === item.QuotationItemID);
                    });
                }
                this.setState({ dataGrid, totalGrid: _.get(data, "total", 0) });
            }
        })
    };

    onChangePage(page) {
        this.filter.Skip = page * this.filter.Limit;
        this.loadGridImage();
    };

    onChangePerPage(per) {
        this.filter.Skip = 0;
        this.filter.Limit = per;
        this.loadGridImage();
    };

    onHide = () => {
        if (this.props.onClose) this.props.onClose();
    };

    checkFileType = (filetype) => {
        const allowedExtensions = [
            "image/jpeg",
            "image/png",
        ];
        if (filetype.length > 0) {
            if (allowedExtensions.indexOf(filetype) === -1) {
                return false;
            }
            return true;
        } else {
            return false;
        }
    };

    upLoadAttach = async (e, data) => {
        if (!e) return;
        const files = _.get(e, "target.files", []);
        const sizeLimit = 20;
        if (files.length === 0) {
            return null;
        } else if (this.checkFileType(files[0].type) === false) {
            Config.popup.show("INFO", `${Config.lang("Dinh_dang_file_khong_hop_le")}`);
        } else if ((files[0].size / 1024 / 1024) > Number(sizeLimit)) {
            Config.popup.show("INFO", `${Config.lang("Dung_luong_File_khong_duoc_lon_hon")} ${sizeLimit} MB`);
        } else {
            let dataAttach = _.get(data, "data.dataImage", []);
            for (let file of files) {
                file.uploading = true;
                dataAttach.push({ file: file, FileName: file.name, URL: window.URL.createObjectURL(file), isAddNew: true });
            }
            this.gridContainer.instance.cellValue(data.rowIndex, "dataImage", dataAttach);
            this.gridContainer.instance.saveEditData();
        }
    };

    deleteAttach = (file, data) => {
        if (!file || !data) return;
        Config.popup.show("YES_NO", Config.lang("Ban_co_chac_chan_muon_xoa_du_lieu_nay"), () => {
            if (this.oldDataAttached.some(item => item.KeyID === file.KeyID)) {
                this.dataAttachDeleted.push(file);
            }
            const dataAttachFilter = _.get(data, "value", []).filter((value) => value.URL !== file.URL);
            this.gridContainer.instance.cellValue(data.rowIndex, "dataImage", dataAttachFilter);
        });
    };

    renderBtnAttach = (e) => {
        if (!e) return;
        const inputID = `attachmentIndex${e.rowIndex}`;
        return (
            <>
                <div style={{ cursor: 'pointer' }} onClick={() => {
                    const getCurrentInputID = document.getElementById(inputID);
                    if (getCurrentInputID) getCurrentInputID.click();
                }}> <i className={`fas fa-cloud-upload text-green`} /> </div>
                <input
                    id={inputID}
                    type={"file"}
                    accept={"image/*"}
                    multiple={"multiple"}
                    className={"media_upload_input hide"}
                    onChange={(c) => this.upLoadAttach(c, e)}
                />
            </>
        )
    };

    renderAttachImage = (e) => {
        const dataImage = _.get(e, "data.dataImage", []);
        const { classes } = this.props;
        return (
            <div className={"mg0 display_row list-attach"} style={{ minHeight: 94, padding: 0, border: 'none' }}>
                {dataImage && dataImage.map((value, index) => {
                    if (value.uploading) {
                        return (<div className={"view-loading"}>
                            <i key={index}
                                className={"fas fa-spinner fa-spin"} />
                        </div>)
                    } else return (
                        <label className={"view-attachment mgr5 control-label small "}
                            key={"view-attachment1" + index}>
                            <img alt={Config.lang("Hinh_anh")} src={value.URL} className={"img-background"} />
                            <span title={value.FileName} className={classes.ellipsis}>{value.FileName}</span>
                            <i className={this.state.disabled === true ? "fas fa-trash-alt text-red deleteAttach hide" : "fas fa-trash-alt text-red deleteAttach"}
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                    this.deleteAttach(value, e)
                                }} />
                        </label>
                    );
                })}
            </div>
        )
    };

    onSave = async () => {
        let countDataSave = 0;
        await this.gridContainer.instance.saveEditData();
        const dataGrid = this.gridContainer.instance.option("dataSource");
        let allDataImage = [];
        dataGrid.forEach(item => {
            if (item.dataImage) {
                const dataAdd = item.dataImage.filter(itemAdd => itemAdd.isAddNew === true);
                if (!_.isEmpty(dataAdd)) allDataImage.push(...dataAdd);
            }
        });
        if (allDataImage.length === 0 && _.isEmpty(this.dataAttachDeleted)) {
            Config.popup.show("INFO", Config.lang("Vui_long_chon_hinh_anh"));
        } else {
            if (dataGrid.length > 0) {
                dataGrid.forEach(async item => {
                    const { QuotationID = "", QuotationItemID = "", dataImage = [] } = item;
                    const dataAddNew = dataImage.filter(item => item.isAddNew === true);
                    for (let i = 0; i < dataAddNew.length; i++) { // Lưu thếm mới hình ảnh
                        const exp = dataAddNew[i].file.name.split(".");
                        const paramAttach = {
                            KeyID: QuotationID,
                            Key2ID: QuotationItemID,
                            FileName: dataAddNew[i].file.name,
                            FileSize: dataAddNew[i].file.size,
                            FileExp: exp[exp.length - 1],
                            TableName: "D05T0017"
                        };
                        const upload = await FileUploadAsync(dataAddNew[i].file, paramAttach);
                        countDataSave += 1;
                        if (upload && upload.code === 200) {
                            dataAddNew[i].AttachmentID = upload.KeyID;
                            if (countDataSave === allDataImage.length) {
                                notify(Config.lang("Luu_thanh_cong"), "success", 2000);
                                this.onHide();
                            }
                        } else {
                            Config.popup.show("INFO", _.get(upload, "message", Config.lang("Loi_chua_xac_dinh")));
                            break;
                        }
                    }
                });
                if (this.dataAttachDeleted.length > 0) { // Xóa hình ảnh
                    let paramDeleteAttach;
                    let paramRemoveAttach;
                    for (let i = 0; i < this.dataAttachDeleted.length; i++) {
                        paramDeleteAttach = {
                            AttachmentID: this.dataAttachDeleted[i].AttachmentID
                        };
                        paramRemoveAttach = {
                            path: this.dataAttachDeleted[i].URL
                        };
                        await Promise.all([
                            FileDeleteAsync(paramDeleteAttach),
                            FileRemoveAsync(paramRemoveAttach)
                        ]).then(() => {
                            if (_.isEmpty(allDataImage)) {
                                notify(Config.lang("Luu_thanh_cong"), "success", 2000);
                                this.onHide();
                            }
                        }).catch((err) => {
                            Config.popup.show("INFO", err || _.get(err, "message", Config.lang("Loi_chua_xac_dinh")));
                            return false;
                        });
                    }
                    this.dataAttachDeleted.length = 0;
                }
            }
        }
    };

    render() {
        const { classes } = this.props;
        const { dataGrid, gridLoading, totalGrid } = this.state;
        return (
            <>
                <PopupScroll
                    show={true}
                    animation={true}
                    className={"popup-size-80"}
                    title={Config.lang("Them_hinh_anh_mat_hang")}
                    onHide={this.onHide}
                    button={
                        <span style={{ display: 'inline-flex', margin: '10px 10px 10px 20px' }}>
                            <ButtonSave
                                onClick={this.onSave}
                                name={Config.lang("Luu")}>
                            </ButtonSave>
                        </span>
                    }>
                    {gridLoading && <Loading />}
                    <div className={"page-container"}>
                        <FormGroup>
                            <Row>
                                <Col md={12} sm={12} xl={12} xs={12} lg={12}
                                    className={classes.gridContainer}>
                                    <GridContainer
                                        reference={(e) => this.gridContainer = e}
                                        totalItems={totalGrid}
                                        dataSource={dataGrid}
                                        allowColumnResizing={true}
                                        pagerFullScreen={false}
                                        showBorders={false}
                                        columnAutoWidth={true}
                                        itemPerPage={this.filter.Limit}
                                        onChangePage={(page) => {
                                            this.onChangePage(page)
                                        }}
                                        onChangePerPage={(per) => {
                                            this.onChangePerPage(per)
                                        }}
                                    >
                                        <Column
                                            width={250}
                                            dataField={"InventoryName"}
                                            caption={Config.lang("Ten_hang")}
                                        />
                                        <Column
                                            width={200}
                                            dataField={"InventoryID"}
                                            caption={Config.lang("Ma_hang")}
                                        />
                                        <Column
                                            width={150}
                                            dataField={"UnitID"}
                                            caption={Config.lang("DVT")}
                                        />
                                        <Column
                                            width={50}
                                            allowSorting={false}
                                            cellRender={this.renderBtnAttach}
                                        />
                                        <Column
                                            allowSorting={false}
                                            dataField={"dataImage"}
                                            cellRender={this.renderAttachImage}
                                            caption={Config.lang("Dinh_kem_hinh_anh")}
                                        />
                                    </GridContainer>
                                </Col>
                            </Row>
                        </FormGroup>
                    </div>
                </PopupScroll>
            </>
        )
    }
}

W17F2035.propTypes = {
    formID: PropTypes.string,
    type: PropTypes.string,
    voucherID: PropTypes.string,
    inventoryID: PropTypes.string,
    onClose: PropTypes.func
};

export default compose(connect(null,
    (dispatch) => ({
        w17f2035Actions: bindActionCreators(w17f2035Actions, dispatch),
    })), withStyles(styles, { withTheme: true }))(W17F2035);

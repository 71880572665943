/**
 * @copyright 2019 @ DigiNet
 * @author THANHTRAM
 * @create 2019/09/04 16:50
 * @update 2019/09/04 16:50
 */

import React from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";

import Config from '../../config/index';
import * as w17f1510Actions from "../../actions/w17/w17f1510/w17f1510-actions";
import * as userActions from "../../actions/user-actions";
import EventTracking from "../common/tracking/event";
import {Column} from "devextreme-react/data-grid";
import GridContainer from "../common/grid-container/grid-container";
import GridActionBar from "../common/action-bar/grid-action-bar";
import PopupView from "../popup/popup-view";
import DetailW17F1510 from "../W17/W17F1511";
import notify from "devextreme/ui/notify";

class W17F1510Page extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            iPermission: 0,
            showGrid: false,
            showPopup: false,
        };
        this.filter = {
            skip: 0,
            limit: 20,
            search: ''
        };
        this.dataSource = [];
        this.totalRecord = 0;

    };

    componentDidMount = async () => {
        await this.loadPermission();
        if(this.state.iPermission <= 0) return;
        this.loadGrid();
    };

    async loadPermission() {
        await this.props.userActions.getPermission(Config.profile.UserID, "D17F1510",(iPer)=> {
            this.setState({iPermission: iPer});
        });
    }

    loadGrid() {
        const disabled = !this.state.showGrid ? '0' : '1';
        const param = {
            "search": this.filter.search,
            "skip": this.filter.skip,
            "limit": this.filter.limit,
            "Disabled": disabled,
        };
        this.props.w17f1510Actions.loadW17F1510(param, (error, data) => {
            if (error) {
                let errorCode = error.code || null;
                let message = "";
                switch (errorCode) {
                    default:
                        message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                        Config.popup.show('INFO', message);
                        break;
                }
                this.setState({isSubmit: false, message: message, loading: false});
                return false;
            } else if (data && data.data && data.data.rows && data.data.rows.length> 0) {
                this.dataSource = data.data.rows.map(o => {
                    const cmbEvaluate = {
                        Color: o.Color,
                        Disabled: o.Disabled,
                        EvaluateTypeID: o.EvaluateTypeID,
                        EvaluateTypeName: o.EvaluateTypeName,
                        Remark: o.Remark,
                    };
                    return cmbEvaluate;
                });
                this.totalRecord = data.data.total;
            } else {
                this.dataSource = [];
                this.totalRecord = 0;
            }
        })
    }

    onAddNew() {
        const rowNew = {
            Color: 'white',
            Disabled: 0,
            EvaluateTypeID: '',
            EvaluateTypeName: '',
            Remark: '',
            mode: 'add'
        };
        this.setState({row: rowNew, showPopup: true});
    }

    renderAction(e) {
        if (!e) return null;
        const {iPermission} = this.state;
        return (
            <GridActionBar
                isPer={iPermission}
                tooltipEdit={Config.lang("CRM_Sua")}
                onEditAction={() => {
                    this.onEdit(e)
                }}

                tooltipDelete={Config.lang("CRM_Xoa")}
                onDeleteAction={() => {
                    this.deleteRow(e)
                }}/>
        );
    }

    onEdit(row) {
        const rowEdit = {
            Color: row.data.Color,
            Disabled: row.data.Disabled,
            EvaluateTypeID: row.data.EvaluateTypeID,
            EvaluateTypeName: row.data.EvaluateTypeName,
            Remark: row.data.Remark,
            mode: 'edit'
        };
        this.setState({row: rowEdit, showPopup: true});
    }

    deleteRow(row) {
        const evaluateTypeID = row && row.data && row.data.EvaluateTypeID ? row.data.EvaluateTypeID : '';
        Config.popup.show("YES_NO", `${Config.lang("CRM_Ban_co_chac_chan_muon_xoa_du_lieu_nay")}`, () => {
            const param = {
                "EvaluateTypeID": evaluateTypeID
            };
            this.props.w17f1510Actions.delW17F1511(param, (error) => {
                if (error) {
                    let errorCode = error.code || null;
                    let message = "";
                    switch (errorCode) {
                        case "F1510E002":
                            message = Config.lang("CRM_Ma_nay_da_duoc_su_dung_ban_khong_duoc_phep_xoa");
                            Config.popup.show('INFO', message);
                            break;
                        default:
                            message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                            Config.popup.show('INFO', message);
                            break;
                    }
                    this.setState({isSubmit: false, message: message, loading: false});
                    return false;
                } else {
                    notify(Config.lang("CRM_Du_lieu_da_xoa_thanh_cong"), "success", 2000);
                    this.loadGrid();
                }
            });
        });
    }

    renderEvaluateTypeName(data) {
        if (!data) return null;
        const name = data && data.value ? data.value : '';

        /* eslint-disable */
        return (
            <div><a onClick={() => this.onDetail(data.data)}>{name}</a></div>
        );
        /* eslint-enable */
    }

    onDetail(row) {
        const rowDetail = {
            Color: row.Color,
            Disabled: row.Disabled,
            EvaluateTypeID: row.EvaluateTypeID,
            EvaluateTypeName: row.EvaluateTypeName,
            Remark: row.Remark,
            mode: 'detail'
        };
        this.setState({row: rowDetail, showPopup: true});
    }

    hideAddNewPopup() {
        this.setState({showPopup: false});
        this.loadGrid();
    }

    onSearch(text) {
        this.filter.skip = 0;
        this.filter.search = text;
        this.loadGrid();
    }

    onChangePage(page) {
        this.filter.skip = page * this.filter.limit;
        this.loadGrid();
    }

    onChangePerPage(per) {
        this.filter.skip = 0;
        this.filter.limit = per;
        this.loadGrid();
    }

    render() {
        return (
            <div className="page-container">
                {Config && Config.profile &&
                <EventTracking category={"TRACKING_USER"} action={"W17F1555"} label={Config.profile.UserID}/>}
                <GridContainer
                    title={Config.lang("CRM_Danh_muc_danh_gia")}
                    dataSource={this.dataSource}
                    onAddNew={() => {
                        this.onAddNew()
                    }}
                    isPer={4}
                    totalItems={this.totalRecord}
                    itemPerPage={this.filter.limit}
                    onSearch={(text) => {
                        this.onSearch(text)
                    }}
                    onChangePage={(page) => {
                        this.onChangePage(page)
                    }}
                    onChangePerPage={(per) => {
                        this.onChangePerPage(per)
                    }}
                    buttonCRM={[
                        {
                            buttonType: "check",
                            dataFieldLb: '',
                            buttonName: `${Config.lang("CRM_Hien_thi_khong_su_dung")}`,
                            className: 'pdr10',
                            align: 'right',
                            onValueChanged:(e)=>{
                                this.setState({
                                    showGrid:e.value
                                },()=>{this.loadGrid()})
                            }
                        },
                    ]}
                >
                    <Column
                        cellRender={(data) => this.renderAction(data)}
                        dataField={'Action'} caption={Config.lang("CRM_Hanh_dong")}
                        alignment={'center'}
                        allowSorting={false}
                        showInColumnChooser={false}
                        width={120}
                        fixed={true}
                    />
                    <Column dataField={'EvaluateTypeID'} caption={Config.lang("CRM_Ma_loai_danh_gia")} width={300} dataType={'string'}/>
                    <Column dataField={'EvaluateTypeName'} caption={Config.lang("CRM_Ten_loai_danh_gia")}
                            cellRender={(data) => this.renderEvaluateTypeName(data)}
                            minWidth={400} width={"100%"}/>
                    <Column dataField={'Remark'} caption={Config.lang("CRM_Ghi_chu")} width={400}
                            dataType={'string'}/>
                    <Column
                        dataField={'Color'}
                        caption={Config.lang("CRM_Mau_sac")}
                        alignment={'center'}
                        width={200}
                        cellRender={(e) => {
                            return <div><i className="fas fa-square pd5" style={{
                                fontSize: '18px',
                                color: e && e.data && e.data.Color,
                            }}/></div>
                        }}
                        allowSorting={true}
                    />
                    <Column dataField={'Disabled'} caption={Config.lang("CRM_Khong_su_dung")}
                            dataType={'boolean'}/>
                </GridContainer>
                {this.state.showPopup && <PopupView
                    title={Config.lang("CRM_Cap_nhat_loai_danh_gia")}
                    className={'popup-size-70'}
                    show={true}
                    animation={true}
                    onHide={() => this.hideAddNewPopup()}>
                    <DetailW17F1510 data={this.state.row} onHide={() => this.hideAddNewPopup()}
                                    onReloadGrid={() => this.loadGrid()}/>
                </PopupView>}
            </div>
        )
    }
}

export default connect(state => ({
        loadW17F1510: state.w17f1510.loadW17F1510,
        delW17F1511: state.w17f1510.delW17F1511,
    }),
    (dispatch) => ({
        w17f1510Actions: bindActionCreators(w17f1510Actions, dispatch),
        userActions: bindActionCreators(userActions, dispatch)
    })
)(W17F1510Page);
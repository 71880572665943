/**
 * @copyright 2021 @ DigiNet
 * @author ANHTAI
 * @create 05/03/2021
 * @update 05/03/2021
 * @file src/components/header/header.js
 */
import { DateBox } from "devextreme-react";
import { Column, Lookup, Summary, TotalItem } from "devextreme-react/data-grid";
import notify from "devextreme/ui/notify";
import _ from "lodash";
import moment from "moment";
import "moment/locale/vi";
import React from "react";
import NumberFormat from "react-number-format";
import { connect } from "react-redux";
import { browserHistory } from "react-router";
import { Input } from "reactstrap";
import { bindActionCreators, compose } from "redux";
import FileDeleteAsync from "../../actions/file-delete-async";
import FileRemoveAsync from "../../actions/file-remove-async";
import FileUploadAsync from "../../actions/file-upload-new-async";
import * as userActions from "../../actions/user-actions";
import * as w17f2040Actions from "../../actions/w17/w17F2040/w17f2040-actions";
import Config from "../../config";
import GridActionBar from "../common/action-bar/grid-action-bar";
import GridActionBarHover from "../common/action-bar/grid-action-bar-hover";
import Combo from "../common/dropdown/combo";
import GridContainer from "../common/grid-container/grid-container";
import { Loading } from "../common/loading/loading";
import EventTracking from "../common/tracking/event";
import W17F2020PopUp from "../W17/W17F2020";
import W17F2030PopUp from "../W17/W17F2030";
import W17F2042PopUp from "../W17/W17F2042";
import W18F1015CustomerPopUp from "../W18/W18F1015";
import ButtonExport from "../common/button/button-export";
import W17F2035PopUp from "./W17F2035";
import W18F1014 from '../W18/W18F1014';
import * as w18F1014Actions from "../../actions/w18F1014-actions";
import { makeStyles } from "diginet-core-ui/theme";
import { Row, Col, Label as LabelCore, Button, Typography, Image, ButtonIcon, Collapse, Accordion, AccordionGroup, AccordionSummary, AccordionDetails } from 'diginet-core-ui/components';

const useStyles = makeStyles(({ spacing }) => {
    return {
        masterDiv: {
            display: 'inline-block',
            marginBottom: spacing(4),
            width: '100%',
            max: 75
        },
        dropdownIconCb: {
            position: "absolute",
            top: 8,
            right: 16,
            pointerEvents: "none",
            padding: 'inherit',
        },
        gridContainer: {
            '& .grid-container': {
                paddingTop: '0 !important',
            }
        },
        inputPopup: {
            backgroundColor: 'initial !important'
        },
        customGridCss: {
            '& td': {
                '& .dx-highlight-outline': {
                    height: '33px',
                }
            }
        },
        textCombo: {
            '&.dx-state-disabled': {
                opacity: '1 !important',
                '& div.dx-texteditor-buttons-container': {
                    visibility: 'hidden',
                    opacity: 0
                }
            }
        },
        inputText: {
            backgroundColor: 'transparent !important',
            '& input': {
                backgroundColor: 'transparent !important',
            }
        },
        noteText: {
            '& textarea': {
                backgroundColor: 'transparent !important',
            }
        },
        numberFormatInput: {
            backgroundColor: 'transparent',
            border: 'none',
            margin: 0,
            padding: spacing(2)
        },
        imgAttachment: {
            '& > img': {
                marginBottom: 0,
                objectFit: 'cover',
                maxWidth: '70px !important'
            }
        },
        otherAttachment: {
            '& > img': {
                maxWidth: '70px !important'
            }
        },
    }
});
const Label = (props) => <LabelCore type={"h4"} {...props} />;
const FormID = "W17F2040";

class W17F2040Page extends React.PureComponent {
    constructor(props) {
        super(props);
        const { mode } = this.getInfo();
        const { dataCboCurrency, dataCboDivision, dataCboEmployee } = this.props;
        // exchangeRate
        this.state = {
            loading: false,
            collapse: true,
            collapseVAT: true,
            saveSuccess: false, // lưu thành công disabled Form
            showW17F2020: false,
            showW18F1015: false,
            showW17F2030: false,
            showW17F2042: false,
            showW17F2035: false,
            iPerD05F1621: 0,
            iPerD05F1631: 0,
            aStatus: 0,
            vatDisplay: 1, // 1 = Hiện 0 = Ẩn
            isQuotationNoneCode: 0, //  = 0 cho edit mã hàng
            iPerD05F5620: 0,
            mode: mode,
            objectID: "",
            inventory: "",
            objectTypeID: "",
            quotationID: "",
            quotationNum: "",
            voucherNo: "",
            objectName: "",
            priceListID: "",
            paymentTermID: "",
            paymentMethodID: "",
            contactPerson: "",
            deliveryMethodID: "",
            voucherTypeID: "",
            statusVoucher: "",
            approveNote: "",
            linkMaster: "",
            voucherNum: "",
            tranTypeID: "",
            tranType: null,
            currencyID: !_.isEmpty(dataCboCurrency) && mode === "add" ? dataCboCurrency[0].ID : "", // Default giá trị mặt định đầu tiên
            exchangeRate: !_.isEmpty(dataCboCurrency) && mode === "add" ? dataCboCurrency[0].ExchangeRate : "", // Default giá trị mặt định đầu tiên
            divisionID: !_.isEmpty(dataCboDivision) && mode === "add" ? dataCboDivision[0].DivisionID : "", // Default giá trị mặt định đầu tiên
            employeeID: !_.isEmpty(dataCboEmployee) && mode === "add" ? dataCboEmployee[0].EmployeeID : "", // Default giá trị mặt định đầu tiên
            validFrom: null,
            validTo: null,
            approveUserName: "",
            approveDate: "",
            quotationDate: null,
            voucherDate: moment().format("YYYY-MM-DD"), // Default Date
            telephone: null,
            dueDate: null,
            VATObjectID: "",
            VATObjectName: "",
            VATObjectAddress: "",
            VATNo: "",
            currencyObj: {},
            attached: [],
            dataGrid: [],// grid tam chi chua nhung dong duoc add vao
            dataAttach: [],
            selectedRow: [],
            dynamicColumm: [],
            priceListData: [],
            dynamicColummName: [],
            showW18F1014: false,
            inventoryColumns: []
        };
        this.initialState = { ...this.state }; // Sate ban đầu
        this.fromDate = null;
        this.gridContainer = null;
        this.OldAttachData = [];
        this.Language = Config.language || "84";
        this.columnDepentStatus = false;
        this.dataW18F1014 = { rowData: {}, mode: 'add', unRedirect: true };
        this.isLoadForm = false;
        this.defaultDynamicCol = {};
    };

    componentWillUnmount = () => {
        this.setState = (state, callback) => {
            return;
        };
    }

    componentDidMount = async () => {
        if (!this.getInfo()) return;
        const { mode, linkMaster, formCall, objectCustomer, isLoadContactPerson, ObjectID } = this.getInfo();
        const { dataCboStatus, dataCboTranType, dataDMFSetting,
            dataCboDeliveryMethod, dataCboVATGroup, dataCboPaymentTerm, dataCboPaymentMethod, } = this.props;
        this.setState({ linkMaster }, () => {
            if (mode !== "add" || formCall === "W17F1026") this.loadForm();
        });
        this.loadPermission();
        if (!_.isEmpty(objectCustomer)) {
            this.setState({
                objectID: objectCustomer.objectID ? objectCustomer.objectID : "",
                objectName: objectCustomer.objectName ? objectCustomer.objectName : "",
                objectTypeID: objectCustomer.objectTypeID ? objectCustomer.objectTypeID : "",
                telephone: objectCustomer.telephone ? objectCustomer.telephone : "",
                paymentTermID: objectCustomer.paymentTermID ? objectCustomer.paymentTermID : "",
                divisionID: objectCustomer.divisionID ? objectCustomer.divisionID : ""
            }, () => {
                this._updateDueDate();
                if (isLoadContactPerson) this.getCboContactPerson({ ObjectID }, true); // Load Loại Nghiệp Vụ
            })
        }
        this.getComboData("getCboDivision");
        this.getComboData("getCboCurrency");
        this.getComboData("getCboEmployee");
        this.getComboData("loadCboWareHouse", {Language: Config.language || "84", FormID});
        this.getComboData("getCboVAT", {Language: Config.language || "84", TranTypeID: "", ObjectID});
        if (_.isEmpty(dataCboTranType) || mode === "add") this.getComboData("getCboTranType");
        if (_.isEmpty(dataCboDeliveryMethod)) this.getComboData("getCboDelivery");
        if (_.isEmpty(dataCboVATGroup)) this.getComboData("getCboVATGroup");
        if (_.isEmpty(dataCboPaymentTerm)) this.getComboData("getCboPaymentTerm");
        if (_.isEmpty(dataCboPaymentMethod)) this.getComboData("getCboPaymentMethod");
        if (_.isEmpty(dataCboStatus)) this.getComboData("getCboStatus");
        if (_.isEmpty(dataDMFSetting)) this.getComboData("getDmfSetting");
        this.loadCaptionInInventory();
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { tranTypeID, paymentMethodID, paymentTermID, currencyID} = this.state;
        if (prevState.mode !== this.state.mode) {
            this.getComboData("getCboEmployee");
        }
        if (prevState.VATObjectAddress !== this.state.VATObjectAddress || prevState.VATNo !== this.state.VATNo) {
            if (this.refs["txtVATObjectAddress"]) this.refs["txtVATObjectAddress"].value = this.state.VATObjectAddress || "";
            if (this.refs["txtVATNo"]) this.refs["txtVATNo"].value = this.state.VATNo || "";
        }
        if (prevState.tranTypeID !== this.state.tranTypeID) {
            const {dataCboTranType} = this.props;
            const _tranType = dataCboTranType.find(d => d.TranTypeID === this.state.tranTypeID);
            if (_tranType) this.setState({tranType: _tranType});
        }
        if (prevState?.tranTypeID !== tranTypeID
            || prevState?.paymentMethodID !== paymentMethodID
            || prevState?.paymentTermID !== paymentTermID
            || prevState?.currencyID !== currencyID) {
            this.loadCboPriceList();
        }
    }

    loadPermission = async () => {
        await this.props.userActions.getPermission(Config.profile.UserID, ["D05F1621", "D05F1631", "D05F5620"], (arrPer) => {
            if (arrPer) {
                const iPerD05F1621 = arrPer.find(data => data.ScreenID === "D05F1621");
                const iPerD05F1631 = arrPer.find(data => data.ScreenID === "D05F1631");
                const iPerD05F5620 = arrPer.find(data => data.ScreenID === "D05F5620");
                this.setState({
                    iPerD05F1621: (iPerD05F1621 && iPerD05F1621.Permission) || 0,
                    iPerD05F1631: (iPerD05F1631 && iPerD05F1631.Permission) || 0,
                    iPerD05F5620: _.get(iPerD05F5620, "Permission", 0),
                });
            }
        });
    }

    getDefaultEmployeeID = (defaultValue = "") => {
        let _employeeID = Config.profile.SalesPersonID || "";
        if (!_employeeID) {
            const user = Config.getUser({ UserID: Config.profile.UserID });
            _employeeID = user.SalesPersonID || "";
        }
        return _employeeID || defaultValue || "";
    };

    getInfo = (flag) => {
        const { location } = this.props;
        const url = new window.URLSearchParams(window.location.search);
        if ((location && location.state) || (url && url.get('QuotationID'))) {
            return {
                id: location.state?.QuotationID || url.get('QuotationID'),
                ObjectID: location.state?.ObjectID || "",
                mode: location.state?.mode || 'view',
                formCall: location.state?.formCall || "",
                linkMaster: location.state?.LinkMaster || "",
                objectCustomer: location.state?.objectCustomer || {},
                orderTab: location.state?.orderTab || false,
                isLoadContactPerson: location.state?.isLoadContactPerson || false, // Load ContactPerson
                currentTabKey: location.state?.currentTabKey || "",
                formCallState: location.state?.formCallState || {},
                tabName: location.state?.tabName || "",
            }
        } else {
            if (flag) {
                browserHistory.push(Config.env.url + "/W19F2000");
                return null;
            }
            return false;
        }
    };

    setDefaultValueCbo = (apiComboName = "", dataCbo = []) => {
        if (_.isEmpty(apiComboName) || _.isEmpty(dataCbo)) return;
        let value = "";
        let stateName = "";
        let valuePlus = "";
        let stateNamePlus = "";
        let status = true;
        switch (apiComboName) {
            case "getCboDivision":
                stateName = "divisionID";
                value = dataCbo[0].DivisionID || "";
                break;
            case "getCboCurrency":
                status = false;
                stateName = "currencyID";
                value = dataCbo[0].ID || "";
                stateNamePlus = "exchangeRate";
                valuePlus = dataCbo[0].ExchangeRate || "";
                break;
            case "getCboEmployee":
                stateName = "employeeID";
                value = this.getDefaultEmployeeID(dataCbo[0].EmployeeID);
                break;
            case "getCboTranType":
                stateName = "tranTypeID";
                value = dataCbo[0].TranTypeID || "";
                break;
            case "getCboVAT":
                status = false;
                stateName = "VATObjectID";
                value = dataCbo[0].VATObjectID || "";
                stateNamePlus = "VATObjectName";
                valuePlus = dataCbo[0].VATObjectName || "";
                this.setState({
                    VATObjectAddress: dataCbo[0].VATObjectAddress || "",
                    VATNo: dataCbo[0].VATNo || ""
                });
                break;
            default:
                break;
        }
        if (_.isEmpty(this.state[stateName])) { // Trống thì ta mới set default
            status ? this.setState({ [stateName]: value }) : this.setState({ [stateName]: value, [stateNamePlus]: valuePlus });
        }
    }

    getComboData = (apiComboName, params) => {
        const defaultValueCboName = ["getCboDivision", "getCboCurrency", "getCboEmployee", "getCboVAT"];
        const { formCall, tabName } = this.getInfo();
        if (apiComboName) {
            if (["getCboEmployee"].includes(apiComboName)) {
                params = {
                    ...params,
                    Language: Config.language || "84",
                    FormID,
                    DataType: "CmbSalesPerson",
                    Mode: this.state.mode === "add" ? "A" : (this.state.mode === "edit" ? "E" : "V")
                };
            }
            if (["getCboTranType"].includes(apiComboName)) {
                params = {
                    ...(params || {}),
                    FormID,
                    DataType: formCall === "W17F1026" && tabName === "price" ? "LoadCBTranType_FormQuote" : "LoadCBTranType",
                };
            }
            if (params) {
                this.props.w17f2040Actions[apiComboName](params, async (error, data) => {
                    if (error) {
                        const message = error.message || Config.lang("Loi_chua_xac_dinh");
                        Config.popup.show("INFO", message);
                        return false;
                    } else if (data) {
                        if (this.state.mode === "add") {
                            if (defaultValueCboName.includes(apiComboName)) {
                                this.setDefaultValueCbo(apiComboName, data);
                            } else if (apiComboName === "getCboTranType" && data.length === 1) { //Nếu chỉ có 1 data thì mới set mặc định nghiệp vụ, voucher
                                const { TranTypeID = "" } = data[0];
                                this.onChangeVoucher(TranTypeID, false, data[0]);
                            }
                        }
                    }
                })
            } else {
                this.props.w17f2040Actions[apiComboName](async (error, data) => {
                    if (error) {
                        const message = error.message || Config.lang("Loi_chua_xac_dinh");
                        Config.popup.show("INFO", message);
                        return false;
                    } else if (data) {
                        if (this.state.mode === "add") {
                            if (defaultValueCboName.includes(apiComboName)) {
                                this.setDefaultValueCbo(apiComboName, data);
                            }
                        }
                        if (apiComboName === "getCboCurrency") {
                            const currencyObj = data.find(item => item?.ID === this.state.currencyID);
                            if (currencyObj) this.setState({ currencyObj });
                            if (this.state.mode === "add" && !_.isEmpty(this.props.dataCboCurrency)) this.loadCboPriceList(); // Load CBO Bảng giá
                        }
                    }
                })
            }
        }
    }

    loadCboPriceList = () => {
        const { currencyID: CurrencyID = "", tranTypeID: TranTypeID = "", paymentMethodID: PaymentMethodID = "", paymentTermID: PaymentTermID = "", priceListID: priceListIDMaster = "" } = this.state || {};
        const { formCall, tabName } = this.getInfo();
        let DataType = "CmbPriceList";
        if (formCall === "W17F1026" && tabName === "price") DataType = "Quotation_IN_Orders";
        const param = {
            FormID,
            DataType,
            TranTypeID,
            PaymentMethodID,
            PaymentTermID,
            CurrencyID
        }
        this.props.w17f2040Actions.loadPriceList(param, (error, data) => {
            if (error) {
                const message = error.message || Config.lang("Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            } else if (data) {
                let priceListData = data;
                let priceListID = this.isLoadForm ? priceListIDMaster : "" ;
                if (!_.isEmpty(data)) {
                    priceListData = data.map(item => {
                        const line = !_.isEmpty(item.DescriptionU) ? "-" : "";
                        if (item?.IsDefault === 1 && !priceListID && !this.isLoadForm) {
                            priceListID = item?.PriceListID || ""; // setDefault
                        }
                        return ({ ...item, PriceListCustomName: `${item.PriceListID} ${line} ${item.DescriptionU}` })
                    });
                }
                this.isLoadForm = false;
                this.setState({ priceListData, priceListID }); //clear old value
            }
        })
    };

    getCboContactPerson = (params, setDefaultData = false) => {
        this.props.w17f2040Actions.getCboContactPerson(params, (error, data) => {
            if (error) {
                const message = error.message || Config.lang("Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            } else if (data) {
                if (setDefaultData && !_.isEmpty(data)) {
                    this.setState({
                        dataCboContactPerson: data,
                        contactPerson: data[0].ContactPerson,
                        telephone: data[0].Telephone
                    });
                } else {
                    this.setState({ dataCboContactPerson: data });
                }
            }
        })
    }

    loadCaptionInInventory = (ObjectID) => {
        const { ObjectID: _ObjectID } = this.getInfo();
        ObjectID = ObjectID ? ObjectID : _ObjectID;
        const params = {
            DataType: "CaptionInventory",
            Language: Config.language || "84",
            TranTypeID: "",
            ObjectID
        };
        this.props.w17f2040Actions.getCboVAT(params, (error, data) => {
            if (error) {
                const message = error.message || Config.lang("Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            } else if (data) {
                this.setState({inventoryColumns: data });
            }
        })
    }

    deleteRow = (e) => {
        Config.popup.show("YES_NO", Config.lang("CRM_Ban_co_chac_chan_muon_xoa_du_lieu_nay"), () => {
            let grid = [...this.state.dataGrid];
            grid.splice(e.rowIndex, 1);
            const selectedRow = grid.filter(rs => rs.InventoryID !== '').map(item => item.InventoryID);
            this.setState({
                dataGrid: grid,
                selectedRow
            });
        })
    }

    _updateDueDate = () => {
        const {voucherDate, paymentTermID} = this.state;
        if (voucherDate) {
            const {dataCboPaymentTerm} = this.props;
            const paymentTerm          = dataCboPaymentTerm?.find(d => d.PaymentTermID === paymentTermID);
            this.setState({
                dueDate: moment(voucherDate).add(paymentTerm?.DueDays || 0, "days").format("YYYY-MM-DD")
            });
        }
    };

    hideW18F1015 = async ({ data = {}, dataInventory = [] }) => {
        if (!data || data?.ObjectID === this.state.objectID) {  // Chọn trùng KH cũ thì đóng và không làm gì cả
            this.setState({ showW18F1015: false });
            return false;
        }
        let selectedRowKey = [];
        let setDefaultContactPerson = false;
        if (this.state.mode === "add") setDefaultContactPerson = true;
        if (!_.isEmpty(dataInventory)) selectedRowKey = dataInventory.map(item => item.InventoryID);
        const { ObjectID = "", CompanyNameU = "", CompanyTypeID = "", Telephone = null, PaymentTermID = "", DivisionID = this.state.divisionID } = data || {};
        this.setState({
            showW18F1015: false,
            objectID: ObjectID,
            objectName: CompanyNameU,
            objectTypeID: CompanyTypeID,
            telephone: Telephone,
            paymentTermID: PaymentTermID,
            divisionID: DivisionID,
            VATObjectID: "",
            VATObjectName: "",
            VATObjectAddress: "",
            VATNo: "",
            employeeID: data.SalesPersonID || this.getDefaultEmployeeID(),
            dataGrid: _.isArray(dataInventory) ? dataInventory : this.state.dataGrid,
            selectedRow: selectedRowKey,
        }, () => { // Load lại combo Contact Person
            this._updateDueDate();
            if (ObjectID) {
                this.getCboContactPerson({ ObjectID: ObjectID, setDefaultContactPerson });
                this.getComboData("getCboVAT", {Language: Config.language || "84", TranTypeID: "", ObjectID: ObjectID});
                this.loadCaptionInInventory(ObjectID);
            }
        })
    };

    hideModal = (popupName) => {
        let stateName = "";
        switch (popupName) {
            case "W17F2020":
                stateName = "showW17F2020";
                break;
            case "W17F2042":
                stateName = "showW17F2042";
                break;
            default:
                break;
        }
        this.setState({ [stateName]: false });
    };

    calcQuantity = async (rowData = {}) => {
        if (_.isEmpty(rowData)) return null;
        const { priceListID, currencyID, paymentTermID, paymentMethodID, divisionID, deliveryMethodID, objectTypeID,
                  objectID, voucherDate, tranTypeID} = this.state;
        const QuotationDate = this.formatDateTimeSave(voucherDate); // CRM-878
        const params = {
            InventoryID: rowData?.InventoryID || "",
            UnitID: rowData?.UnitID || "",
            Quantity: rowData?.Quantity || 0,
            PaymentTermID: paymentTermID || "",
            PaymentMethodID: paymentMethodID || "",
            PriceListID: priceListID || "",
            ObjectID: objectID || "",
            QuotationDate: QuotationDate,
            TranTypeID: tranTypeID || "",
            LinkMaster: rowData?.LinkMaster || "",
            LinkDetail: rowData?.LinkDetail || "",
            CurrencyID: currencyID || "",
            DivisionID: divisionID || "",
            DeliveryMethodID: deliveryMethodID || "",
            ObjectTypeID: objectTypeID || "",
        };
        return await this.props.w17f2040Actions.calcQuantity(params);
    };

    calculate = async (rowData = {}, columnName = "", e) => {
        let rowIndex = -1;
        const { dataCboVATGroup } = this.props;
        let vatRateResult = Number.isInteger(rowData.VATRate) ? rowData.VATRate / 100 : rowData.VATRate;
        if (columnName === "VATGroupID" && dataCboVATGroup) { // Thuế suất
            if (rowData.VATGroupID) {
                const vATGroupObj = dataCboVATGroup.find(item => item.VATGroupID === rowData.VATGroupID);
                vatRateResult = Number.isInteger(vATGroupObj.VATRate) ? vATGroupObj.VATRate / 100 : vATGroupObj.VATRate;
                if (vatRateResult > 100) {
                    Config.popup.show("INFO", `${Config.lang("CRM_Thue_suat_khong_duoc_lon_hon")} 100%`);
                    rowData.VATRate = "";
                    return false;
                } else {
                    rowData.VATRate = vatRateResult * 100;
                }
            } else { // = Null (Btn Clear Value)
                vatRateResult = rowData.VATRate = null;
            }
        }
        if (columnName === "Quantity") {
            const resQuantity = await this.calcQuantity(rowData);
            for (let col of resQuantity) {
                if (col.ColID) rowData[col.ColID] = col.Value || 0;
            }
        }
        const ACol01Rate = Number.isInteger(rowData.ACol01Rate) ? rowData.ACol01Rate / 100 : rowData.ACol01Rate; // Dạng %
        const ACol02Rate = Number.isInteger(rowData.ACol02Rate) ? rowData.ACol02Rate / 100 : rowData.ACol02Rate; // Dạng %
        const ACol03Rate = Number.isInteger(rowData.ACol03Rate) ? rowData.ACol03Rate / 100 : rowData.ACol03Rate; // Dạng %
        const ACol04Rate = Number.isInteger(rowData.ACol04Rate) ? rowData.ACol04Rate / 100 : rowData.ACol04Rate; // Dạng %
        const ACol05Rate = Number.isInteger(rowData.ACol05Rate) ? rowData.ACol05Rate / 100 : rowData.ACol05Rate; // Dạng %
        const exchangeR = parseInt(this.state.exchangeRate) || 1;
        const oAmountTmpResult = rowData.Quantity * rowData.OUnitPrice;
        const cAmountTmpResult = oAmountTmpResult * exchangeR;
        const amountResult = oAmountTmpResult
            + (rowData.ACol01 * rowData.ACol01Formula) + (ACol01Rate * rowData.OUnitPrice * rowData.ACol01Formula)
            + (rowData.ACol02 * rowData.ACol02Formula) + (ACol02Rate * rowData.OUnitPrice * rowData.ACol02Formula)
            + (rowData.ACol03 * rowData.ACol03Formula) + (ACol03Rate * rowData.OUnitPrice * rowData.ACol03Formula)
            + (rowData.ACol04 * rowData.ACol04Formula) + (ACol04Rate * rowData.OUnitPrice * rowData.ACol04Formula)
            + (rowData.ACol05 * rowData.ACol05Formula) + (ACol05Rate * rowData.OUnitPrice * rowData.ACol05Formula);
        const adjustedUnitPriceResult = rowData.Quantity ? amountResult / rowData.Quantity : 0;
        const cAmountResult = amountResult * exchangeR;
        const unitPriceResult = vatRateResult >= 0 ? (adjustedUnitPriceResult * vatRateResult) + adjustedUnitPriceResult : adjustedUnitPriceResult; // Đơn giá sau thuế
        rowData.UnitPrice = unitPriceResult;  // Đơn giá sau thuế
        rowData.OAmountTmp = !_.isNaN(oAmountTmpResult) ? oAmountTmpResult : null; // Thành tiền
        rowData.CAmountTmp = !_.isNaN(cAmountTmpResult) ? cAmountTmpResult : null; // Thành tiền QĐ
        rowData.AdjustedUnitPrice = !_.isNaN(adjustedUnitPriceResult) ? adjustedUnitPriceResult : null;  // Đơn giá sau DC
        rowData.Amount = !_.isNaN(amountResult) ? amountResult : null;  // Thành tiền sau DC
        rowData.CAmount = !_.isNaN(cAmountResult) ? cAmountResult : null;  // Thành tiền sau DC QĐ

        const OAmountVAT = (this.columnDepentStatus ? rowData.Amount : rowData.OAmountTmp) * (rowData.VATRate ? rowData.VATRate / 100 : 0);
        const CAmountVAT = OAmountVAT * exchangeR;
        rowData.OAmountVAT = !_.isNaN(OAmountVAT) ? OAmountVAT : null;
        rowData.CAmountVAT = !_.isNaN(CAmountVAT) ? CAmountVAT : null;

        const totalAdjustedAmountResult = (this.columnDepentStatus ? rowData.Amount : rowData.OAmountTmp) + rowData.OAmountVAT;
        const totalAdjustedCAmountResult = totalAdjustedAmountResult * exchangeR;
        rowData.TotalAdjustedAmount = !_.isNaN(totalAdjustedAmountResult) ? totalAdjustedAmountResult : null; // Tổng tiền
        rowData.TotalAdjustedCAmount = !_.isNaN(totalAdjustedCAmountResult) ? totalAdjustedCAmountResult : null; // Tổng tiền QĐ

        if (e) {
            const { dataGrid } = this.state;
            rowIndex = e.component.getRowIndexByKey(e.key);
            if (rowIndex > -1 && !_.isEmpty(dataGrid)) dataGrid[rowIndex] = rowData;
        }
    }

    renderActionHover = (e) => {
        return (
            <div style={{ maxHeight: 20 }}>
                <GridActionBarHover>
                    {this.state.mode !== "view" &&
                        <GridActionBar
                            isPer={this.state.iPerD05F1611}
                            tooltipDelete={Config.lang("Xoa")}
                            onDeleteAction={() => {
                                this.deleteRow(e)
                            }} />}
                </GridActionBarHover>
            </div>
        );
    }

    formatDateTimeSave = (value) => {
        let result = null;
        if (!_.isEmpty(value) && Config.isValidDateTime(value)) {
            result = moment(value).format("YYYY-MM-DD");
        }
        return result;
    }

    formatDateTimeArraySave = (array = []) => {
        if (_.isEmpty(array)) return [];
        const columnDateName = ["DeliveryDate"];
        const allValueName = Object.keys(array[0]);
        allValueName.forEach(fieldName => {
            array.forEach(obj => {
                if (Config.isValidDateTime(obj[fieldName]) && columnDateName.includes(fieldName)) {
                    obj[fieldName] = moment(obj[fieldName]).format("YYYY-MM-DD");
                }
            })
        })
        return array;
    }

    getParamSave = () => {
        const { statusVoucher, employeeID, dataGrid, validTo, validFrom, priceListID, contactPerson, telephone, quotationNum,
            currencyID, paymentTermID, paymentMethodID, objectID, voucherNum, exchangeRate, voucherTypeID, quotationID, quotationDate,
            objectTypeID, isQuotationNoneCode, divisionID, tranTypeID, voucherDate, deliveryMethodID, aStatus, voucherNo, linkMaster,
            dueDate, VATObjectID, VATObjectName, VATObjectAddress, VATNo, wareHouseID
        } = this.state;
        const description = document.getElementById("descriptionW17F2040").value;
        const shipAddress = document.getElementById("shipAddressW17F2040").value;
        const VoucherDate = this.formatDateTimeSave(voucherDate);
        const DueDate = this.formatDateTimeSave(dueDate);
        const ValidFrom = this.formatDateTimeSave(validFrom);
        const ValidTo = this.formatDateTimeSave(validTo);
        const QuotationDate = this.formatDateTimeSave(quotationDate);
        const dataGridFormat = this.formatDateTimeArraySave(dataGrid);
        return {
            Language: this.Language,
            DivisionID: divisionID,
            QuotationID: quotationID,
            LinkMaster: linkMaster,
            QuotationNum: quotationNum,
            VoucherNo: voucherNo,
            ObjectTypeID: objectTypeID,
            VoucherTypeID: voucherTypeID,
            IsQuotationNoneCode: isQuotationNoneCode,
            ObjectID: objectID,
            TranTypeID: tranTypeID,
            VoucherNum: voucherNum,
            VoucherDate,
            ValidTo,
            ValidFrom,
            QuotationDate,
            EmployeeID: employeeID,
            ContactPerson: contactPerson,
            Telephone: telephone,
            PaymentTermID: paymentTermID,
            PaymentMethodID: paymentMethodID,
            DeliveryMethodID: deliveryMethodID,
            CurrencyID: currencyID,
            ExchangeRate: exchangeRate,
            PriceListID: priceListID,
            StatusVoucher: statusVoucher,
            Description: description,
            AStatus: aStatus,
            NotesCustomer: "",
            DueDate,
            WareHouseID: wareHouseID,
            VATObjectID,
            VATObjectName,
            VATObjectAddress,
            VATNo,
            ShipAddress: shipAddress,
            ShipAddressID: "",
            detail: JSON.stringify(dataGridFormat)
        };
    };

    checkSaveData = () => {
        const param = this.getParamSave();
        this.setState({ loading: true });
        this.props.w17f2040Actions.checkSaveData(param, async (error, data) => {
            this.setState({ loading: false });
            if (error) {
                Config.popup.show("INFO", _.get(error, "message", Config.lang("Loi_chua_xac_dinh")));
                return false;
            } else if (data) {
                if (data.Status === 0) {
                    this.onSaveData();
                } else if (data.Status === 1) {
                    if (data.MsgAsk === 0) {
                        Config.popup.show("INFO", _.get(data, "Message", Config.lang("Loi_chua_xac_dinh")));
                        return false;
                    } else if (data.MsgAsk === 1) {
                        Config.popup.show("YES_NO", data.Message, () => {
                            this.onSaveData();
                        });
                    }
                }
            }
        });
    };

    onSaveData = async () => {
        const param = this.getParamSave();
        this.setState({ loading: true });
        this.props.w17f2040Actions.saveData(param, async (error, data) => {
            this.setState({ loading: false });
            if (error) {
                let errorCode = error.code || null;
                let message;
                switch (errorCode) {
                    case "US004":
                        message = Config.lang("CRM_Tai_khoan_dang_nhap_hoac_mat_khau_khong_dung");
                        break;
                    default:
                        message = error.message || Config.lang("Loi_chua_xac_dinh");
                        break;
                }
                Config.popup.show("INFO", Config.lang("Loi"));
                Config.popup.show("INFO", message);
                return false;
            } else if (!_.isEmpty(data)) {
                if (data.Status === 0) {
                    const quotationID = (data && data.QuotationID) ? data.QuotationID : this.state.quotationID;
                    if (this.state.mode === "add") {
                        const paramsCheckTask = {
                            QuotationID: quotationID,
                            TranTypeID: param.TranTypeID,
                        };
                        this.props.w17f2040Actions.checkTask(paramsCheckTask, (er, cb) => {
                            if (cb.Status) {
                                const paramsGetForm = {
                                    KeyID: quotationID,
                                    TaskID: '',
                                    FormID,
                                };
                                this.props.w18F1014Actions.getForm(paramsGetForm, (error, data) => {
                                    if (!Config.isEmpty(data)) {
                                        this.dataW18F1014.rowData = data;
                                        this.dataW18F1014.params = paramsGetForm;
                                        this.setState({ showW18F1014: true });
                                    }
                                });
                            }
                        });
                    }
                    const status = await this.saveAttach(quotationID); // Lưu đính kèm
                    if (status === 200) {
                        this.setState({
                            quotationID,
                            saveSuccess: true,
                            mode: "view", // Lưu thành công cho về "View"
                            // voucherNum: data.VoucherNum ? data.VoucherNum : this.state.voucherNum Để xem
                        }, () => {
                            this.loadForm(quotationID); // Load lại form
                        });
                        notify(Config.lang("CRM_Luu_thanh_cong"), "success", 400);
                    }
                } else if (data.Status === 1 && _.isEmpty(data.QuotationID)) {
                    if (data.Message) Config.popup.show("INFO", Config.lang(data.Message)); // Xuất thông báo từ api
                    return false;
                }
            }
        });
    };

    saveAttach = async (keyID) => {
        if (_.isEmpty(keyID)) return;
        const { dataAttach, mode } = this.state;
        const OldAttachData = this.OldAttachData;
        let status = 200;
        let attached = [...this.state.attached];
        if (dataAttach.length > 0) {
            for (let i = 0; i < dataAttach.length; i++) {
                const exp = dataAttach[i].file.name.split(".");
                const paramAttach = {
                    "KeyID": keyID,
                    "FileName": dataAttach[i].file.name,
                    "FileSize": dataAttach[i].file.size,
                    "FileExp": exp[exp.length - 1],
                    "TableName": "D05T0016"
                };
                const upload = await FileUploadAsync(dataAttach[i].file, paramAttach);
                if (upload && upload.code === 200) {
                    dataAttach[i].AttachmentID = upload.KeyID;
                    attached.push(dataAttach[i]);
                } else {
                    status = 400;
                    break;
                }
            }
            this.setState({
                dataAttach: [],
                attached: attached,
            });
        }
        if (mode === "edit") { // Xóa Attachment
            if (OldAttachData.length > 0) {
                let paramDeleteAttach;
                let paramRemoveAttach;
                for (let i = 0; i < OldAttachData.length; i++) {
                    paramDeleteAttach = {
                        "AttachmentID": OldAttachData[i].AttachmentID
                    };
                    paramRemoveAttach = {
                        "path": OldAttachData[i].URL
                    };
                    await Promise.all([
                        FileDeleteAsync(paramDeleteAttach),
                        FileRemoveAsync(paramRemoveAttach)
                    ]);
                }
                OldAttachData.length = 0;
            }
        }
        return status
    };

    saveData = async () => {
        await this.gridContainer.instance.saveEditData();
        const { employeeID, dataGrid, validTo, validFrom, currencyID, objectID, voucherNum, divisionID, tranTypeID, voucherDate } = this.state;
        let quantityOUnitPriceValidate = false;
        if (!_.isEmpty(dataGrid) && !quantityOUnitPriceValidate) dataGrid.forEach(item => {
            if (_.isNull(item.Quantity) || _.isNull(item.OUnitPrice)) {
                quantityOUnitPriceValidate = true;
                return;
            }
        });
        if (_.isEmpty(objectID)) {
            Config.popup.show("INFO", Config.lang("CRM_Khach_hang_khong_duoc_de_trong"));
            document.getElementById("customerW17F2040").focus();
            return false;
        } else if (_.isEmpty(divisionID)) {
            Config.popup.show("INFO", `${Config.lang("CRM_Don_vi_ban")} ${(Config.lang("CRM_Khong_duoc_de_trong")).toLowerCase()}`);
            document.getElementById("divisionW17F2040").getElementsByClassName("dx-texteditor-input")[0].focus();
            return false;
        } else if (_.isEmpty(tranTypeID)) {
            Config.popup.show("INFO", `${Config.lang("CRM_Nghiep_vu")} ${(Config.lang("CRM_Khong_duoc_de_trong")).toLowerCase()}`);
            document.getElementById("tranTypeW17F2040").getElementsByClassName("dx-texteditor-input")[0].focus();
            return false;
        } else if (_.isEmpty(voucherNum)) {
            Config.popup.show("INFO", `${Config.lang("CRM_So_don_hang")} ${(Config.lang("CRM_Khong_duoc_de_trong")).toLowerCase()}`);
            document.getElementById("voucherNumW17F2040").focus();
            return false;
        } else if (_.isEmpty(voucherDate)) {
            Config.popup.show("INFO", `${Config.lang("CRM_Ngay_phieu")} ${(Config.lang("CRM_Khong_duoc_de_trong")).toLowerCase()}`);
            document.getElementById("voucherDateW17F2040").getElementsByClassName("dx-texteditor-input")[0].focus();
            return false;
        } else if (_.isEmpty(employeeID)) {
            Config.popup.show("INFO", `${Config.lang("CRM_Nhan_vien_kinh_doanh")} ${(Config.lang("CRM_Khong_duoc_de_trong")).toLowerCase()}`);
            document.getElementById("employeeW17F2040").getElementsByClassName("dx-texteditor-input")[0].focus();
            return false;
        } else if (_.isEmpty(currencyID)) {
            Config.popup.show("INFO", `${Config.lang("CRM_Loai_tien")} ${(Config.lang("CRM_Khong_duoc_de_trong")).toLowerCase()}`);
            document.getElementById("currencyW17F2040").getElementsByClassName("dx-texteditor-input")[0].focus();
            return false;
        } else if (_.isEmpty(validFrom)) {
            Config.popup.show("INFO", `${Config.lang("CRM_Hieu_luc_tu")} ${(Config.lang("CRM_Khong_duoc_de_trong")).toLowerCase()}`);
            document.getElementById("validFromW17F2040").getElementsByClassName("dx-texteditor-input")[0].focus();
            return false;
        } else if ((validFrom > validTo) && validTo) {
            Config.popup.show("INFO", Config.lang("CRM_Hieu_luc_den_phai_lon_hon_hieu_luc_tu"));
            this.setState({ validTo: null });
            document.getElementById("validToW17F2040").getElementsByClassName("dx-texteditor-input")[0].focus();
            return false;
        } else if (!dataGrid || !(dataGrid.length > 0)) {
            Config.popup.show("INFO", Config.lang("CRM_Ban_chua_chon_hang_hoa"));
            return false;
        } else if (quantityOUnitPriceValidate) {
            Config.popup.show("INFO", Config.lang("CRM_Vui_long_nhap_day_du_don_gia_truoc_thue_va_so_luong_hang_hoa"));
            return false;
        } else {
            this.checkSaveData();
        }
    };

    loadForm = (newQuotationID = "", resetDataGrid = false) => {
        if (!this.getInfo()) return;
        let dynamicColummName = [];
        const { id, linkMaster: linkMasterInfo = "" } = this.getInfo();
        const { mode, quotationID, linkMaster } = this.state;
        const { dataCboCurrency, dataCboDivision, dataCboEmployee } = this.props;
        let idF = mode !== "add" ? (id ? id : quotationID) : quotationID;
        const paramStore = {
            Language: this.Language,
            DivisionID: Config.profile.DivisionID,
            QuotationID: !_.isEmpty(newQuotationID) ? newQuotationID : idF,
            LinkMaster: linkMaster,
        };
        this.props.w17f2040Actions.loadForm(paramStore, async (error, data) => {
            if (error) {
                let errorCode = error.code || null;
                let message;
                switch (errorCode) {
                    case "US004":
                        message = Config.lang("CRM_Tai_khoan_dang_nhap_hoac_mat_khau_khong_dung");
                        break;
                    default:
                        message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                        break;
                }
                Config.popup.show("INFO", message);
                return false;
            } else if (!_.isEmpty(data)) {
                let selectedRow = [];
                if (data.ObjectID) {
                    this.getCboContactPerson({ ObjectID: data.ObjectID });
                    this.getComboData("getCboVAT", {Language: Config.language || "84", TranTypeID: "", ObjectID: data.ObjectID});
                    this.loadCaptionInInventory(data.ObjectID);
                }
                if (data.Description) document.getElementById("descriptionW17F2040").value = data.Description;
                if (data.ShipAddress) document.getElementById("shipAddressW17F2040").value = data.ShipAddress;
                if (data.detail && !_.isEmpty(data.detail.collumn)) {
                    dynamicColummName = data.detail.collumn.map(item => item.ColID);
                }
                if (!resetDataGrid && data.detail && data.detail.rows) {
                    data.detail.rows.forEach(item => {
                        if (item.VATRate) {
                            item.VATRate = Number.isInteger(item.VATRate) ? item.VATRate : item.VATRate * 100; // Format lại dạng phần trăm
                        }
                    })
                    selectedRow = data.detail.rows.map(item => item.InventoryID);
                }
                this.isLoadForm = true;
                this.setState({
                    currencyID: data.CurrencyID || (!_.isEmpty(dataCboCurrency) && mode === "add" ? dataCboCurrency[0].ID : ""), // Default giá trị mặt định đầu tiên
                    exchangeRate: data.ExchangeRate || (!_.isEmpty(dataCboCurrency) && mode === "add" ? dataCboCurrency[0].ExchangeRate : ""), // Default giá trị mặt định đầu tiên
                    divisionID: data.DivisionID || (!_.isEmpty(dataCboDivision) && mode === "add" ? dataCboDivision[0].DivisionID : ""), // Default giá trị mặt định đầu tiên
                    employeeID: data.EmployeeID || (mode === "add" ? this.getDefaultEmployeeID(!_.isEmpty(dataCboEmployee) ? dataCboEmployee[0].EmployeeID : "") : ""), // Default giá trị mặt định đầu tiên
                    voucherDate: data.VoucherDate || moment().format("YYYY-MM-DD"), // Default Date

                    voucherNo: data.VoucherNo || this.state.voucherNo,
                    contactPerson: data.ContactPerson || this.state.contactPerson,
                    objectID: data.ObjectID || this.state.objectID,
                    priceListID: data.PriceListID || this.state.priceListID,
                    paymentTermID: data.PaymentTermID || this.state.paymentTermID,
                    tranTypeID: data.TranTypeID || this.state.tranTypeID,
                    telephone: data.Telephone ? data.Telephone : this.state.telephone,
                    aStatus: data.AStatus ?? this.state.aStatus,
                    quotationNum: data.QuotationNum || this.state.quotationNum,
                    quotationID: data.QuotationID || this.state.quotationID,
                    objectTypeID: data.ObjectTypeID || this.state.objectTypeID,
                    quotationDate: data.QuotationDate || this.state.quotationDate,
                    paymentMethodID: data.PaymentMethodID || this.state.paymentMethodID,
                    validTo: data.ValidTo || this.state.validTo,
                    validFrom: data.ValidFrom || this.state.validFrom,
                    approveUserName: data.ApproveUserName,
                    approveDate: data.ApproveDate ? moment(data.ApproveDate).format("DD/MM/YYYY HH:mm:ss") : "",
                    dueDate: data.DueDate || this.state.DueDate,
                    wareHouseID: data.WareHouseID || this.state.WareHouseID,
                    VATObjectID: data?.VATObjectID || this.state.VATObjectID,
                    VATObjectName: data?.VATObjectName || this.state.VATObjectName,
                    VATObjectAddress: data?.VATObjectAddress || this.state.VATObjectAddress,
                    VATNo: data?.VATNo || this.state.VATNo,
                    // approveNote: data.ApproveNotesU,
                    objectName: data.ObjectName || this.state.objectName,
                    voucherNum: data.VoucherNum || this.state.voucherNum,
                    statusVoucher: data.StatusVoucher || this.state.statusVoucher,
                    voucherTypeID: data.VoucherTypeID || this.state.voucherTypeID,
                    deliveryMethodID: data.DeliveryMethodID || this.state.deliveryMethodID,
                    isQuotationNoneCode: data.IsQuotationNoneCode || this.state.isQuotationNoneCode,
                    linkMaster: data.LinkMaster ? data.LinkMaster : linkMasterInfo,
                    dataGrid: resetDataGrid ? [] : (data.detail.rows || []), //Data Lưới
                    dynamicColumm: data.detail.collumn || [],
                    dynamicColummName,
                    selectedRow,
                    attached: data.attached || []  // Đính kèm
                });
            }
        })
    };

    renderCboUnit = (e) => {
        if (!e.row) return;
        const { row } = e;
        e.editorOptions.onFocusIn = args => {
            const input = args.element.querySelector(".dx-texteditor-input");
            input.select();
        };
        e.editorOptions.onKeyDown = args => {
            if (e.format && e.dataType === "number") {
                const value = args.event.target.value;
                const patternF = e.format ? e.format.split(".")[1].length : 0;
                if (!patternF && (args.event.key === "." || args.event.key === ",")) args.event.preventDefault();
                const decimalPart = value.includes(".") ? value.split(".")[1] : value.split(",") ? value.split(",")[1] : false;
                if (decimalPart && decimalPart.length === patternF && parseInt(args.event.key) && !window.getSelection().toString()) {
                    //Nếu phần thập phân leng = 3 và ký tụ tiếp theo là số, và không bôi đen input thì cho nhập
                    args.event.preventDefault();
                }
            }
        };
        e.editorOptions.onValueChanged = args => {
            row.data[e.dataField] = args.value;
            this.calculate(row.data, e.dataField);
            e.component.refresh();
        }
    }

    goBack = () => {
        const state = this.getInfo();
        const { currentTabKey, formCall, formCallState } = state;
        if (formCall) {
            browserHistory.push({
                pathname: Config.getRootPath() + formCall,
                state: { ...state, ...formCallState, currentTabKey }
            });
        } else {
            browserHistory.goBack();
        }
    };

    onChangeInput = (e, inputName) => {
        let stateName = "";
        switch (inputName) {
            case "telephone":
                stateName = "telephone";
                document.getElementById("telephoneW17F2040").value = Config.replaceSymbol(e.target.value);
                break;
            case "voucherNo":
                stateName = "voucherNo";
                document.getElementById("voucherNoW17F2040").value = Config.replaceSymbol(e.target.value);
                break;
            case "quotationNum":
                stateName = "quotationNum";
                document.getElementById("quotationNumW17F2040").value = Config.replaceSymbol(e.target.value);
                break;
            default:
                break;
        }
        const value = Config.replaceSymbol(e.target.value);
        this.setState({ [stateName]: value });
    };

    deleteOldAttach = (attachment) => {
        Config.popup.show("YES_NO", Config.lang("CRM_Ban_co_chac_chan_muon_xoa_du_lieu_nay"), () => {
            this.OldAttachData.push(attachment);
            const arr = this.state.attached.filter(function (obj) {
                return obj.AttachmentID !== attachment.AttachmentID;
            });
            this.setState({ attached: arr })
        });
    }

    deleteAttach = async (file) => {
        Config.popup.show("YES_NO", Config.lang("CRM_Ban_co_chac_chan_muon_xoa_du_lieu_nay"), () => {
            const arr = this.state.dataAttach.filter((value) => {
                return value.FileName !== file.FileName;
            });
            this.setState({ dataAttach: arr });
        });
    }

    renderIcon = (file, url) => {
        const classes = useStyles();
        let ext = file ? file.split(".") : "";
        ext = ext[ext.length - 1];
        switch (ext) {
            case 'doc':
                return (<Image className={classes.otherAttachment} width={'auto'} height={'auto'} alt="" src={require("../../images/file/icon-doc.png")} />);
            case 'docx':
                return (<Image className={classes.otherAttachment} width={'auto'} height={'auto'} alt="" src={require("../../images/file/icon-docx.png")} />);

            case 'xls':
                return (<Image className={classes.otherAttachment} width={'auto'} height={'auto'} alt="" src={require("../../images/file/icon-xls.png")} />);
            case 'xlsx':
                return (<Image className={classes.otherAttachment} width={'auto'} height={'auto'} alt="" src={require("../../images/file/icon-xlsx.png")} />);

            case 'ppt':
                return (<Image className={classes.otherAttachment} width={'auto'} height={'auto'} alt="" src={require("../../images/file/icon-ppt.png")} />);
            case 'pptx':
                return (<Image className={classes.otherAttachment} width={'auto'} height={'auto'} alt="" src={require("../../images/file/icon-pptx.png")} />);

            case 'pdf':
                return (<Image className={classes.otherAttachment} width={'auto'} height={'auto'} alt="" src={require("../../images/file/icon-pdf.png")} />);

            case 'txt':
                return (<Image className={classes.otherAttachment} width={'auto'} height={'auto'} alt="" src={require("../../images/file/icon-txt.png")} />);

            default:
                return (<Image width={70} height={70} className={`${classes.imgAttachment} img-background`} alt="" src={url} />);
        }
    };

    checkFileType = (filetype) => {
        const allowedExtensions = [
            "image/jpeg",
            "image/png",
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            "application/msword",
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            "application/pdf"
        ];
        if (filetype.length > 0) {
            if (allowedExtensions.indexOf(filetype) === -1) {
                return false;
            }
            return true;
        } else {
            return false;
        }
    }

    upLoadAttach = (e) => {
        if (!e) return;
        const files = _.get(e, "target.files", []);
        const sizeLimit = 20;
        if (files.length === 0) {
            return null;
        } else if (this.checkFileType(files[0].type) === false) {
            Config.popup.show("INFO", `${Config.lang("CRM_Dinh_dang_file_khong_hop_le")}`);
        } else if ((files[0].size / 1024 / 1024) > Number(sizeLimit)) {
            Config.popup.show("INFO", `${Config.lang("CRM_Dung_luong_File_khong_duoc_lon_hon")} ${sizeLimit} MB`);
        } else {
            let dataAttach = [...this.state.dataAttach];
            for (let file of files) {
                file.uploading = true;
                dataAttach.push({ file: file, FileName: file.name, URL: window.URL.createObjectURL(file) });
            }
            this.setState({ dataAttach });
        }
    }

    handleW17F2030Modal = (showW17F2030, data = [], selectedRow = []) => {
        this.setState({ showW17F2030 });
        if (!_.isEmpty(data) && !_.isEmpty(selectedRow)) {
            this.setState({
                dataGrid: data,
                selectedRow
            })
        }
    }

    renderNumberFormat = (e) => {
        if (!e) return false;
        let limitDecimal = 0;
        const { data } = e.row;
        const { column } = e;
        const { dataDMFSetting } = this.props;
        const { currencyObj, dynamicColumm } = this.state;
        const dataField = column.dataField;
        let decimalValue = data[dataField];
        if (_.isUndefined(decimalValue)) return;
        if (dataField.search("ACol") < 0) {
            switch (dataField) {
                case "UnitPrice":
                case "Amount":
                case "OUnitPrice":
                case "OAmountTmp":
                case "TotalAdjustedAmount":
                case "AdjustedUnitPrice":
                case "OAmountVAT":
                    limitDecimal = !_.isEmpty(currencyObj) ? currencyObj.OriginalDecimal : 0;
                    break;
                case "UnitPriceOnD14":
                case "CAmountTmp":
                case "CAmount":
                case "TotalAdjustedCAmount":
                case "CAmountVAT":
                    limitDecimal = !_.isEmpty(dataDMFSetting) ? dataDMFSetting.D90_ConvertedDecimals : 0;
                    break;
                case "Quantity":
                    limitDecimal = !_.isEmpty(dataDMFSetting) ? dataDMFSetting.D07_QuantityDecimals : 0;
                    break;
                default:
                    break;
            }
        } else { // Các cột Động Acol
            const currentACol = dynamicColumm && dynamicColumm.find(item => item.ColID === dataField);
            if (!_.isEmpty(currentACol)) limitDecimal = currentACol.DecimalPlace;
        }
        if (!_.isEmpty(decimalValue) && _.isString(decimalValue)) decimalValue = _.toNumber(decimalValue);
        return <NumberFormat decimalScale={limitDecimal} value={decimalValue} displayType={"text"} thousandSeparator={true} />
    };

    checkStatusOrder = () => {
        const { tranTypeID, quotationID, divisionID, linkMaster } = this.state;
        const params = {
            DivisionID: divisionID,
            QuotationID: quotationID,
            TranTypeID: tranTypeID,
            Language: this.Language,
            LinkMaster: linkMaster
        };
        this.props.w17f2040Actions.checkStatus(params, (error, data) => {
            if (error) {
                const message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            } else if (!_.isEmpty(data)) {
                if (data.Status === 0) {
                    this.setState({ showW17F2020: true });
                } else if (data.Status === 1 && data.Message) {
                    Config.popup.show("INFO", data.Message || Config.lang("CRM_Loi_chua_xac_dinh"));
                }
            }
        })
    }

    checkUpdateIventoryID = () => {
        const { tranTypeID, quotationID, divisionID, linkMaster } = this.state;
        const params = {
            DivisionID: divisionID,
            QuotationID: quotationID,
            TranTypeID: tranTypeID,
            Language: this.Language,
            LinkMaster: linkMaster
        };
        this.props.w17f2040Actions.checkUpdateInventoryID(params, (error, data) => {
            if (error) {
                const message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            } else if (!_.isEmpty(data)) {
                this.setState({ showW17F2042: true });
            }
        })
    }

    onChangeVoucher = (TranTypeID, reloadEmptyGrid = false) => {
        const { orderTab } = this.getInfo();
        const { quotationID, divisionID, linkMaster = "" } = this.state;
        let dynamicColumm = this.state.dynamicColumm;
        let dynamicColummName = this.state.dynamicColummName;
        const param = {
            QuotationID: quotationID,
            DivisionID: divisionID,
            TranTypeID,
            LinkMaster: linkMaster,
            Language: this.Language
        };
        this.props.w17f2040Actions.loadVoucher(param, (error, data) => {
            if (error) {
                const message = error.message || Config.lang("Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            } else if (data && !_.isEmpty(data)) {
                if (this.state.mode !== "edit" && data.detail && !_.isEmpty(data.detail.column)) {
                    dynamicColumm = data.detail.column;
                    dynamicColummName = data.detail.column.map(item => item.ColID);
                }
                this.setState({
                    tranTypeID: TranTypeID,
                    vatDisplay: data.VATDisplay,
                    voucherNum: data.VoucherNum || "",
                    voucherTypeID: data.VoucherTypeID || "",
                    isQuotationNoneCode: data.IsQuotationNoneCode,
                    dynamicColumm,
                    dynamicColummName,
                }, () => {
                    if (reloadEmptyGrid) {
                        if (orderTab === true) {
                            this.loadForm("", true);
                        } else {
                            this.setState({
                                dataGrid: [],
                                selectedRow: [],
                            });
                        }
                    } // Nếu gọi từ Tab Báo giá thì load lại form
                });
            }
        });
    }

    loadUnitPrice = () => {
        const { quotationID: QuotationID = "", tranTypeID: TranTypeID = "", paymentTermID: PaymentTermID = "", paymentMethodID: PaymentMethodID = "" } = this.state;
        const params = {
            FormID,
            QuotationID,
            TranTypeID,
            PaymentTermID,
            PaymentMethodID,
            LinkMaster: "",
        };
        this.props.w17f2040Actions.loadUnitPrice(params, (error, data) => {
            if (error) {
                const message = error.message || Config.lang("Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            } else if (data) {
                this.defaultDynamicCol = data;
                if (this.gridContainer) this.gridContainer.instance.addRow();
            }
        })
    };

    changeDate = (e, stateName) => {
        this.setState({ [stateName]: e.value ? moment(e.value).format() : null }, () => {
            if (stateName === "voucherDate") {
                this._updateDueDate();
            }
        });
    };

    handleChangeTranType = (obj) => {
        const { divisionID, tranTypeID } = this.state;
        if (_.isEmpty(obj) || (obj && obj.TranTypeID === tranTypeID)) return;
        if (_.isEmpty(divisionID)) {
            Config.popup.show("INFO", "CRM_Ban_phai_chon_don_vi_ban");
        } else {
            if (this.state.dataGrid.length > 0 && tranTypeID && tranTypeID !== obj.TranTypeID) {
                Config.popup.show("YES_NO", Config.lang("CRM_Thay_doi_nghiep_vu_se_xoa_du_lieu_luoi_mat_hang_ban_co_muon_tiep_tuc_khong"), () => {
                    this.onChangeVoucher(obj.TranTypeID, true);
                })
            } else {
                this.onChangeVoucher(obj.TranTypeID, false);
            }
        }
    }

    handleChangeDivision = (obj) => {
        const { DivisionID: divisionID = "" } = obj || {};
        let tranTypeID = this.state.tranTypeID;
        if (!_.isEmpty(this.state.dataCboTranType) && divisionID) {
            const currentDataTranType = this.state.dataCboTranType.find(item => item.DivisionID === divisionID);
            if (currentDataTranType && currentDataTranType.TranTypeID) tranTypeID = currentDataTranType.TranTypeID;
        }
        this.setState({
            divisionID,
            tranTypeID,
        });
    }

    handleChangeCurrency = async (obj, e) => {
        const grid = [...this.state.dataGrid];
        const { ID: currencyID = "", ExchangeRate: exchangeRate = "" } = obj || {};
        if (grid.length > 0) {
            Config.popup.show("YES_NO", Config.lang("CRM_Thay_doi_loai_tien_se_tinh_toan_lai_thanh_tien_QD_ban_co_muon_tiep_tuc_khong"), async () => {
                //Thực hiện tính toán các cột có liên quan đến exchangeRate
                const exchangeR = exchangeRate ? parseInt(exchangeRate) : 1;
                await grid.forEach((obj) => { // Tính toán lại lưới khi thay đổi
                    obj.CAmountTmp = obj.OAmountTmp * exchangeR; // Thành tiền QĐ
                    obj.CAmount = obj.Amount * exchangeR; // Thành tiền sau DC QĐ
                    obj.TotalAdjustedCAmount = obj.TotalAdjustedAmount * exchangeR; // Thành tiền sau thuế QĐ
                });
                this.setState({
                    currencyID,
                    exchangeRate,
                    dataGrid: grid,
                    currencyObj: e.selectedRowsData[0] || {}
                });
            })
        } else {
            this.setState({
                currencyID,
                exchangeRate,
                currencyObj: e.selectedRowsData[0] || {}
            });
        }
    }

    handleChangeContactPerson = (obj) => {
        const { ContactPerson: contactPerson = "" , Telephone: telephone = null } = obj || {};
        this.setState({
            contactPerson,
            telephone
        });
    };

    handleChangeDeliveryMethod = (obj) => {
        this.setState({ deliveryMethodID: obj?.DeliveryMethodID || "" });
    };

    handleChangePaymentTerm = (obj) => {
        this.setState({
            paymentTermID: obj?.PaymentTermID || "",
        }, () => {
            this._updateDueDate();
        });
    }

    handleChangeStatus = (obj) => {
        this.setState({ statusVoucher: obj?.StatusID || "" });
    }

    handleChangeEmployee = (obj) => {
        this.setState({ employeeID: obj?.EmployeeID || "" });
    }

    handleChangePriceList = (obj) => {
        this.setState({ priceListID: obj?.PriceListID || "" });
    }

    handleChangePaymentMethod = (obj) => {
        this.setState({ paymentMethodID: obj?.PaymentMethodID || "" });
    }

    handleChangeWarehouse = (obj) => {
        this.setState({ wareHouseID: obj?.WareHouseID || "" });
    };

    handleChangeVATObject = (key, obj) => {
        if (!key || _.isEmpty(obj)) return;
        switch (key) {
            case "VATObjectID":
                this.setState({
                    VATObjectID: obj?.VATObjectID || "",
                    VATObjectName: obj?.VATObjectName || "",
                    VATObjectAddress: obj?.VATObjectAddress || "",
                    VATNo: obj?.VATNo || ""
                });
                break;
            case "VATObjectAddress":
            case "VATNo":
                if (this.timer) clearTimeout(this.timer);
                const value = obj?.target?.value || "";
                this.timer = setTimeout(() => {
                    this.setState({ [key]: value});
                }, 500);
                break;
            default: break;
        }
    };

    renderColumn = (dynamicColumm) => {
        let column = [];
        let totalItem = [];
        let columnDisplay = [];
        let visibleDynamicColummName = [];
        let columnDepentStatus = false;
        const {tranType} = this.state;
        const classes = useStyles();
        if (!_.isEmpty(dynamicColumm)) visibleDynamicColummName = (dynamicColumm.filter(item => item.Display === 1)).map(item => item.ColID);
        const totalItem1 = ["VATRate"];
        const totalItem2 = ["Amount", "UnitPriceOnD14", "CAmount", "TotalAdjustedCAmount", "Quantity",
            "CAmountTmp", "OAmountTmp", "UnitPrice", "TotalAdjustedAmount", "OUnitPrice", "AdjustedUnitPrice",
            "OAmountVAT", "CAmountVAT"].concat(visibleDynamicColummName); // Các cột Sum có định dạng dấu chấm sau thập phân
        [...totalItem1, ...totalItem2].forEach((value, i) => {
            if (value.includes(totalItem1)) {
                totalItem.push(
                    <TotalItem
                        key={`${i}Sum`}
                        displayFormat={"{0}"}
                        column={value}
                        summaryType={"sum"}
                    />
                )
            } else {
                totalItem.push(
                    <TotalItem
                        key={`${i}Sum`}
                        displayFormat={"{0}"}
                        column={value}
                        valueFormat={"#,##0.##"}
                        summaryType={"sum"}
                    />
                )
            }
        });

        dynamicColumm && dynamicColumm.forEach((value, index) => {
            if (!_.isEmpty(value)) {
                const visible = value.Display === 1; // = 1 hiển thị
                columnDisplay.push(value.Display);
                column.push(<Column key={index}
                    dataField={value.ColID}
                    visible={visible}
                    allowEditing={Number(_.get(tranType, "Lock" + value.ColID, "0")) !== 1}
                    caption={value.ColName}
                    cellRender={this.renderNumberFormat}
                    editCellRender={(e) => {
                        return (
                            <NumberFormat
                                style={{ width: '100%' }}
                                className={classes.numberFormatInput}
                                decimalScale={value.DecimalPlace || 0}
                                thousandSeparator={true}
                                isNumericString={true}
                                value={e.value}
                                onValueChange={(c) => e.setValue(_.toNumber(c.value))}
                            />
                        )
                    }}
                />)
            }
        });
        if (columnDisplay.length > 0) {
            const findShowDisplay = columnDisplay.includes(1);
            if (findShowDisplay) columnDepentStatus = true;
        }
        this.columnDepentStatus = columnDepentStatus;
        return {
            column,
            columnDepentStatus,
            totalItem: <Summary>{totalItem}</Summary>
            //Fix totalItem Summary khi nhận totalItem đầu tiên sẽ giữ nguyên giá trị dù sau khi caption api chạy  và update lại totalItem mới
        }
    };

    approveAction = () => {
        const { tranTypeID, quotationID, divisionID, linkMaster = "" } = this.state;
        const params = {
            DivisionID: divisionID,
            QuotationID: quotationID,
            TranTypeID: tranTypeID,
            Language: this.Language,
            LinkMaster: linkMaster
        };
        this.props.w17f2040Actions.approval(params, (error, data) => {
            if (error) {
                const message = error.message || Config.lang("Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            } else if (data) {
                const message = data.Message ? data.Message : Config.lang("CRM_Duyet_thanh_cong");
                notify(message, "success", 400);
                this.loadForm(quotationID); // Load lại form
            }
        });
    }

    unApproveAction = () => {
        const { tranTypeID, quotationID, divisionID, linkMaster = "" } = this.state;
        const params = {
            DivisionID: divisionID,
            QuotationID: quotationID,
            TranTypeID: tranTypeID,
            Language: this.Language,
            LinkMaster: linkMaster
        };
        this.props.w17f2040Actions.cancelApproval(params, (error, data) => {
            if (error) {
                const message = error.message || Config.lang("Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            } else if (data) {
                const message = data.Message ? data.Message : Config.lang("CRM_Bo_duyet_thanh_cong");
                notify(message, "success", 400);
                this.loadForm(quotationID); // Load lại form
            }
        });
    };

    checkEdit = () => {
        const { tranTypeID, quotationID, divisionID, linkMaster = "" } = this.state;
        const params = {
            DivisionID: divisionID,
            QuotationID: quotationID,
            TranTypeID: tranTypeID,
            Language: this.Language,
            LinkMaster: linkMaster
        };
        this.props.w17f2040Actions.checkEdit(params, (error, data) => {
            if (error) {
                const message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            } else if (!_.isEmpty(data)) {
                if (data.Status === 0) {
                    this.setState({ mode: "edit", saveSuccess: false });
                } else if (data.Status === 1 && data.Message) {
                    Config.popup.show("INFO", data.Message || Config.lang("CRM_Loi_chua_xac_dinh"));
                }
            }
        });
    }

    onReset = async () => {
        const { dataCboCurrency, dataCboDivision, dataCboEmployee } = this.props;
        const iPerD05F1621 = this.state.iPerD05F1621; // Giữ lại permision permis
        const iPerD05F5620 = this.state.iPerD05F5620; // Giữ lại permision them hinh anh
        await this.setState(this.initialState); // Reset lại state ban đầu và cho mode là "add"
        this.setState({
            mode: "add",
            iPerD05F1621,
            iPerD05F5620,
            currencyID: !_.isEmpty(dataCboCurrency) ? dataCboCurrency[0].ID : "", // Set lại Default giá trị mặt định đầu tiên  CRM_Loai_tien
            exchangeRate: !_.isEmpty(dataCboCurrency) ? dataCboCurrency[0].ExchangeRate : "", // Set lại Default giá trị mặt định đầu tiên
            divisionID: !_.isEmpty(dataCboDivision) ? dataCboDivision[0].DivisionID : "", // Set lại Default giá trị mặt định đầu tiên CRM_Don_vi_ban
            employeeID: this.getDefaultEmployeeID(!_.isEmpty(dataCboEmployee) ? dataCboEmployee[0].EmployeeID : ""), // Set lại Default giá trị mặt định đầu tiên  CRM_Nhan_vien_kinh_doanh
            selectedRow: []
        });
        document.getElementById("descriptionW17F2040").value = "";
        document.getElementById("shipAddressW17F2040").value = "";
    }

    onAddNewRow = () => {
        this.loadUnitPrice();
    }

    render() {
        if (!this.getInfo(true)) return null;
        const { id, formCall } = this.getInfo();
        const classes = useStyles();
        const { dataCboStatus, dataCboDivision, dataDMFSetting, dataCboPaymentMethod, dataCboVATGroup,
            dataCboTranType, dataCboEmployee, dataCboDeliveryMethod, dataCboCurrency, dataCboPaymentTerm, dataCboVAT,
            dataCboWarehouse
        } = this.props;
        const { dataAttach, attached, exchangeRate, divisionID, showW17F2020, dataCboContactPerson, mode, voucherNo,
            dataGrid, aStatus, saveSuccess, isQuotationNoneCode, showW18F1015, collapse, validFrom, showW17F2042,
            objectName, telephone, voucherNum, priceListData, dynamicColumm, iPerD05F1621, iPerD05F1631, currencyID, selectedRow,
            priceListID, validTo, showW17F2030, vatDisplay, tranTypeID, voucherDate, objectID, quotationDate, quotationNum,
            paymentTermID, employeeID, contactPerson, paymentMethodID, deliveryMethodID, currencyObj, statusVoucher, linkMaster,
            iPerD05F5620, showW17F2035, quotationID: quotationIDState, dueDate, wareHouseID, VATObjectID, VATObjectAddress,
            VATNo, tranType, showW18F1014, approveUserName, approveDate, inventoryColumns } = this.state;
        const quotationID = this.state.mode !== "add" ? (quotationIDState ? quotationIDState : id) : quotationIDState;
        const { column, totalItem, columnDepentStatus } = this.renderColumn(dynamicColumm);
        const btnAddRowStattus = isQuotationNoneCode === 1 && (mode === "add" || mode === "edit") && mode !== "view";
        const btnAddChosenRowStatus = mode !== "view";
        const numberFormatEditName = ["OUnitPrice", "Quantity", "ACol01", "ACol02", "ACol03", "ACol04", "ACol05", "ACol01Formula", "ACol02Formula", "ACol03Formula", "ACol04Formula", "ACol05Formula",
            "ACol01Rate", "ACol02Rate", "ACol03Rate", "ACol04Rate", "ACol05Rate"]; // Các cột có dùng React Number Format
        const paramPrint = [
            { type: "Varchar", id: "UserID", value: _.get(Config, "profile.UserID", "") },
            { type: "Varchar", id: "Language", value: this.Language },
            { type: "VarChar", id: "DivisionID", value: divisionID },
            { type: "VarChar", id: "DataType", value: "Export_SalesOrder" },
            { type: "VarChar", id: "QuotationID", value: quotationID },
        ];
        const btnDelClass = !!this.state.isDisabled ? 'hide' : '';
        return (
            <div className={"page-container"} style={{ margin: 0 }}>
                {this.state.loading && <Loading />}
                {showW18F1014 &&
                    <W18F1014
                        loadData={(cb) => cb(this.dataW18F1014)}
                        data={this.dataW18F1014}
                        formID={'W17F2040'}
                        state={this.getInfo()}
                        onHide={() => this.setState({ showW18F1014: false })}

                    />}
                {showW17F2030 && <W17F2030PopUp
                    formID={"W17F2040"}
                    maxWidth={"lg"}
                    objectID={objectID}
                    divisionID={divisionID}
                    priceListID={priceListID}
                    currencyID={currencyID}
                    tranTypeID={tranTypeID}
                    paymentMethodID={paymentMethodID}
                    paymentTermID={paymentTermID}
                    openModal={showW17F2030}
                    selectedRow={selectedRow}
                    dataGridMaster={dataGrid}
                    getAll={true}
                    isW17F2040={true}
                    onClose={(data, selectedRow) => this.handleW17F2030Modal(false, data, selectedRow)}
                />}
                {showW18F1015 && <W18F1015CustomerPopUp
                    mode={1}
                    companyID={""}
                    formID={"W17F2040"}
                    typeID={"AddSO"}
                    quotationID={quotationID}
                    tranTypeID={tranTypeID}
                    stateData={this.state}
                    onHide={(data, dataInventory) => {
                        this.hideW18F1015({ data, dataInventory });
                    }}
                />}
                {showW17F2020 && <W17F2020PopUp
                    companyID={""}
                    statusID={statusVoucher}
                    voucherID={quotationID}
                    formID={"W17F2040"}
                    type={"UpStatusSO"}
                    onHide={(reloadForm) => {
                        this.hideModal("W17F2020");
                        if (reloadForm) this.loadForm();
                    }}
                />}
                {showW17F2042 &&
                    <W17F2042PopUp
                        formID={"W17F2040"}
                        dataType={"CheckUpInventoryID"}
                        type={"UpInventoryID"}
                        tranTypeID={tranTypeID}
                        divisionID={divisionID}
                        quotationID={quotationID}
                        voucherID={quotationID}
                        linkMaster={linkMaster}
                        onHide={(reloadForm) => {
                            this.hideModal("W17F2042");
                            if (reloadForm) this.loadForm();
                        }}
                    />}
                {showW17F2035 && <W17F2035PopUp
                    voucherID={quotationID}
                    type={"AddImages"}
                    formID={"W17F2040"}
                    onClose={(reLoadStatus) => {
                        this.setState({ showW17F2035: false });
                        if (reLoadStatus) this.loadForm(quotationID);
                    }}
                />}
                <EventTracking action={"FORM"} label={"W17F2040"} />
                <div id={"W17F2040"} className="page-container">
                    <Row className={"mgb4x"}>
                        <Col xs={12}><Typography color={"info"} type={"h4"}>{Config.lang("Lap_don_hang")}</Typography></Col>
                        <Col xs={12}>
                            <div className={"wrap align-center"}>
                                <Button
                                    size={"small"}
                                    color={'warning'}
                                    startIcon={"Back"}
                                    label={Config.lang('Quay_lai')}
                                    labelProps={{ style: { color: 'initial' } }}
                                    onClick={this.goBack}
                                />
                                <Button
                                    size={"small"}
                                    color={'info'}
                                    startIcon={"Add"}
                                    className={"mgl1x"}
                                    label={Config.lang('Nhap_tiep')}
                                    labelProps={{ style: { color: 'initial' } }}
                                    disabled={iPerD05F1621 < 2 || mode === "add" || formCall === "W17F1026"}
                                    onClick={() => this.onReset()}
                                />
                                <Button
                                    size={"small"}
                                    color={'info'}
                                    startIcon={"Save"}
                                    className={"mgl1x"}
                                    label={Config.lang('Luu')}
                                    labelProps={{ style: { color: 'initial' } }}
                                    disabled={mode === "view" || saveSuccess || iPerD05F1621 < 2}
                                    onClick={() => this.saveData()}
                                />
                                <Button
                                    size={"small"}
                                    color={'info'}
                                    startIcon={"Edit"}
                                    className={"mgl1x"}
                                    label={Config.lang('Sua')}
                                    labelProps={{ style: { color: 'initial' } }}
                                    disabled={iPerD05F1621 < 3 || (mode === "edit" && !saveSuccess) || mode === "add"}
                                    onClick={() => this.checkEdit()}
                                />
                                <Button
                                    size={"small"}
                                    color={'success'}
                                    startIcon={"Approval"}
                                    className={"mgl1x"}
                                    label={Config.lang('Duyet')}
                                    labelProps={{ style: { color: 'initial' } }}
                                    disabled={mode !== "view" || iPerD05F1631 < 2}
                                    onClick={() => this.approveAction()}
                                />
                                <Button
                                    size={"small"}
                                    color={'danger'}
                                    startIcon={"CancelFilled"}
                                    className={"mgl1x"}
                                    label={Config.lang('Bo_duyet')}
                                    labelProps={{ style: { color: 'initial' } }}
                                    disabled={mode !== "view" || iPerD05F1631 < 4}
                                    onClick={() => this.unApproveAction()}
                                />
                                <Button
                                    size={"small"}
                                    color={'default'}
                                    startIcon={"SyncHorizontal"}
                                    className={"mgl1x"}
                                    label={Config.lang('Cap_nhat_trang_thai_thuc_hien_DH')}
                                    labelProps={{ style: { color: 'initial' } }}
                                    disabled={mode !== "view" || iPerD05F1621 < 2}
                                    onClick={() => this.checkStatusOrder()}
                                />
                                <Button
                                    size={"small"}
                                    color={'warning'}
                                    startIcon={"ShoppingCart"}
                                    className={"mgl1x"}
                                    label={Config.lang('Cap_nhat_ma_hang')}
                                    labelProps={{ style: { color: 'initial' } }}
                                    disabled={mode !== "view" || iPerD05F1621 < 4}
                                    onClick={this.checkUpdateIventoryID}
                                />
                                <Button
                                    size={"small"}
                                    color={'success'}
                                    startIcon={"Upload"}
                                    className={"mgl1x"}
                                    label={Config.lang('Dinh_kem')}
                                    labelProps={{ style: { color: 'initial' } }}
                                    disabled={mode !== "add" && mode !== "edit"}
                                    onClick={() => {
                                        document.getElementById("attachmentW17F2040").click();
                                    }}
                                />
                                {iPerD05F5620 >= 2 &&
                                    <Button
                                        size={"small"}
                                        color={'info'}
                                        startIcon={"Attachment"}
                                        className={"mgl1x"}
                                        label={Config.lang('Them_hinh_anh_mat_hang')}
                                        labelProps={{ style: { color: 'initial' } }}
                                        disabled={mode !== "view"}
                                        onClick={() => {
                                            this.setState({ showW17F2035: true });
                                        }}
                                    />
                                }
                                <ButtonExport
                                    disabled={mode === "add" || iPerD05F1621 < 2}
                                    name={Config.lang("In1")}
                                    transTypeID={"Export_Quotation"}
                                    reportTypeID={"W17F2040"}
                                    param={paramPrint}
                                    onlyShowPopup={true}
                                    className={"mgl1x"}
                                />
                            </div>
                        </Col>
                        <Col xs={12}>
                            <Collapse open={collapse}>
                                <Row id={"masterW17F2040"} className={"mgb10x"} style={{ transition: "0.2s" }}>
                                    <Col xs={12} sm={6} md={4} lg={3}>
                                        <Row>
                                            <Col xs={12}>
                                                <Label required={mode !== "view"}>{Config.lang("Khach_hang")}</Label>
                                            </Col>
                                            <Col xs={12} style={{ position: "relative" }}>
                                                <input className={`form-control ${classes.inputPopup}`}
                                                    readOnly={true}
                                                    type={"text"}
                                                    id={"customerW17F2040"}
                                                    value={objectName}
                                                    disabled={mode === "view" || mode === "edit" || saveSuccess}
                                                    onClick={() => { this.setState({ showW18F1015: true }) }}
                                                />
                                                <i className={`fa fa-sort-down ${classes.dropdownIconCb}`} />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={12} sm={6} md={4} lg={3}>
                                        <Row>
                                            <Col xs={12}>
                                                <Label required={mode !== "view"}>{Config.lang("Don_vi_ban")}</Label>
                                            </Col>
                                            <Col xs={12}>
                                                <Combo
                                                    className={classes.textCombo}
                                                    id={"divisionW17F2040"}
                                                    wordWrapEnabled={true}
                                                    showHeader={false}
                                                    // required={true} // Tạm ẩn
                                                    virtual={true}
                                                    disabled={mode === "view" || mode === "edit" || saveSuccess}
                                                    dataSource={dataCboDivision}
                                                    value={divisionID}
                                                    placeholder={""}
                                                    valueExpr={"DivisionID"}
                                                    displayExpr={"DivisionID"}
                                                    onActionWhenSelectChange={this.handleChangeDivision}
                                                >
                                                    <Column
                                                        dataField={"DivisionCustomName"}
                                                        width={"auto"}
                                                        dataType={"string"} />
                                                </Combo>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={12} sm={6} md={4} lg={3}>
                                        <Row>
                                            <Col xs={12}>
                                                <Label required={mode !== "view"}>{Config.lang("Nghiep_vu")}</Label>
                                            </Col>
                                            <Col xs={12}>
                                                <Combo
                                                    className={classes.textCombo}
                                                    id={"tranTypeW17F2040"}
                                                    wordWrapEnabled={true}
                                                    showHeader={false}
                                                    // virtual={true}
                                                    placeholder={""}
                                                    // required={true} // Tạm ẩn
                                                    // valueExpr={"TranTypeID"}
                                                    displayExpr={"TranTypeID"}
                                                    disabled={mode === "view" || mode === "edit" || saveSuccess}
                                                    dataSource={dataCboTranType}
                                                    value={tranTypeID}
                                                    onActionWhenSelectChange={this.handleChangeTranType}
                                                >
                                                    <Column
                                                        dataField={"TranTypeCustomName"}
                                                        width={"auto"}
                                                        dataType={"string"} />
                                                </Combo>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={12} sm={6} md={4} lg={3}>
                                        <Row>
                                            <Col xs={12}>
                                                <Label required={mode !== "view"}>{Config.lang("So_don_hang")}</Label>
                                            </Col>
                                            <Col xs={12} className={"display_row align-center"}>
                                                <input
                                                    value={voucherNum}
                                                    id={"voucherNumW17F2040"}
                                                    className={"form-control"}
                                                    readOnly={true} />
                                                <ButtonIcon
                                                    viewType={"text"}
                                                    name={"SyncHorizontal"}
                                                    disabled={mode !== "add"}
                                                    className={"mgl4x"}
                                                    onClick={() => { this.onChangeVoucher(tranTypeID, false) }} />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={12} sm={6} md={4} lg={3}>
                                        <Row>
                                            <Col xs={12}>
                                                <Label required={mode !== "view"}>{Config.lang("Ngay_phieu")}</Label>
                                            </Col>
                                            <Col xs={12}>
                                                <DateBox
                                                    type={"date"}
                                                    width={"100%"}
                                                    pickerType={"calendar"}
                                                    id={"voucherDateW17F2040"}
                                                    displayFormat={"dd/MM/y"}
                                                    dateSerializationFormat={"yyyy-MM-ddTHH:mm:ss"}
                                                    placeholder={Config.lang("CRM_Ngay_phieu")}
                                                    openOnFieldClick={true}
                                                    useMaskBehavior={true}
                                                    showAnalogClock={false}
                                                    value={voucherDate}
                                                    disabled={mode === "view" || saveSuccess}
                                                    ref={(ref) => this.fromDate = ref}
                                                    onValueChanged={(e) => this.changeDate(e, "voucherDate")}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={12} sm={6} md={4} lg={3}>
                                        <Row>
                                            <Col xs={12}>
                                                <Label required={mode !== "view"}>{Config.lang("Hieu_luc_tu")}</Label>
                                            </Col>
                                            <Col xs={12}>
                                                <DateBox
                                                    className={classes.textCombo}
                                                    width={"100%"}
                                                    type={"date"}
                                                    pickerType={"calendar"}
                                                    id={"validFromW17F2040"}
                                                    displayFormat={"dd/MM/y"}
                                                    placeholder={Config.lang("CRM_Hieu_luc_tu")}
                                                    dateSerializationFormat={"yyyy-MM-ddTHH:mm:ss"}
                                                    openOnFieldClick={true}
                                                    useMaskBehavior={true}
                                                    showAnalogClock={false}
                                                    value={validFrom}
                                                    ref={(ref) => this.fromDate = ref}
                                                    disabled={mode === "view" || saveSuccess}
                                                    onValueChanged={(e) => this.changeDate(e, "validFrom")}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={12} sm={6} md={4} lg={3}>
                                        <Row>
                                            <Col xs={12}>
                                                <Label>{Config.lang("Hieu_luc_den")}</Label>
                                            </Col>
                                            <Col xs={12}>
                                                <DateBox
                                                    className={classes.textCombo}
                                                    type={"date"}
                                                    width={"100%"}
                                                    id={"validToW17F2040"}
                                                    pickerType={"calendar"}
                                                    displayFormat={"dd/MM/y"}
                                                    dateSerializationFormat={"yyyy-MM-ddTHH:mm:ss"}
                                                    placeholder={Config.lang("CRM_Hieu_luc_den")}
                                                    openOnFieldClick={true}
                                                    useMaskBehavior={true}
                                                    showClearButton={true}
                                                    showAnalogClock={false}
                                                    value={validTo}
                                                    disabled={mode === "view" || saveSuccess}
                                                    onValueChanged={(e) => this.changeDate(e, "validTo")}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={12} sm={6} md={4} lg={3}>
                                        <Row>
                                            <Col xs={12}>
                                                <Label required={mode !== "view"}>{Config.lang("Nhan_vien_kinh_doanh")}</Label>
                                            </Col>
                                            <Col xs={12}>
                                                <Combo
                                                    className={classes.textCombo}
                                                    id={"employeeW17F2040"}
                                                    wordWrapEnabled={true}
                                                    showHeader={false}
                                                    virtual={true}
                                                    // required={true} // Tạm ẩn
                                                    disabled={mode === "view" || saveSuccess}
                                                    dataSource={dataCboEmployee}
                                                    value={employeeID}
                                                    placeholder={""}
                                                    valueExpr={"EmployeeID"}
                                                    displayExpr={"EmployeeName"}
                                                    onActionWhenSelectChange={this.handleChangeEmployee}
                                                >
                                                    <Column
                                                        dataField={"EmployeeCustomName"}
                                                        width={"auto"}
                                                        dataType={"string"} />
                                                </Combo>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={12} sm={6} md={4} lg={3}>
                                        <Row>
                                            <Col xs={12}>
                                                <Label>{Config.lang("Nguoi_lien_he")}</Label>
                                            </Col>
                                            <Col xs={12}>
                                                <Combo
                                                    className={classes.textCombo}
                                                    virtual={true}
                                                    showHeader={false}
                                                    showClearButton={true}
                                                    // required={true} // Tạm ẩn
                                                    placeholder={""}
                                                    valueExpr={"ContactPerson"}
                                                    displayExpr={"ContactPerson"}
                                                    disabled={mode === "view" || saveSuccess}
                                                    dataSource={dataCboContactPerson}
                                                    value={contactPerson}
                                                    onActionWhenSelectChange={this.handleChangeContactPerson}
                                                >
                                                    <Column
                                                        dataField={"ContactPerson"}
                                                        width={"auto"}
                                                        dataType={"string"}
                                                        cellRender={(e) => `${e.row.data.ContactPerson}`} />
                                                </Combo>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={12} sm={6} md={4} lg={3}>
                                        <Row>
                                            <Col xs={12}>
                                                <Label>{Config.lang("Dien_thoai")}</Label>
                                            </Col>
                                            <Col xs={12}>
                                                <Input id={"telephoneW17F2040"}
                                                    className={classes.inputText}
                                                    disabled={mode === "view" || saveSuccess}
                                                    placeholder={Config.lang("CRM_Dien_thoai")}
                                                    type={"number"}
                                                    autoComplete={"off"}
                                                    value={telephone || ""}
                                                    onChange={(e) => this.onChangeInput(e, "telephone")}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={12} sm={6} md={4} lg={3}>
                                        <Row>
                                            <Col xs={12}>
                                                <Label>{Config.lang("Dieu_khoan_TM")}</Label>
                                            </Col>
                                            <Col xs={12}>
                                                <Combo
                                                    className={classes.textCombo}
                                                    showClearButton={true}
                                                    wordWrapEnabled={true}
                                                    showHeader={false}
                                                    virtual={true}
                                                    disabled={mode === "view" || (mode === "add" && saveSuccess)}
                                                    dataSource={dataCboPaymentTerm}
                                                    value={paymentTermID}
                                                    placeholder={""}
                                                    valueExpr={"PaymentTermID"}
                                                    displayExpr={"PaymentTermName"}
                                                    onActionWhenSelectChange={this.handleChangePaymentTerm}
                                                >
                                                    <Column dataField={"PaymentTermCustomName"}
                                                        width={"auto"}
                                                        dataType={"string"} />
                                                </Combo>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={12} sm={6} md={4} lg={3}>
                                        <Row>
                                            <Col xs={12}>
                                                <Label>{Config.lang("Ngay_dao_han")}</Label>
                                            </Col>
                                            <Col xs={12}>
                                                <DateBox
                                                    className={classes.textCombo}
                                                    type={"date"}
                                                    width={"100%"}
                                                    id={"dueDateW17F2040"}
                                                    pickerType={"calendar"}
                                                    displayFormat={"dd/MM/y"}
                                                    dateSerializationFormat={"yyyy-MM-ddTHH:mm:ss"}
                                                    placeholder={Config.lang("CRM_Ngay_dao_han")}
                                                    openOnFieldClick={true}
                                                    useMaskBehavior={true}
                                                    showClearButton={true}
                                                    showAnalogClock={false}
                                                    value={dueDate}
                                                    disabled={mode === "view" || saveSuccess}
                                                    onValueChanged={(e) => this.changeDate(e, "dueDate")}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={12} sm={6} md={4} lg={3}>
                                        <Row>
                                            <Col xs={12}>
                                                <Label>{Config.lang("PT_thanh_toan")}</Label>
                                            </Col>
                                            <Col xs={12}>
                                                <Combo
                                                    className={classes.textCombo}
                                                    showClearButton={true}
                                                    showHeader={false}
                                                    // required={true} // Tạm ẩn
                                                    virtual={true}
                                                    placeholder={""}
                                                    valueExpr={"PaymentMethodID"}
                                                    displayExpr={"PaymentMethodName"}
                                                    disabled={mode === "view" || saveSuccess}
                                                    dataSource={dataCboPaymentMethod}
                                                    value={paymentMethodID}
                                                    onActionWhenSelectChange={this.handleChangePaymentMethod}
                                                >
                                                    <Column
                                                        dataField={"PaymentMethodCustomName"}
                                                        width={"auto"}
                                                        dataType={"string"} />
                                                </Combo>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={12} sm={6} md={4} lg={3}>
                                        <Row>
                                            <Col xs={12}>
                                                <Label>{Config.lang("PT_giao_hang")}</Label>
                                            </Col>
                                            <Col xs={12}>
                                                <Combo
                                                    className={classes.textCombo}
                                                    showClearButton={true}
                                                    wordWrapEnabled={true}
                                                    showHeader={false}
                                                    virtual={true}
                                                    placeholder={""}
                                                    valueExpr={"DeliveryMethodID"}
                                                    displayExpr={"DeliveryMethodName"}
                                                    disabled={mode === "view" || saveSuccess}
                                                    dataSource={dataCboDeliveryMethod}
                                                    value={deliveryMethodID}
                                                    onActionWhenSelectChange={this.handleChangeDeliveryMethod}
                                                >
                                                    <Column
                                                        dataField={"DeliveryMethodCustomName"}
                                                        width={"auto"}
                                                        dataType={"string"} />
                                                </Combo>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={12} sm={6} md={4} lg={3}>
                                        <Row>
                                            <Col xs={12}>
                                                <Label required={mode !== "view"}>{Config.lang("Loai_tien")}</Label>
                                            </Col>
                                            <Col xs={12}>
                                                <div className={"display_row align-center"}>
                                                    <div className={"mgr4x"} style={{ flexBasis: '50%' }}>
                                                        <Combo
                                                            className={classes.textCombo}
                                                            // required={true} // Tạm ẩn
                                                            totalItems={62}
                                                            virtual={true}
                                                            placeholder={""}
                                                            valueExpr={"ID"}
                                                            displayExpr={"ID"}
                                                            id={"currencyW17F2040"}
                                                            dataSource={dataCboCurrency}
                                                            value={currencyID}
                                                            disabled={mode === "view" || saveSuccess}
                                                            onActionWhenSelectChange={(key, e) => {
                                                                this.handleChangeCurrency(key, e)
                                                            }}
                                                        >
                                                            <Column dataField={"ID"}
                                                                caption={""}
                                                                dataType={"string"} />
                                                        </Combo>
                                                    </div>
                                                    <NumberFormat decimalScale={(dataDMFSetting && dataDMFSetting.D90_ConvertedDecimals) || 0} value={exchangeRate} displayType={"text"} thousandSeparator={true} />
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={12} sm={6} md={4} lg={3}>
                                        <Row>
                                            <Col xs={12}>
                                                <Label>{Config.lang("Bang_gia")}</Label>
                                            </Col>
                                            <Col xs={12}>
                                                <Combo
                                                    className={classes.textCombo}
                                                    disabled={mode === "view" || saveSuccess}
                                                    virtual={true}
                                                    showHeader={false}
                                                    // required={true} // Tạm ẩn
                                                    dataSource={priceListData}
                                                    value={priceListID}
                                                    placeholder={""}
                                                    // valueExpr={"PriceListID"}
                                                    id={"priceListW17f2040"}
                                                    displayExpr={"DescriptionU"}
                                                    onActionWhenSelectChange={this.handleChangePriceList}
                                                >
                                                    <Column
                                                        dataField={"PriceListCustomName"}
                                                        width={"auto"}
                                                        dataType={"string"} />
                                                </Combo>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={12} sm={6} md={4} lg={3}>
                                        <Row>
                                            <Col xs={12}>
                                                <Label>{Config.lang("Trang_thai_thuc_hien_DH")}</Label>
                                            </Col>
                                            <Col xs={12} style={{ position: "relative" }}>
                                                <Combo
                                                    className={classes.textCombo}
                                                    showClearButton={true}
                                                    showHeader={false}
                                                    // required={true} // Tạm ẩn
                                                    virtual={true}
                                                    placeholder={""}
                                                    valueExpr={"StatusID"}
                                                    displayExpr={"StatusName"}
                                                    value={statusVoucher}
                                                    dataSource={dataCboStatus}
                                                    disabled={mode === "view" || saveSuccess}
                                                    onActionWhenSelectChange={this.handleChangeStatus}
                                                >
                                                    <Column
                                                        dataField={"StatusCustomName"}
                                                        width={"auto"}
                                                        dataType={"string"} />
                                                </Combo>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={12} sm={6} md={4} lg={3}>
                                        <Row>
                                            <Col xs={12}>
                                                <Label>{Config.lang("So_bao_gia")}</Label>
                                            </Col>
                                            <Col xs={12}>
                                                <Input id={"quotationNumW17F2040"}
                                                    className={classes.inputText}
                                                    disabled={true}
                                                    placeholder={Config.lang("CRM_So_bao_gia")}
                                                    // type={"number"}
                                                    autoComplete={"off"}
                                                    value={quotationNum || ""}
                                                // onChange={(e) => this.onChangeInput(e, "quotationNum")}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={12} sm={6} md={4} lg={3}>
                                        <Row>
                                            <Col xs={12}>
                                                <Label>{Config.lang("So_chung_tu")}</Label>
                                            </Col>
                                            <Col xs={12}>
                                                <Input id={"voucherNoW17F2040"}
                                                    className={classes.inputText}
                                                    disabled={mode === "view" || saveSuccess}
                                                    placeholder={Config.lang("CRM_So_chung_tu")}
                                                    autoComplete={"off"}
                                                    value={voucherNo || ""}
                                                    onChange={(e) => this.onChangeInput(e, "voucherNo")}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={12} sm={6} md={4} lg={3}>
                                        <Row>
                                            <Col xs={12}>
                                                <Label>{Config.lang("Ngay_chung_tuU")}</Label>
                                            </Col>
                                            <Col xs={12}>
                                                <DateBox
                                                    type={"date"}
                                                    width={"100%"}
                                                    pickerType={"calendar"}
                                                    id={"quotationDateW17F2040"}
                                                    displayFormat={"dd/MM/y"}
                                                    dateSerializationFormat={"yyyy-MM-ddTHH:mm:ss"}
                                                    placeholder={Config.lang("CRM_Ngay_chung_tuU")}
                                                    openOnFieldClick={true}
                                                    useMaskBehavior={true}
                                                    showClearButton={true}
                                                    showAnalogClock={false}
                                                    value={quotationDate}
                                                    disabled={mode === "view" || saveSuccess}
                                                    onValueChanged={(e) => this.changeDate(e, "quotationDate")}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>

                                    <Col xs={12} sm={6} md={4} lg={3}>
                                        <Row>
                                            <Col xs={12}>
                                                <Label>{Config.lang("Kho_xuat")}</Label>
                                            </Col>
                                            <Col xs={12}>
                                                <Combo
                                                    className={classes.textCombo}
                                                    disabled={mode === "view" || saveSuccess}
                                                    virtual={true}
                                                    showHeader={false}
                                                    // required={true} // Tạm ẩn
                                                    dataSource={dataCboWarehouse}
                                                    value={wareHouseID}
                                                    placeholder={""}
                                                    valueExpr={"WareHouseID"}
                                                    id={"wareHouseW17f2040"}
                                                    displayExpr={"WareHouseName"}
                                                    onActionWhenSelectChange={this.handleChangeWarehouse}
                                                >
                                                    <Column
                                                        dataField={"WareHouseName"}
                                                        width={"auto"}
                                                        dataType={"string"} />
                                                </Combo>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={12} sm={12} md={6} lg={aStatus === 1 ? 3 : 4}>
                                        <Row>
                                            <Col xs={12}>
                                                <Label>{Config.lang("Ghi_chu")}</Label>
                                            </Col>
                                            <Col xs={12}>
                                                <textarea
                                                    style={{ backgroundColor: 'transparent !important' }}
                                                    rows={4}
                                                    className={"form-control"}
                                                    id={"descriptionW17F2040"}
                                                    disabled={mode === "view" || saveSuccess}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>

                                    {aStatus === 1 &&
                                        <Col xs={12} sm={6} md={6} lg={2}>
                                            <Image alt={""}
                                                id={"passStampW17F2040"}
                                                width={'100%'}
                                                height={'auto'}
                                                src={require("./../../images/approve-stamp.png")} />
                                            <div className={"display_row align-center mgb1x"}><Typography className={"pdr1x"} type={"h4"}>{Config.lang("Nguoi_duyet")}:</Typography><Typography color={"secondary"} type={"p2"}>{approveUserName}</Typography></div>
                                            <div className={"display_row align-center"}><Typography className={"pdr1x"} type={"h4"}>{Config.lang("Ngay_duyet")}:</Typography><Typography color={"secondary"} type={"p2"}>{approveDate}</Typography></div>
                                            {/* <div id={"approveNoteW17F2040"}> {approveNote} </div> */}
                                        </Col>}

                                    <Col xs={12} sm={12} md={aStatus === 1 ? 6 : 5} lg={aStatus === 1 ? 4 : 5}>
                                        <Row>
                                            <Col xs={12}>
                                                <Label>{Config.lang("Tai_lieu_dinh_kem")}</Label>
                                            </Col>
                                            <Col xs={12}>
                                                <div className={"display_row list-attach"} style={{ minHeight: 94 }}>
                                                    {(dataAttach.length === 0 && attached.length === 0) && <span
                                                        className={"text-no-data"}>{Config.lang("CRM_Khong_co_du_lieu")}</span>}
                                                    {attached && attached.map((value, index) => {
                                                        return (
                                                            <div className={'view-attachment mgr1x'}
                                                                key={'view-attachment' + index}>
                                                                <a href={Config.env.api + '/file/get?AttachmentID=' + value.AttachmentID}
                                                                    download>
                                                                    {this.renderIcon(value.FileName, value.URL)}
                                                                </a>
                                                                <Typography type={"p3"} className={"mgt1x"}>{value.FileName}</Typography>
                                                                <ButtonIcon circular
                                                                    viewType={'ghost'}
                                                                    className={`deleteAttach ${btnDelClass}`}
                                                                    size={'small'}
                                                                    name={"Delete"}
                                                                    color={"danger"}
                                                                    onClick={() => {
                                                                        this.deleteOldAttach(value)
                                                                    }}
                                                                />
                                                            </div>
                                                        )
                                                    })}
                                                    {dataAttach.map((value, index) => {
                                                        if (value.uploading) {
                                                            return (<div className={"view-loading"}>
                                                                <i key={index}
                                                                    className="fas fa-spinner fa-spin" />
                                                            </div>)
                                                        } else return (
                                                            <div className={'view-attachment mgr1x'}
                                                                key={'view-attachment1' + index}>
                                                                {this.renderIcon(value.FileName, value.URL)}
                                                                <Typography type={"p3"} className={"mgt1x"}>{value.FileName}</Typography>
                                                                <ButtonIcon circular
                                                                    viewType={'ghost'}
                                                                    className={`deleteAttach ${btnDelClass}`}
                                                                    size={'small'}
                                                                    name={"Delete"}
                                                                    color={"danger"}
                                                                    onClick={() => {
                                                                        this.deleteAttach(value)
                                                                    }}
                                                                />
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={12} sm={6} md={4} lg={3}>
                                        <Row>
                                            <Col xs={12}>
                                                <Label>{Config.lang("Noi_nhan_hang")}</Label>
                                            </Col>
                                            <Col xs={12}>
                                                <Input id={"shipAddressW17F2040"}
                                                    className={classes.inputText}
                                                    disabled={mode === "view" || saveSuccess}
                                                    placeholder={Config.lang("Noi_nhan_hang")}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={12}>
                                        <AccordionGroup>
                                            <Accordion expand className={"mgb1x"}>
                                                <AccordionSummary>
                                                    {Config.lang("Thong_tin_thue_GTGT")}
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Row>
                                                        <Col xs={12} sm={6} md={4} lg={3}>
                                                            <Row>
                                                                <Col xs={12}>
                                                                    <Label>{Config.lang("Doi_tuong_thue")}</Label>
                                                                </Col>
                                                                <Col xs={12}>
                                                                    <Combo
                                                                        className={classes.textCombo}
                                                                        disabled={mode === "view" || saveSuccess}
                                                                        virtual={true}
                                                                        showHeader={false}
                                                                        // required={true} // Tạm ẩn
                                                                        dataSource={dataCboVAT}
                                                                        value={VATObjectID}
                                                                        placeholder={""}
                                                                        valueExpr={"VATObjectID"}
                                                                        id={"VATObjectW17f2040"}
                                                                        displayExpr={"VATObjectName"}
                                                                        onActionWhenSelectChange={(e) => this.handleChangeVATObject("VATObjectID", e)}
                                                                    >
                                                                        <Column
                                                                            dataField={"VATObjectName"}
                                                                            width={"auto"}
                                                                            dataType={"string"} />
                                                                    </Combo>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col xs={12} sm={6} md={8} lg={6}>
                                                            <Row>
                                                                <Col xs={12}>
                                                                    <Label>{Config.lang("Dia_chi_doi_tuong_thue")}</Label>
                                                                </Col>
                                                                <Col xs={12}>
                                                                    <input className={'form-control'}
                                                                        ref={"txtVATObjectAddress"}
                                                                        onChange={(e) => this.handleChangeVATObject("VATObjectAddress", e)}
                                                                        disabled={mode === "view" || saveSuccess}
                                                                        defaultValue={VATObjectAddress} />
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col xs={12} sm={6} md={4} lg={3}>
                                                            <Row>
                                                                <Col xs={12}>
                                                                    <Label>{Config.lang("MST")}</Label>
                                                                </Col>
                                                                <Col xs={12}>
                                                                    <input className={'form-control'}
                                                                        ref={"txtVATNo"}
                                                                        onChange={(e) => this.handleChangeVATObject("VATNo", e)}
                                                                        disabled={mode === "view" || saveSuccess}
                                                                        defaultValue={VATNo} />
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                </AccordionDetails>
                                            </Accordion>
                                        </AccordionGroup>
                                    </Col>
                                </Row>
                            </Collapse>
                        </Col>
                        <Col xs={12}>
                            <div className={"mgb1x"} style={{ marginLeft: 'auto', display: 'block' }}>
                                <Button
                                    size={"small"}
                                    color={'info'}
                                    startIcon={"Add"}
                                    label={Config.lang('Them')}
                                    disabled={!btnAddRowStattus}
                                    labelProps={{ style: { color: 'initial' } }}
                                    onClick={() => this.onAddNewRow()}
                                />
                                <Button
                                    size={"small"}
                                    color={'primary'}
                                    viewType={"outlined"}
                                    className={`mgl1x`}
                                    disabled={!btnAddChosenRowStatus}
                                    label={Config.lang('Chon_hang_hoa')}
                                    labelProps={{ style: { color: 'initial' } }}
                                    onClick={() => this.setState({ showW17F2030: true })}
                                />
                                <ButtonIcon
                                    width={22}
                                    height={22}
                                    viewType={"ghost"}
                                    color={"info"}
                                    className={"pull-right"}
                                    name={"ArrowDownFilled"}
                                    style={{ transition: 'transform .5s', transform: `${collapse ? 'rotate(177deg)' : ''}` }}
                                    onClick={() => this.setState({ collapse: !this.state.collapse })}
                                />
                            </div>
                            <GridContainer
                                reference={(e) => this.gridContainer = e}
                                totalItems={dataGrid.length}
                                dataSource={dataGrid}
                                hoverStateEnabled={true}
                                elementAttr={{ class: classes.customGridCss }}
                                gridProps={{
                                    dateSerializationFormat: "yyyy-MM-dd"
                                }}
                                onEditorPreparing={(e) => {
                                    this.renderCboUnit(e);
                                }}
                                onRowUpdating={async (e) => {
                                    if (!e) return;
                                    const columnName = (Object.keys(e.newData))[0];
                                    if (numberFormatEditName.includes(columnName) && e.oldData) { // Xữ lý NumberFormat vì editCellRender không v6 onEditorPreparing
                                        e.oldData[columnName] = e.newData[columnName];
                                        await this.calculate(e.oldData, columnName, e); // Thực hiện tính toán những cột xài thư viện NumberFormat
                                        e.component.refresh();
                                    }
                                }}
                                onInitNewRow={(eMethod) => {
                                    eMethod.data.VATRate = null;
                                    eMethod.data.UnitPrice = null;
                                    eMethod.data.UnitPriceOnD14 = null;
                                    eMethod.data.UnitID = "";
                                    eMethod.data.VATGroupID = "";
                                    eMethod.data.InventoryID = "";
                                    eMethod.data.InventoryName = "";
                                    eMethod.data.QuotationItemID = "";
                                    eMethod.data.Notes = "";
                                    if (!_.isEmpty(this.defaultDynamicCol) && _.isObject(this.defaultDynamicCol)) {
                                    const arrayColKey = Object.keys(this.defaultDynamicCol);
                                    for (let i = 0; i < arrayColKey.length; i++) {
                                        eMethod.data[arrayColKey[i]] = this.defaultDynamicCol[arrayColKey[i]];
                                    }
                                }
                                }}
                                onRowInserted={(e) => {
                                    if (!e) return;
                                    this.calculate(e.data, "VATGroupID", e); // Thực hiện tính toán ngay khi lúc insert vào lưới
                                    e.component.refresh();
                                }}
                                filterRow={{
                                    visible: true,
                                    applyFilter: 'auto'
                                }}
                                editing={{
                                    mode: "cell",
                                    refreshMode: "reshape",
                                    allowUpdating: mode !== "view" && !(mode === "add" && saveSuccess),
                                    texts: { confirmDeleteMessage: "" },
                                    startEditAction: "click",
                                }}
                            >
                                <Column
                                    width={50}
                                    alignment={"center"}
                                    allowEditing={false}
                                    cellRender={(data) => data.rowIndex + 1}
                                />
                                <Column
                                    width={120}
                                    allowSorting={false}
                                    allowEditing={false}
                                    showInColumnChooser={false}
                                    alignment={"center"}
                                    visible={Config.isMobile && mode !== "view"}
                                    caption={Config.lang("CRM_Hanh_dong")}
                                    cellRender={(e) => this.renderActionHover(e)}
                                />
                                <Column
                                    width={180}
                                    allowSorting={true}
                                    dataField={"InventoryID"}
                                    caption={Config.lang("CRM_Ma_hang_hoa")}
                                    allowEditing={isQuotationNoneCode === 0}
                                />
                                <Column
                                    allowSorting={true}
                                    dataField={"InventoryName"}
                                    caption={Config.lang("CRM_Ten_hang_hoa")}
                                    allowEditing={isQuotationNoneCode === 1}
                                />
                                <Column
                                    width={100}
                                    dataField={"UnitID"}
                                    caption={Config.lang("CRM_DVT")}
                                    allowEditing={false}
                                />
                                <Column
                                    width={150}
                                    allowEditing={false}
                                    dataField={"UnitPriceOnD14"}
                                    caption={Config.lang("CRM_Gia_goc")}
                                    cellRender={this.renderNumberFormat}
                                />
                                <Column
                                    dataField={"Notes"}
                                    width={150}
                                    caption={Config.lang("CRM_Dien_giai")}
                                />
                                <Column
                                    width={150}
                                    format={"dd/MM/yyyy"}
                                    alignment={"center"}
                                    dataType={"date"}
                                    editorOptions={{
                                        dateSerializationFormat: 'yyyy-MM-dd'
                                    }}
                                    dataField={"DeliveryDate"}
                                    caption={Config.lang("CRM_Ngay_giao_hang")}
                                />
                                <Column
                                    width={150}
                                    allowEditing={Number(_.get(tranType, "LockAllowEditUnitPrice", "0")) !== 1}
                                    dataField={"OUnitPrice"}
                                    caption={Config.lang("DG_truoc_thue")}
                                    cellRender={this.renderNumberFormat}
                                    editCellRender={(e) => {
                                        return (
                                            <NumberFormat
                                                style={{ width: '100%' }}
                                                className={classes.numberFormatInput}
                                                decimalScale={!_.isEmpty(currencyObj) ? currencyObj.OriginalDecimal : 0}
                                                thousandSeparator={true}
                                                isNumericString={true}
                                                value={e.value}
                                                onValueChange={(c) => e.setValue(_.toNumber(c.value))}
                                            />
                                        )
                                    }}
                                />
                                <Column
                                    width={120}
                                    dataType={"number"}
                                    dataField={"Quantity"}
                                    caption={Config.lang("CRM_So_luong")}
                                    cellRender={this.renderNumberFormat}
                                    editCellRender={(e) => {
                                        return (
                                            <NumberFormat
                                                style={{ width: '100%' }}
                                                className={classes.numberFormatInput}
                                                decimalScale={!_.isEmpty(dataDMFSetting) ? dataDMFSetting.D07_QuantityDecimals : 0}
                                                thousandSeparator={true}
                                                isNumericString={true}
                                                value={e.value}
                                                onValueChange={(c) => e.setValue(_.toNumber(c.value))}
                                            />
                                        )
                                    }}
                                />
                                <Column
                                    allowEditing={false}
                                    dataField={"OAmountTmp"}
                                    caption={Config.lang("CRM_Thanh_tien")}
                                    cellRender={this.renderNumberFormat}
                                />
                                <Column
                                    width={150}
                                    allowEditing={false}
                                    dataField={"CAmountTmp"}
                                    caption={Config.lang("CRM_Thanh_tien_QD")}
                                    cellRender={this.renderNumberFormat}
                                />
                                {/* Cột Động */}
                                {column}
                                {/* Cột Động */}
                                <Column
                                    width={180}
                                    allowEditing={false}
                                    visible={columnDepentStatus}
                                    dataType={"number"}
                                    dataField={"AdjustedUnitPrice"}
                                    caption={Config.lang("CRM_Don_gia_sau_DC")}
                                    cellRender={this.renderNumberFormat}
                                />
                                <Column
                                    width={180}
                                    allowEditing={false}
                                    dataField={"Amount"}
                                    visible={columnDepentStatus}
                                    caption={Config.lang("CRM_Thanh_tien_sau_DC")}
                                    cellRender={this.renderNumberFormat}
                                />
                                <Column
                                    width={180}
                                    allowEditing={false}
                                    dataField={"CAmount"}
                                    visible={columnDepentStatus}
                                    caption={Config.lang("CRM_Thanh_tien_sau_DC_QD")}
                                    cellRender={this.renderNumberFormat}
                                />
                                <Column
                                    width={180}
                                    dataType={"number"}
                                    dataField={"VATGroupID"}
                                    caption={Config.lang("CRM_Nhom_thue")}
                                    visible={vatDisplay === 1}
                                >
                                    <Lookup
                                        allowClearing={true}
                                        valueExpr={"VATGroupID"}
                                        displayExpr={"VATGroupID"}
                                        dataSource={dataCboVATGroup}
                                    />
                                </Column>
                                <Column
                                    width={120}
                                    allowEditing={false}
                                    dataField={"VATRate"}
                                    visible={vatDisplay === 1}
                                    caption={`${Config.lang("CRM_Thue_suat")} %`}
                                    format={"#,##0.##"}
                                    dataType={"number"}
                                />
                                <Column
                                    width={180}
                                    allowEditing={false}
                                    alignment={"right"}
                                    dataField={"OAmountVAT"}
                                    visible={vatDisplay === 1}
                                    caption={Config.lang("CRM_Tien_thue")}
                                    cellRender={this.renderNumberFormat}
                                />
                                <Column
                                    width={180}
                                    allowEditing={false}
                                    alignment={"right"}
                                    dataField={"CAmountVAT"}
                                    visible={vatDisplay === 1}
                                    caption={Config.lang("CRM_Tien_thue_QD")}
                                    cellRender={this.renderNumberFormat}
                                />
                                <Column
                                    width={180}
                                    allowEditing={false}
                                    alignment={"right"}
                                    dataField={"UnitPrice"}
                                    visible={false}
                                    caption={Config.lang("CRM_Don_gia_sau_thue")}
                                // cellRender={this.renderNumberFormat}
                                />
                                <Column
                                    width={180}
                                    allowEditing={false}
                                    visible={vatDisplay === 1}
                                    dataField={"TotalAdjustedAmount"}
                                    caption={Config.lang("CRM_Tong_tien")}
                                    cellRender={this.renderNumberFormat}
                                />
                                <Column
                                    width={180}
                                    allowEditing={false}
                                    dataType={"number"}
                                    visible={vatDisplay === 1}
                                    dataField={"TotalAdjustedCAmount"}
                                    caption={Config.lang("CRM_Tong_tien_QD")}
                                    cellRender={this.renderNumberFormat}
                                />
                                {inventoryColumns && inventoryColumns.map(col => {
                                    return (
                                        <Column
                                            key={col.FieldID}
                                            width={150}
                                            allowEditing={false}
                                            trueText={Config.lang("Co1")}
                                            falseText={Config.lang("Khong")}
                                            dataType={"boolean"}
                                            dataField={col.FieldID}
                                            caption={col.Caption}
                                        />
                                    );
                                })}
                                <Column
                                    width={50}
                                    fixed={true}
                                    alignment={"right"}
                                    fixedPosition={"right"}
                                    allowEditing={false}
                                    allowFixing={true}
                                    visible={!Config.isMobile && mode !== "view"}
                                    cellRender={(e) => this.renderActionHover(e)}
                                />
                                {/* SUM TOTAL Ở DƯỚI LƯỚI */}
                                {totalItem}
                                {/* SUM TOTAL Ở DƯỚI LƯỚI */}
                            </GridContainer>
                        </Col>

                    </Row>
                </div>
                <input
                    id={"attachmentW17F2040"}
                    multiple={"multiple"}
                    type={"file"}
                    className={"media_upload_input hide"}
                    onChange={this.upLoadAttach}
                />
            </div >
        )
    }
}

export default compose(connect((state) => ({
    cboStatus: state.w17f2040.cboStatus,
    dataCboDivision: state.w17f2040.dataCboDivision,
    dataCboTranType: state.w17f2040.dataCboTranType,
    dataCboEmployee: state.w17f2040.dataCboEmployee,
    dataCboDeliveryMethod: state.w17f2040.dataCboDeliveryMethod,
    dataCboPaymentTerm: state.w17f2040.dataCboPaymentTerm,
    dataCboPaymentMethod: state.w17f2040.dataCboPaymentMethod,
    dataCboCurrency: state.w17f2040.dataCboCurrency,
    dataCboStatus: state.w17f2040.dataCboStatus,
    dataCboVATGroup: state.w17f2040.dataCboVATGroup,
    dataDMFSetting: state.w17f2040.dataDMFSetting,
    dataCboVAT: state.w17f2040.dataCboVAT,
    dataCboWarehouse: state.w17f2040.dataCboWarehouse,
}), (dispatch) => ({
    w17f2040Actions: bindActionCreators(w17f2040Actions, dispatch),
    userActions: bindActionCreators(userActions, dispatch),
    w18F1014Actions: bindActionCreators(w18F1014Actions, dispatch),
})))(W17F2040Page);

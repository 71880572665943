/**
 * @copyright 2021 @ DigiNet
 * @author ANHTAI
 * @create 22/01/2021
 * @update 05/03/2021
 * @file src/components/header/header.js
 */
import { withStyles } from "@material-ui/styles";
import { Column } from "devextreme-react/data-grid";
import notify from "devextreme/ui/notify";
import _ from "lodash";
import PropTypes from "prop-types";
import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import * as w17f2020Actions from "../../actions/w17/w17F2020/w17f2020-actions";
import Config from "../../config";
import ButtonSave from "../common/button/button-save";
import Combo from "../common/dropdown/combo";
import EventTracking from "../common/tracking/event";
import PopupScroll from "../popup/popup-scroll";

const styles = theme => {
    return {
        btnSave: {
            display: 'block',
            margin: '15px 0 10px auto',
        },
        dropdownIconCb: {
            position: 'absolute',
            top: 8,
            right: 26,
            pointerEvents: 'none'
        }
    }
};

class W17F2020Page extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            cboLoading: false,
            statusID: "",
            statusName: "",
            dataCboStatus: []
        };
        this.paramStatus = {
            Language: Config.language || "84",
            FormID: "",
            Type: "",
            DataType: "",
            VoucherID: "",
        }
    }

    componentDidMount = () => {
        const { voucherID, type, formID, statusID } = this.props;
        this.paramStatus.Type = type;
        this.paramStatus.VoucherID = voucherID;
        this.paramStatus.FormID = formID;
        this.paramStatus.StatusID = statusID;
        this.getComboData();
    }

    getComboData = () => {
        const { Language, FormID, Type, VoucherID } = this.paramStatus;
        const params = {
            Language,
            Type,
            FormID,
            DataType: "",
            VoucherID
        };
        this.setState({ cboLoading: true });
        this.props.w17f2020Actions.getCboStatusW17F2020(params, (error, data) => {
            this.setState({ cboLoading: false });
            if (error) {
                const message = error.message || "Lỗi chưa xác định";
                Config.popup.show("INFO", message);
                return false;
            } else if (data) {
                this.setState({ dataCboStatus: data });
            }
        });
    }

    onHide = (reLoadStatus = false) => {
        if (this.props.onHide) this.props.onHide(reLoadStatus);
    }

    saveStatus = () => {
        const { Language, FormID, Type, VoucherID } = this.paramStatus;
        const Notes = document.getElementById("notesW17F2020").value;
        if (_.isEmpty(this.state.statusID)) {
            Config.popup.show("INFO", `${Config.lang("CRM_Trang_thai")} ${(Config.lang("CRM_Khong_duoc_de_trong")).toLowerCase()}`);
            document.getElementById("statusW17F2020").getElementsByClassName("dx-texteditor-input")[0].focus();
            return false;
        }
        const params = {
            Language,
            FormID,
            Type,
            DataType: "Save",
            VoucherID,
            StatusID: this.state.statusID,
            Notes
        };
        this.setState({ cboLoading: true });
        this.props.w17f2020Actions.saveW17F2020(params, (error, data) => {
            this.setState({ cboLoading: false });
            if (error) {
                const message = error.message || "Lỗi chưa xác định";
                Config.popup.show("INFO", message);
                return false;
            } else if (data) {
                this.onHide(true); // Đóng Form và Reload Data
                notify(Config.lang("CRM_Luu_thanh_cong"), "success", 400);
            }
        })
    }

    render() {
        const { classes } = this.props;
        const { cboLoading, statusID, dataCboStatus } = this.state;

        return (
            <PopupScroll
                title={Config.lang("CRM_Cap_nhat_trang_thai")}
                show={true}
                className={"popup-size-40"}
                animation={true}
                onHide={this.onHide}>
                {Config && Config.profile &&
                    <EventTracking category={"TRACKING_USER"} action={"W17F2020"} label={Config.profile.UserID} />}
                <div className={"page-container"}
                    id={"frmW17F2020"}>
                    <Form horizontal={true}>
                        <Row>
                            <Col md={2} lg={2} sm={2} xs={12} >
                                <label className={`control-label ${classes.labelName}`}>{Config.lang("CRM_Trang_thai")} </label>
                            </Col>
                            <Col md={10} sm={10} xs={12} lg={10} style={{ marginBottom: 15 }}>
                                <Combo
                                    id={"statusW17F2020"}
                                    wordWrapEnabled={true}
                                    showHeader={false}
                                    virtual={false}
                                    disabled={cboLoading}
                                    dataSource={dataCboStatus}
                                    value={statusID}
                                    placeholder={""}
                                    valueExpr={"StatusID"}
                                    displayExpr={"StatusName"}
                                    onActionWhenSelectChange={(obj) => {
                                        this.setState({
                                            statusID: obj.StatusID,
                                            statusName: obj.StatusName
                                        })
                                    }}
                                >
                                    <Column dataField={"StatusName"}
                                        width={"auto"}
                                        dataType={"string"}
                                        cellRender={(e) => `${e.row.data.StatusName}`} />
                                </Combo>
                            </Col>
                            <Col md={12} sm={12} xs={12} lg={12}>
                                <Row>
                                    <Col md={2} lg={2} sm={2} xs={12}>
                                        <label className={`control-label ${classes.labelName}`}>{Config.lang("CRM_Ghi_chu")}</label>
                                    </Col>
                                    <Col md={10} sm={10} xs={12} lg={10}>
                                        <textarea
                                            rows={4}
                                            className={"form-control"}
                                            id={"notesW17F2020"}
                                            disabled={cboLoading}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={12} sm={12} xs={12} lg={12}>
                                <ButtonSave name={Config.lang("CRM_Luu")}
                                    disabled={cboLoading}
                                    className={`${classes.btnSave}`} onClick={() => this.saveStatus()} />
                            </Col>
                        </Row>
                    </Form>
                </div>
            </PopupScroll>
        )
    }
}

W17F2020Page.propTypes = {
    companyID: PropTypes.string,
    statusID: PropTypes.string,
    voucherID: PropTypes.string,
    formID: PropTypes.string,
    type: PropTypes.string,
    onHide: PropTypes.func,
};

export default compose(connect((state) => ({
}), (dispatch) => ({
    w17f2020Actions: bindActionCreators(w17f2020Actions, dispatch),
})), withStyles(styles, { withTheme: true }))(W17F2020Page);

/**
 * @copyright 2019 @ DigiNet
 * @author rocachien
 * @create 2019/01/11 10:37
 * @update 2019/01/11 10:37
 * @file src/components/header/header.js
 */

import React from 'react';
import {connect} from "react-redux";
import {browserHistory} from "react-router";
import {bindActionCreators} from "redux";

import * as W17F2051Actions from "../../../actions/w17/W17F2051/W17F2051-actions";
import * as userActions from "../../../actions/user-actions";
import {Column} from 'devextreme-react/data-grid';
import {Loading} from "../../common/loading/loading";
import Config from "../../../config";
import EventTracking from "../../common/tracking/event";
import notify from 'devextreme/ui/notify';

import PageContainer from "../../common/page-container/page-container";
import Combo from '../../common/dropdown/combo';
import {Row, Col, Collapse, Form, FormGroup } from "react-bootstrap";

import ButtonSave from '../../common/button/button-save';
import ButtonAdd from '../../common/button/button-add';
import ButtonCustom from '../../common/button/button-custom';
import ButtonEdit from '../../common/button/button-edit';
import { DateBox } from 'devextreme-react';
import _ from "lodash"
import NumberFormat from 'react-number-format';

class W17F2051Page extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state ={
            loadingNewKey:false,
            loading:false,
            iPermission:0,
            allowAddMore:false,
            collapse:{
                1:true,
              
            },
            masterInputData:{

            },
            anaMaster: [],
            AdditionalMaster:[]
        }
    }
    async loadPermission() {
        await this.props.userActions.getPermission(Config.profile.UserID, "W17F2050",(iPer)=>{
            this.setState({iPermission: iPer});
        });
    }
    componentDidMount() {
        this.loadPermission()
        const {mode} = this.getInfo()
        // load cb
        this.props.W17F2051Actions.getCboCategoryType()
        this.props.W17F2051Actions.getCboGender()
       
        this.props.W17F2051Actions.getAnaMaster({},(error,data) =>{
            if (error || !data) {
                return
            }
            let categories =_.groupBy(data,"AnaCategoryID")
            let anaMaster = _.map(categories,(value,AnaCategoryID) =>{
                const curAnaData = _.find(this.state.anaMaster,{AnaCategoryID}) || {}
                return {
                    ...curAnaData,
                    AnaCategoryID,
                    AnaList:value
                }
            })
           this.setState({anaMaster})

        })
        this.props.W17F2051Actions.getAnaMasterCaption({},(error,data) =>{
            if (error || !data) {
                return
            }
            const {anaMaster} = this.state
            _.forEach(data,anaCaption => {
                if (anaCaption.AnaCategoryID) {
                    let index =  anaMaster.findIndex(item =>  item.AnaCategoryID === anaCaption.AnaCategoryID)
                    if (index !== -1) {
                        anaMaster[index] = {
                            ...anaMaster[index],
                            ...anaCaption
                        }
                    }else{
                        anaMaster.push(anaCaption)
                    }
                }
            })
            this.setState({anaMaster})

        })
       
        this.props.W17F2051Actions.getAdditionalMasterCaption({},(error,data) =>{
            if (error || !data) {
                return
            }
            const AdditionalMaster =[]
            _.forEach(data,item => {
                item.DataType =_.startsWith(item.DataID,"Num",4) 
                                ?"Num"
                                :_.startsWith(item.DataID,"Date",4)?"Date":"String"
                AdditionalMaster.push(item)
            })
            this.setState({AdditionalMaster})

        })
        if (mode === "add") {
            //this.getNewKey()
        }
        if (mode === "view" || mode === "edit") {
            this.loadFormMaster()
        }

    }
    getInfo =() => {
        const {location} = this.props
        const url = new window.URLSearchParams(window.location.search);
        let state =  {}
        const keys = [...url.keys()]
        if (keys.length) {
           
            for (let key of keys) {
               state[key] = url.get(key)  
            }
        }
        state = {
            ...state,
            ...( (location && location.state) || {})
        }
        state.mode = state.mode || "add"
        return state
    }
    loadFormMaster = () =>{
        const {ObjectID} = this.getInfo()
        const params = {
            Language: Config.language || '84',
            FormID: 'W17F2051',
            CodeID:ObjectID
        }
        this.setState({loading:true})
        this.props.W17F2051Actions.getForm(params,(error, data) => {
            
            if (error || !data) {
                let message = (error && error.message) || Config.lang("CRM_Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                this.setState({loading: false});
                return false;
            }
            const  masterInputData =_.get(data,"0",{})
            this.state.anaMaster.map(ana  => {
                if (ana.AnaCategoryID && ana.AnaList) {
                    masterInputData[`${ana.AnaCategoryID}Name`] = _.get(_.find(ana.AnaList,{AnaID:masterInputData[`${ana.AnaCategoryID}ID`]}),"AnaName","")
                }
                return false
            })
            this.setState({masterInputData,loading: false})
        })
    }
    loadNewKey = (data) =>{
                                                                                                                                                                                                                                
        const S1 = data || _.get(this.state,"masterInputData.S1","")
        if (!S1) {
            return 
        }
        const params = {
            Language: Config.language || '84',
            CodeID: "",
            S1
        }
        this.setState({loadingNewKey: true});
        this.props.W17F2051Actions.getNewKey(params,(error,data) => {
            
            if (error) {
                let message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                this.setState({loadingNewKey: false});
                return false;
            }
            if (!data) {
                let message =Config.lang("CRM_Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                this.setState({loadingNewKey: false});
                return false;
            }
            this.setState({loadingNewKey: false,masterInputData:{...this.state.masterInputData,ObjectID: data.NewKey}});
       })

    }
    // event
    onChangeMasterInputData =(e) =>{
        const {name,value} = e.target
        const newMasterInputData = {...this.state.masterInputData}
        if (["IDCardNo","BankAccountNo","TelNo"].includes(name)) {
            if (/^\d+$/.test(value)) {
                newMasterInputData[name] = value
                this.setState({masterInputData:newMasterInputData})
            }
            return 
        }
        switch (name) {
           
            default:
                newMasterInputData[name] = value
                this.setState({masterInputData:newMasterInputData})
        }
        
    } 
    onChangeMasterInputDataObject =(obj ={} ) =>{
        const newMasterInputData = {...this.state.masterInputData,...obj}
        this.setState({masterInputData:newMasterInputData})
        
    } 
    onClickEdit = () =>{
        let params = {
            Language: Config.language || '84',
            CodeID: _.get(this.state,"masterInputData.ObjectID")
        }
        this.setState({loading:true})
        return this.props.W17F2051Actions.checkEdit(params,async (error,data) => {
            if (error || !data) {
                let message =(error && error.message )|| Config.lang("CRM_Loi_chua_xac_dinh");
                this.setState({ loading: false});
                return Config.popup.show('INFO', message);
            }
            if (data.Status === 0) {
                this.setState({ loading: false});
                this.onChangeLocationState({
                    mode: "edit",
                })
            }else{
                return Config.popup.show('INFO', data.Message)
            }
            
        })

    }
    onClickAddMore = (e) =>{
        this.setState({masterInputData:{},allowAddMore:false})
        this.onChangeLocationState({mode:"add",ObjectID:null})
    }
    onClickSave = (e) =>{
        const {mode} = this.getInfo()
        let params = this.getSaveParams()
        params.Mode = mode === "add" ? "Save" : "SaveEdit"
        let error = this.checkSaveParams(params)
        if (error) {
            return Config.popup.show('INFO', error);
        }
        this.onSave(params,mode,()=>{
            this.onChangeLocationState({
                ObjectID: params.ObjectID,
                mode: "view",
            })
            this.loadFormMaster()
            
    })
    }
    onChangeLocationState =(state ={}) =>{
        const newLocation = this.props.location || {}
        newLocation.state = newLocation.state ?{...newLocation.state,...state} :state
        browserHistory.replace(newLocation)
    }
    onClickGoBack = (e) =>{
        const {formCall,formCallState} = this.getInfo()
        if (formCall) {
            browserHistory.push({pathname: Config.env.url + `/${formCall}`, state:formCallState});
        }else{
            browserHistory.goBack();
        }
    }
    onCollapse =(index) =>{
        let newCollapse ={...this.state.collapse}
        newCollapse[index] = !newCollapse[index]
       this.setState({collapse:newCollapse})
    }
    onSave =(params,mode,cb) => {
        this.setState({loading:true})
        
        return this.props.W17F2051Actions.save(params,async (error,data) => {
                if (error || !data) {
                    let message =(error && error.message )|| Config.lang("CRM_Loi_chua_xac_dinh");
                    this.setState({ loading: false});
                    return Config.popup.show('INFO', message);
                }
                if (data.Status === 0) {
                    notify(Config.lang("CRM_Luu_thanh_cong"),"success",2000)
                    this.setState({ loading: false,allowAddMore: mode === "add"});
                    
                    cb && cb()
                }else{
                    this.setState({ loading: false});
                    return Config.popup.show('INFO', data.Message)
                }
                
            })
        
    }
    //handle 
    checkSaveParams =(params) =>{
        if (!params.S1) {
            return `${Config.lang("CRM_Ban_chua_nhap_gia_tri")}: ${Config.lang("CRM_Su_dung_phan_loai")}!`
        }
        if (!params.ObjectNameU) {
            return `${Config.lang("CRM_Ban_chua_nhap_gia_tri")}: ${Config.lang("CRM_Ten")}!`
        }
        if (!params.IDCardNo) {
            return `${Config.lang("CRM_Ban_chua_nhap_gia_tri")}: ${Config.lang("CRM_So_CCCD_/_CMND")}!`
        }
        if (!params.IDCardIssueDate) {
            return `${Config.lang("CRM_Ban_chua_nhap_gia_tri")}: ${Config.lang("CRM_Ngay_cap_CMND")}!`
        }
        if (!params.IDCardIssuePlaceU) {
            return `${Config.lang("CRM_Ban_chua_nhap_gia_tri")}: ${Config.lang("CRM_Noi_cap")}!`
        }
        if (!params.TelNo) {
            return `${Config.lang("CRM_Ban_chua_nhap_gia_tri")}: ${Config.lang("CRM_So_dien_thoai")}!`
        }else{
            let rgx = /^\d{10,}$/
            if ( !rgx.test(params.TelNo)) {
                    return `${Config.lang("CRM_So_dien_thoai_khong_hop_le")}!`
            }
           
        }
        if (!params.AddressLine1U) {
            return `${Config.lang("CRM_Ban_chua_nhap_gia_tri")}: ${Config.lang("CRM_Dia_chi_thuong_tru")}!`
        }
        if (!params.ObjectAddressU) {
            return `${Config.lang("CRM_Ban_chua_nhap_gia_tri")}: ${Config.lang("CRM_Dia_chi_lien_he")}!`
        }
        return false
    }
    getSaveParams = () =>{
        const {masterInputData,anaMaster,AdditionalMaster} = this.state
        let anaData ={}
        let additionalData ={}
        anaMaster.map(ana =>{
            if (ana.AnaCategoryID) {
                anaData[`${ana.AnaCategoryID}ID`] = masterInputData[`${ana.AnaCategoryID}ID`] || null
            }
            return false
        })
        AdditionalMaster.map(item =>{
            if (item.DataID) {
                additionalData[`${item.DataID}`] = masterInputData[`${item.DataID}`] || null
            }
            return false
        })
        return {
            Language: Config.language || '84',
            CodeID: masterInputData.ObjectID || "",
            S1: masterInputData.S1 || "",
            ObjectTypeID: masterInputData.ObjectTypeID || "",
            ObjectID: masterInputData.ObjectID || "",
            RefInformationU: masterInputData.RefInformationU || "",
            ObjectNameU: masterInputData.ObjectNameU || "",
            BirthDate: masterInputData.BirthDate || null,
            Gender: masterInputData.Gender || "",
            IDCardNo: masterInputData.IDCardNo || "",
            IDCardIssueDate: masterInputData.IDCardIssueDate || "",
            IDCardIssuePlaceU: masterInputData.IDCardIssuePlaceU || "",
            TelNo: masterInputData.TelNo || "",
            Email: masterInputData.Email || "",
            AddressLine1U: masterInputData.AddressLine1U || "",
            ObjectAddressU: masterInputData.ObjectAddressU || "",
            BankAccountNo: masterInputData.BankAccountNo || "",
            BankBranchNameU	: masterInputData.BankBranchNameU	 || "",
            ...anaData,
            ...additionalData,
        }
    }
    
    render() {
        const {mode} = this.getInfo()
        const ViewMode = mode === "view"
        const AddMode = mode === "add"
        const EditMode = mode === "edit"
        const {cboCategoryType,cboGender} = this.props
        const {iPermission,allowAddMore,masterInputData,loadingNewKey,collapse,anaMaster,AdditionalMaster,loading} = this.state
        return ( 
            <PageContainer>
                {Config && Config.profile &&
                <EventTracking category={"TRACKING_USER"} action={"W17F1025"} label={Config.profile.UserID}/>}
                {
                    loading && <Loading/>
                }
                <div className="grid-title" style={{ marginLeft: '10px' }}>{Config.lang('CRM_Cong_tac_vien')}</div>
                <Form horizontal={true} id={'frmW17F1026'} style={{margin: '0 15px'}}>
                    <Row className="form-group">
                        <Col md={12} sm={12} xl={12} xs={12} lg={12}>
                            <ButtonCustom color={'text-orange'} icon={'fas fa-arrow-alt-left'} isAwesome={true}
                                          name={Config.lang("CRM_Quay_lai")}
                                          onClick={() => {
                                            this.onClickGoBack()
                                          }}/>
                            <ButtonEdit
                                className={`mgl5 ${iPermission >=3 ? "" : "isDisabled"} ${ViewMode? "" : "hide"}`}
                                name={Config.lang("CRM_Sua")} onClick={() => {
                                    if (iPermission >=3) {
                                        this.onClickEdit()
                                    }
                                }}
                            />
                            <ButtonSave className={`mgl5 ${iPermission >=2 ? "" : "isDisabled"} ${(AddMode || EditMode)? "" : "hide"}`}
                                name={Config.lang("CRM_Luu")} onClick={() => {
                                    if (iPermission >=2) {
                                        this.onClickSave()
                                    }
                                }}
                            />
                            <ButtonAdd className={`mgl5 ${iPermission >=2 ? "" : "isDisabled"} ${(allowAddMore)? "" : "hide"}`}
                                name={Config.lang("CRM_Nhap_tiep")} onClick={() => {
                                    if (iPermission >=2) {
                                       this.onClickAddMore()
                                    }
                                }}
                            />

                        </Col>

                    </Row>
                </Form>
                <Form horizontal={true} id={'frmW17F1026'} style={{margin: '0 15px'}}>
                   
                    <FormGroup>
                        <Col md={2} sm={2} xs={6} lg={2}><label
                                        className="control-label">{Config.lang("CRM_Su_dung_phan_loai")}
                                        <span className={'text-red'}> (*)</span>
                                        </label>
                        </Col>
                        <Col md={4} sm={4} xs={6} lg={4} className={'mgb15'}>
                            <Combo
                                showClearButton={true}
                                showHeader={false}
                                dataSource={cboCategoryType}
                                placeholder={''}
                                value={masterInputData.S1}
                                valueExpr="CategoryID"
                                displayExpr="Description"
                                onActionWhenSelectChange={data =>{
                                    
                                    this.onChangeMasterInputDataObject({
                                        S1: data ? data.CategoryID : "",
                                    })
                                    AddMode && this.loadNewKey(data ? data.CategoryID : "")
                                }}
                                disabled ={ViewMode || EditMode}
                                
                                
                            >
                                <Column dataField={'CategoryID'} caption="" width={'150px'}
                                            dataType={'string'}/>
                                <Column dataField={'Description'} caption="" width={'150px'}
                                            dataType={'string'}/>
                            </Combo>
                    
                        </Col>
                    </FormGroup>
                    <FormGroup>
                        <Col md={2} sm={2} xs={6} lg={2}><label
                            className={"control-label"}>{Config.lang("CRM_Ma")}
                            <span className={'text-red'}> (*)</span> 
                                {
                                   AddMode && <i  style ={{cursor:"pointer"}} onClick={() => this.loadNewKey()} 
                                    className={`fas fa-sync mgl20 text-blue ${loadingNewKey? "fa-spin" : ""}`}/>
                                }
                                </label></Col>
                        <Col  md={4} sm={4} xs={6} lg={4}>
                            <input className={'form-control'}
                                name="CompanyID"
                                onChange={this.onChangeMasterInputData}
                                value={masterInputData.ObjectID || ""}
                                disabled
                            />
                        </Col>

                        <Col md={2} sm={2} xs={6} lg={2}><label
                            className={"control-label"}>{Config.lang("CRM_Ten_tat")}</label></Col>
                        <Col  md={4} sm={4} xs={6} lg={4}>
                            <input className={'form-control'}
                                name="RefInformationU"
                                onChange={this.onChangeMasterInputData}
                                value={masterInputData.RefInformationU || ""}
                                disabled = {ViewMode}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup>
                        <Col md={2} sm={2} xs={6} lg={2}><label
                            className={"control-label"}>{Config.lang("CRM_Ten")}<span
                                className={'text-red'}> (*)</span></label></Col>
                        <Col md={10} sm={10} xs={6} lg={10}>
                            <input className={'form-control'}
                                name="ObjectNameU"
                                onChange={this.onChangeMasterInputData}
                                value={masterInputData.ObjectNameU || ""}
                                disabled = {ViewMode}
                            />
                        </Col>
                    </FormGroup>
                    {/* group 1 */}
                    <div>
                    <FormGroup>
                        <Col md={12} sm={12} xl={12} xs={12} lg={12}>
                            <span onClick={() => this.onCollapse(1)}><i
                                className={collapse[1] ? 'fas fa-caret-down fa-lg ' : 'fas fa-caret-right fa-lg '} /> <label
                                style = {{cursor:"pointer"}}    className={'text-blue'}>&nbsp;{Config.lang("CRM_Thong_tin_ca_nhan")}</label></span>
                        </Col>
                    </FormGroup>
                    <Collapse in={collapse[1]}>
                        <div>
                        <FormGroup>
                        <Col md={2} sm={2} xs={6} lg={2}><label
                                        className="control-label">{Config.lang("CRM_Ngay_sinh")}</label>
                        </Col>
                        <Col md={4} sm={4} xs={6} lg={4} className={'mgb15'}>
                                <DateBox
                                    name = "BirthDate"
                                    width={"100%"}
                                    dateSerializationFormat={'yyyy-MM-dd HH:mm:ss'}
                                    placeholder={Config.lang("CRM_Ngay_sinh")}
                                    openOnFieldClick={true}
                                    useMaskBehavior={true}
                                    showClearButton={true}
                                    type={'date'}
                                    pickerType={"calendar"}
                                    showAnalogClock={false}
                                    displayFormat={'dd/MM/y'}
                                    value={masterInputData.BirthDate}
                                    onValueChanged={e => this.onChangeMasterInputDataObject({BirthDate:e.value})}
                                    disabled = {ViewMode}
                                />
                        </Col>
                        <Col md={2} sm={2} xs={6} lg={2}><label
                                        className="control-label">{Config.lang("CRM_Gioi_tinh")}
                                        </label>
                        </Col>
                        <Col md={4} sm={4} xs={6} lg={4} className={'mgb15'}>
                            <Combo
                                showClearButton={true}
                                showHeader={false}
                                dataSource={cboGender}
                                placeholder={''}
                                value={masterInputData.Gender}
                                valueExpr="GenderID"
                                displayExpr="GenderName"
                                onActionWhenSelectChange={data =>{
                                    this.onChangeMasterInputDataObject({
                                        Gender: data ? data.GenderID : "",
                                    })
                                }}
                                disabled ={ViewMode}
                                
                                
                            >
                                <Column dataField={'GenderName'} caption="" width={'150px'}
                                            dataType={'string'}/>
                            </Combo>
                    
                        </Col>
                        </FormGroup>
                        <FormGroup>
                        <Col md={2} sm={2} xs={6} lg={2}><label
                         className={"control-label"}>{Config.lang('CRM_So_CCCD_/_CMND')}<span className={'text-red'}> (*)</span></label></Col>
                        <Col  md={4} sm={4} xs={6} lg={4}>
                            <input className={'form-control'}
                                name="IDCardNo"
                                onChange={this.onChangeMasterInputData}
                                value={masterInputData.IDCardNo || ""}
                                disabled = {ViewMode}
                            /></Col>
                                <Col md={2} sm={2} xs={6} lg={2}><label
                        className={"control-label"}>{Config.lang("CRM_Ngay_cap_CMND")}<span className={'text-red'}> (*)</span></label></Col>
                    <Col  md={4} sm={4} xs={6} lg={4}>
                    <DateBox
                                name = "IDCardIssueDate"
                                width={"100%"}
                                dateSerializationFormat={'yyyy-MM-dd HH:mm:ss'}
                                placeholder={Config.lang("CRM_Ngay_cap_CMND")}
                                openOnFieldClick={true}
                                useMaskBehavior={true}
                                showClearButton={true}
                            
                                type={'date'}
                                pickerType={"calendar"}
                                showAnalogClock={false}
                                displayFormat={'dd/MM/y'}
                                value={masterInputData.IDCardIssueDate}
                                onValueChanged={e => this.onChangeMasterInputDataObject({IDCardIssueDate:e.value || ""}) }
                                disabled = {ViewMode}
                            />
                    </Col>  

                        </FormGroup>
                        <FormGroup>
                        <Col md={2} sm={2} xs={6} lg={2}><label
                            className={"control-label"}>{Config.lang("CRM_Noi_cap")}<span className={'text-red'}> (*)</span></label></Col>
                        <Col md={10} sm={10} xs={6} lg={10}>
                            <input className={'form-control'}
                                name="IDCardIssuePlaceU"
                                onChange={this.onChangeMasterInputData}
                                value={masterInputData.IDCardIssuePlaceU || ""}
                                disabled = {ViewMode}
                            />
                        </Col>
                    </FormGroup>
                    
                   <FormGroup>
                    <Col md={2} sm={2} xs={6} lg={2}><label
                        className={"control-label"}>{Config.lang("CRM_So_dien_thoai")}<span className={'text-red'}> (*)</span></label></Col>
                    <Col  md={4} sm={4} xs={6} lg={4}>
                        <input className={'form-control'}
                            name="TelNo"
                            onChange={this.onChangeMasterInputData}
                            value={masterInputData.TelNo || ""}
                            disabled = {ViewMode}
                        />
                    </Col>

                    <Col md={2} sm={2} xs={6} lg={2}><label
                        className={"control-label"}>{Config.lang("CRM_Email")} </label></Col>
                    <Col  md={4} sm={4} xs={6} lg={4}>
                        <input className={'form-control'}
                            name="Email"
                            onChange={this.onChangeMasterInputData}
                            value={masterInputData.Email || ""}
                            disabled = {ViewMode}
                        />
                    </Col>
                    </FormGroup>
                    <FormGroup>
                        <Col md={2} sm={2} xs={6} lg={2}><label
                            className={"control-label"}>{Config.lang("CRM_Dia_chi_thuong_tru")}<span className={'text-red'}> (*)</span></label></Col>
                        <Col md={10} sm={10} xs={6} lg={10}>
                            <input className={'form-control'}
                                name="AddressLine1U"
                                onChange={this.onChangeMasterInputData}
                                value={masterInputData.AddressLine1U || ""}
                                disabled = {ViewMode}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup>
                        <Col md={2} sm={2} xs={6} lg={2}><label
                            className={"control-label"}>{Config.lang("CRM_Dia_chi_lien_he")}<span className={'text-red'}> (*)</span></label></Col>
                        <Col md={10} sm={10} xs={6} lg={10}>
                            <input className={'form-control'}
                                name="ObjectAddressU"
                                onChange={this.onChangeMasterInputData}
                                value={masterInputData.ObjectAddressU || ""}
                                disabled = {ViewMode}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup>
                    <Col md={2} sm={2} xs={6} lg={2}><label
                        className={"control-label"}>{Config.lang("CRM_So_tai_khoan")}</label></Col>
                    <Col  md={4} sm={4} xs={6} lg={4}>
                        <input className={'form-control'}
                            name="BankAccountNo"
                            onChange={this.onChangeMasterInputData}
                            value={masterInputData.BankAccountNo || ""}
                            disabled = {ViewMode}
                        />
                    </Col>

                    <Col md={2} sm={2} xs={6} lg={2}><label
                        className={"control-label"}>{Config.lang("CRM_Chi_nhanh_ngan_hang")} </label></Col>
                    <Col  md={4} sm={4} xs={6} lg={4}>
                        <input className={'form-control'}
                            name="BankBranchNameU"
                            onChange={this.onChangeMasterInputData}
                            value={masterInputData.BankBranchNameU || ""}
                            disabled = {ViewMode}
                        />
                    </Col>
                    </FormGroup>
                    
                        </div>
                    </Collapse>
                    </div>
                    {/* group 2 */}
                    <div>
                    <FormGroup>
                        <Col md={12} sm={12} xl={12} xs={12} lg={12}>
                            <span onClick={() => this.onCollapse(2)}><i
                                className={collapse[2] ? 'fas fa-caret-down fa-lg ' : 'fas fa-caret-right fa-lg '} /> <label
                                style = {{cursor:"pointer"}}    className={'text-blue'}>&nbsp;{Config.lang("CRM_Thong_tin_phu")}</label></span>
                        </Col>
                    </FormGroup>
                    <Collapse in={collapse[2]}>
                        <div>
                        
                            {
                                anaMaster.map(ana =>{
                                    if (ana.UseOO === 1) {
                                        return <FormGroup key ={ana.AnaCategoryID}>
                                                    <Col md={2} sm={2} xs={6} lg={2}><label
                                                        className={"control-label"}>{ana.AnaCategoryNameU}</label></Col>
                                                    <Col  md={4} sm={4} xs={6} lg={4}>
                                                        <Combo
                                                            showClearButton={true}
                                                            showHeader={false}
                                                            dataSource={ana.AnaList}
                                                            placeholder={''}
                                                            value={masterInputData[`${ana.AnaCategoryID}ID`]}
                                                            valueExpr="AnaID"
                                                            displayExpr="AnaID"
                                                            onActionWhenSelectChange={data =>{
                                                                this.onChangeMasterInputDataObject({
                                                                    [`${ana.AnaCategoryID}ID`]:data ? data.AnaID : "",
                                                                    [`${ana.AnaCategoryID}Name`]:data ? data.AnaName : ""
                                                                })
                                                            }}
                                                            disabled ={ViewMode}
                                                            
                                                            
                                                        >
                                                            <Column dataField={'AnaID'} caption="" width={'150px'}
                                                                    dataType={'string'}/>
                                                            <Column dataField={'AnaName'} caption="" width={'150px'}
                                                                    dataType={'string'}/>
                                                            </Combo>
                                                    </Col>

                                                    <Col md={2} sm={2} xs={6} lg={2}></Col>
                                                    <Col  md={4} sm={4} xs={6} lg={4}>
                                                        <input className={'form-control'}
                                                            value={masterInputData[ana.AnaCategoryID+"Name"] || ""}
                                                            disabled
                                                        />
                                                    </Col>
                                        </FormGroup>
                                    }
                                    return false
                                })
                            }
                    
                        </div>
                    </Collapse>
                    </div>
                    {/* group 3 */}
                    <div>
                    <FormGroup>
                        <Col md={12} sm={12} xl={12} xs={12} lg={12}>
                            <span onClick={() => this.onCollapse(3)}><i
                                className={collapse[3] ? 'fas fa-caret-down fa-lg ' : 'fas fa-caret-right fa-lg '} /> <label
                                style = {{cursor:"pointer"}}    className={'text-blue'}>&nbsp;{Config.lang("CRM_Thong_tin_bo_sung")}</label></span>
                        </Col>
                    </FormGroup>
                    <Collapse in={collapse[3]}>
                        <FormGroup>
                            {
                                AdditionalMaster && AdditionalMaster.map(item =>{
                                    if (item.DefaultUse === 1) {
                                        return (<div key ={item.DataID}>
                                            <Col md={2} sm={2} xs={6} lg={2}><label
                                                            className="control-label">{item.Description}</label>
                                            </Col>
                                            <Col md={4} sm={4} xs={6} lg={4} className={'mgb15'}>
                                                {
                                                    item.DataType === "Date"
                                                    ?<DateBox
                                                        name = {item.DataID}
                                                        width={"100%"}
                                                        dateSerializationFormat={'yyyy-MM-dd HH:mm:ss'}
                                                        placeholder={item.Description}
                                                        openOnFieldClick={true}
                                                        useMaskBehavior={true}
                                                        showClearButton={true}
                                                        type={'date'}
                                                        pickerType={"calendar"}
                                                        showAnalogClock={false}
                                                        displayFormat={'dd/MM/y'}
                                                        value={masterInputData[item.DataID]}
                                                        onValueChanged={e => this.onChangeMasterInputDataObject({[item.DataID]:e.value})}
                                                        disabled = {ViewMode}
                                                    />
                                                    :item.DataType === "Num"
                                                        ?<NumberFormat
                                                            className ='form-control'
                                                            decimalScale={item.DecimalNum}
                                                            thousandSeparator={true}
                                                            isNumericString={true}
                                                            value={masterInputData[item.DataID] || ""}
                                                            onValueChange={e => this.onChangeMasterInputDataObject({[item.DataID]:e.value})}
                                                            suffix={item.FormatData === "PERCENT" ? "%" : ""}
                                                            disabled={ViewMode}
                                                        />
                                                        :<input className={'form-control'}
                                                                                name={item.DataID}
                                                                                onChange={this.onChangeMasterInputData}
                                                                                value={masterInputData[item.DataID] || ""}
                                                                                disabled = {ViewMode}
                                                        />
                                                    }
                                                    
                                            </Col>
                                    </div>)
                                        
                                    }
                                    return  false
                                    
                                })
                            }
                        </FormGroup>
                    </Collapse>
                    </div>
                   
                    </Form>
            </PageContainer>
        )
    }
}

export default connect(state => ({
    anaMaster: state.w17f2051.anaMaster,
    cboGender: state.w17f2051.cboGender,
    cboCategoryType:state.w17f2051.cboCategoryType,
    anaMasterCation:state.w17f2051.anaMasterCation,
    formMaster:state.w17f2051.formMaster,
}),
(dispatch) => ({
    W17F2051Actions: bindActionCreators(W17F2051Actions, dispatch),
    userActions: bindActionCreators(userActions, dispatch)
})
)(W17F2051Page);

/**
 * @copyright 2019 @ DigiNet
 * @author VINHLE
 * @create 7/10/2019
 */

import React from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import Config from '../../config';
import {Form, FormGroup, Col} from 'react-bootstrap';
import PageContainer from "../common/page-container/page-container";
import GridContainer from "../common/grid-container/grid-container";
import {Column} from "devextreme-react/data-grid";
import {CheckBox} from "devextreme-react";
import W17F1521Pop from "./W17F1521Pop";
import * as action from '../../actions/w17/w17F1520/w17f1520-actions';
import GridActionBar from "../common/action-bar/grid-action-bar";
import * as userActions from "../../actions/user-actions";
import notify from "devextreme/ui/notify";

class W17F1520 extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            showPop: false,
            disabled: false,
            dataGrid: [],
            loading: false,
            iPermission: 0
        };
        this.rowData = null;
        this.mode = 'add';
        this.filter = {
            skip: 0,
            limit: 10,
            search: '',
            total: 0,
        }
    };

    async loadPermission() {
        await this.props.userActions.getPermission(Config.profile.UserID, "D17F1520", (iPer) => {
            this.setState({iPermission: iPer});
        });
    }

    componentDidMount = async () => {
        this.loadDataGrid();
        await this.loadPermission();
    };

    loadDataGrid() {
        const {disabled} = this.state;
        const param = Object.assign({}, this.filter);
        param.Disabled = disabled ? 1 : 0;

        this.props.action.getGrid(param, (error, data) => {
            if (error) return false;
            if (data) {
                this.filter.total = data.total;
                this.setState({
                    dataGrid: data.rows
                })
            }
        })

    }


    togglePop(mode) {
        const {showPop} = this.state;

        if (mode) this.mode = mode;
        this.setState({
            showPop: !showPop
        }, () => {
            const {showPop} = this.state;

            if (!showPop) this.loadDataGrid();
        })
    }

    search = (e) => {
        this.filter.search = e;
        this.loadDataGrid();
    };

    toggleDisabled() {
        const {disabled} = this.state;

        this.setState({
            disabled: !disabled
        }, () => {
            this.loadDataGrid()
        })

    }

    editRow = (data) => {
        if (data) {
            const {row} = data;
            this.rowData = row.data;
            this.togglePop('edit')
        }
    };

    onDelete = data => {
        if (data) {
            const {row} = data;
            const param = {
                CaseTypeID: row.data.CaseTypeID
            };

            Config.popup.show("YES_NO", Config.lang('CRM_Ban_co_chac_chan_muon_xoa_du_lieu_nay'), () => {
                this.props.action.deleteRow(param, (error) => {
                    if (error) {
                        let message;
                        message = error.code === 'F1521E005' ? Config.lang('CRM_Du_lieu_da_duoc_su_dung') : Config.lang('CRM_Co_loi_khi_xoa');
                        Config.popup.show('INFO', message);
                        return false;
                    }
                    this.loadDataGrid();
                    notify(Config.lang("CRM_Du_lieu_da_xoa_thanh_cong"), "success", 2000);
                })
            })
        }
    };

    renderAction = (data) => {
        const {iPermission} = this.state;

        return (
            <GridActionBar
                isPer={iPermission}
                tooltipDelete={Config.lang("CRM_Xoa")}
                onDeleteAction={() => this.onDelete(data)}
                tooltipEdit={Config.lang('CRM_Sua')}
                onEditAction={() => this.editRow(data)
                }
            />
        );
    };
    onChangePage = (page) => {
        this.filter.skip = page * this.filter.limit;
        this.loadDataGrid();
    };

    onChangePerPage = (per) => {
        this.filter.skip = 0;
        this.filter.limit = per;
        this.loadDataGrid();
    };

    render() {
        const {showPop, dataGrid} = this.state;
        const {limit, total} = this.filter;

        return (
            <PageContainer
                title={Config.lang('CRM_Danh_muc_loai_tinh_huong')}
                tracking={'W18F1060'}
            >
                {showPop && <W17F1521Pop data={this.rowData} mode={this.mode} onHide={() => {
                    this.filter.skip = 0;
                    this.togglePop()
                }}/>}
                <Form horizontal={true}>
                    <FormGroup>
                        <Col lg={12}>
                            <GridContainer
                                itemPerPage={limit}
                                totalItems={total}
                                onSearch={this.search}
                                dataSource={dataGrid}
                                onChangePage={(page) => {
                                    this.onChangePage(page)
                                }}
                                onChangePerPage={(per) => {
                                    this.onChangePerPage(per)
                                }}
                                onAddNew={() => {
                                    this.rowData = null;
                                    this.togglePop('add')
                                }}
                                buttonCRM={[
                                    {
                                        buttonType: "check",
                                        buttonName: Config.lang('CRM_Hien_thi_khong_su_dung'),
                                        className: 'pdr10',
                                        align: 'right',
                                        onValueChanged: () => this.toggleDisabled()
                                    }
                                ]}
                            >
                                <Column
                                    cellRender={(data) => this.renderAction(data)}
                                    caption={Config.lang("CRM_Hanh_dong")}
                                    alignment={'center'}
                                    allowSorting={false}
                                    showInColumnChooser={false}
                                    width={120}
                                    fixed={true}
                                />
                                <Column
                                    dataField={'CaseTypeID'}
                                    width={150}
                                    caption={Config.lang("CRM_Ma_loai_tinh_huong")}
                                />
                                <Column
                                    dataField={'CaseTypeName'}
                                    width={200}
                                    caption={Config.lang("CRM_Ten_loai_tinh_huong")}/>
                                <Column
                                    dataField={'Remark'}
                                    caption={Config.lang("CRM_Mo_ta")}/>
                                <Column
                                    dataField={'Disabled'}
                                    width={120}
                                    allowEditing={false}
                                    alignment={'center'}
                                    cellRender={(e) => {
                                        return (<CheckBox
                                            value={e.value === 1}
                                            className={'control-label text-bold'}
                                            id={"Disabled"}
                                            disabled={true}
                                        />)
                                    }}
                                    caption={Config.lang("CRM_Khong_su_dung")}/>
                            </GridContainer>
                        </Col>
                    </FormGroup>
                </Form>
            </PageContainer>
        )
    }
}

export default connect(null,
    (dispatch) => ({
        action: bindActionCreators(action, dispatch),
        userActions: bindActionCreators(userActions, dispatch)

    })
)(W17F1520);

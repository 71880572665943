/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 6/23/2021
 * @Example
 */

import React                from 'react';
import {connect}            from "react-redux";
import {bindActionCreators} from "redux";
import * as w17f5560Actions from "../../../actions/w17/W17F5560/w17F5560-actions";
import * as userActions     from "../../../actions/user-actions";
import Config               from '../../../config';
import {Column}             from 'devextreme-react/data-grid';
import {
    Row,
    Form,
    FormGroup,
    Col,
}                           from 'react-bootstrap';
import EventTracking        from "../../common/tracking/event";
import {
    Label
}                           from "reactstrap";

import moment           from "moment";
import 'moment/locale/vi'
import GridContainer    from "../../common/grid-container/grid-container";
import PopupScroll      from "../../popup/popup-scroll";
import SendMail         from "../W17F1002";
import {browserHistory} from "react-router";
import Select           from "react-select";
import {CheckBox}             from 'devextreme-react';

moment.locale('vi');

class W17F5560Page extends React.PureComponent {
    constructor (props) {
        super(props);
        this.state   = {
            search:   "",
            loading:  {},
            filter:   {
                InventoryTypeID: [],
                InventoryID:     [],
                TypeCodeID:      [],
                ICodeID:         [],
                WareHouseID:         [],
                IsHideQuantity: 0,
            },
            data:     [],
            dataMail: null,

            showSendMail: false,
        };
        this.filter  = {
            skip:   0,
            limit:  15,
            search: ''
        };
        this.timer   = null;
        this.loading = {};
        this.defaultParams = {
            Language: Config.language || "84",
            FormID: "W17F5560"
        };
    };

    setLoading            = (obj) => {
        this.loading = {...this.loading, ...obj};
        this.setState({loading: this.loading});
    };
    setFilter             = (obj, cb) => {
        this.setState({filter: {...this.state.filter, ...obj}}, () => {
            if (cb) cb();
        });
    };
    setDefaultFilterValue = (key, value, cb) => {
        this.setFilter({[key]: value}, () => {
            if (cb) cb();
        });
    };

    loadPermission       = async () => {
        await this.props.userActions.getPermission(Config.profile.UserID, "W17F5560", (iPer) => {
            this.setState({iPermission: iPer});
        });
    };
    loadCboInventoryType = () => {
        this.setLoading({cboInventoryType: true});
        this.props.w17f5560Actions.loadCboInventoryType(this.defaultParams, (error, data) => {
            this.setLoading({cboInventoryType: false});
            if (error) {
                let message = error.message || Config.lang("Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            }
            if (data && data.length > 0)
                this.setDefaultFilterValue("InventoryTypeID", [data[0].InventoryTypeID], () => {
                    this.loadCboInventory();
                });
        });
    };
    loadCboInventory     = () => {
        const {InventoryTypeID, WareHouseID} = this.state?.filter || {};
        this.setLoading({cboInventory: true});
        const params = {
            ...this.defaultParams,
            InventoryTypeID: JSON.stringify(InventoryTypeID),
            WareHouseID: JSON.stringify(WareHouseID)
        };
        this.props.w17f5560Actions.loadCboInventory(params, (error, data) => {
            this.setLoading({cboInventory: false});
            if (error) {
                let message = error.message || Config.lang("Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            }
            if (data && data.length > 0)
                this.setDefaultFilterValue("InventoryID", [data[0].InventoryID]);
        });
    };
    loadCboTypeCode      = () => {
        const {InventoryID} = this.state?.filter || {};
        const params = {
            ...this.defaultParams,
            InventoryID: JSON.stringify(InventoryID)
        };
        this.setLoading({cboTypeCode: true});
        this.props.w17f5560Actions.loadCboTypeCode(params, (error, data) => {
            this.setLoading({cboTypeCode: false});
            if (error) {
                let message = error.message || Config.lang("Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            }
            if (data && data[0]?.TypeCodeID)
                this.setDefaultFilterValue("TypeCodeID", [data[0]?.TypeCodeID], () => {
                    this.loadCboCode();
                });
        });
    };
    loadCboCode          = () => {
        const {TypeCodeID} = this.state?.filter || {};
        const params = {
            ...this.defaultParams,
            TypeCodeID: JSON.stringify(TypeCodeID)
        };
        this.setLoading({cboCode: true});
        this.props.w17f5560Actions.loadCboCode(params, (error, data) => {
            this.setLoading({cboCode: false});
            if (error) {
                let message = error.message || Config.lang("Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            }
            if (data && data[0]?.ICodeID)
                this.setDefaultFilterValue("ICodeID", [data[0]?.ICodeID]);
        });
    };
    loadCboWarehouse          = () => {
        this.setLoading({cboWarehouse: true});
        this.props.w17f5560Actions.loadCboWareHouse({...this.defaultParams}, (error, data) => {
            this.setLoading({cboWarehouse: false});
            if (error) {
                let message = error.message || Config.lang("Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            }
            if (data && data[0]?.WareHouseID)
                this.setDefaultFilterValue("WareHouseID", [data[0]?.WareHouseID]); //[{value: data[0]?.WareHouseID, label: data[0]?.WareHouseName}]
        });
    };
    loadColumns          = () => {
        const {filter}                                            = this.state || {};
        const {InventoryTypeID, InventoryID, TypeCodeID, ICodeID} = filter || {};
        const params                                              = {
            ...this.defaultParams,
            InventoryTypeID,
            InventoryID,
            TypeCodeID,
            ICodeID,
        };
        this.setLoading({columns: true});
        this.props.w17f5560Actions.loadColumns(params, (error) => {
            this.setLoading({columns: false});
            if (error) {
                let message = error.message || Config.lang("Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            }
        });
    };
    getQuantityDecimal          = () => {
        this.setLoading({quantityDecimal: true});
        this.props.w17f5560Actions.getQuantityDecimal({...this.defaultParams}, (error, data) => {
            this.setLoading({quantityDecimal: false});
            if (error) {
                let message = error.message || Config.lang("Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            }
        });
    };
    loadGrid             = () => {
        const {search, filter}                                    = this.state || {};
        const {InventoryTypeID, InventoryID, TypeCodeID, ICodeID, WareHouseID, IsHideQuantity} = filter || {};
        const {skip, limit}                                       = this.filter || {};
        const params                                              = {
            ...this.defaultParams,
            InventoryTypeID: JSON.stringify(InventoryTypeID),
            InventoryID: JSON.stringify(InventoryID),
            TypeCodeID: JSON.stringify(TypeCodeID),
            ICodeID: JSON.stringify(ICodeID),
            WareHouseID: JSON.stringify(WareHouseID),
            skip,
            limit,
            StrSearch: search,
            IsHideQuantity: IsHideQuantity ? 1 : 0
        };
        this.setLoading({main: true});
        this.props.w17f5560Actions.loadForm(params, (error) => {
            this.setLoading({main: false});
            if (error) {
                let message = error.message || Config.lang("Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            }
        });
    };

    componentDidMount = async () => {
        await this.loadPermission();
        if (this.state.iPermission <= 0) return false;
        this.loadCboInventoryType();
        this.loadCboTypeCode();
        this.loadCboWarehouse();
        this.loadColumns();
        this.getQuantityDecimal();
    };

    handleChange = (key, e) => {
        if (!key || !e) return false;
        const value = e[key] ? e[key] : e;
        this.setFilter({[key]: value.map(i => i.value)}, () => {
            switch (key) {
                case "InventoryTypeID":
                    this.loadCboInventory();
                    break;
                case "TypeCodeID":
                    this.loadCboCode();
                    break;
                case "WareHouseID":
                    this.loadCboInventory();
                    break;
                default:
                    break;
            }
        });
    };

    onSearch = (text) => {
        this.filter.skip = 0;
        this.setState({
            search: text
        }, () => {
            this.loadGrid();
        });
    };

    onSendMail = (flag) => {
        const {data, showSendMail} = this.state;
        flag                       = typeof flag !== "undefined" ? flag : !showSendMail;
        if (flag) {
            if (data && data.length > 0) {
                this.setState({
                    showSendMail: flag,
                    dataMail:     {
                        FormID:     this.defaultParams.FormID || "W17F5560",
                        KeyID:      JSON.stringify(data.map(d => d.InventoryID)),
                        DivisionID: ""
                    }
                });
            }
        } else {
            this.setState({showSendMail: flag});
        }
    };

    onChangePage = (page) => {
        this.filter.skip = page * this.filter.limit;
        this.loadGrid();
    };

    onChangePerPage = (per) => {
        this.filter.skip  = 0;
        this.filter.limit = per;
        this.loadGrid();
    };

    onSelectionChanged = (data) => {
        this.setState({data});
    };

    redirect = (data) => {
        browserHistory.push({
            pathname: Config.getRootPath() + "W17F5565",
            state: data
        });
    };

    renderItem = (e) => {
        if (!e) return null;
        const value = e?.value || "";
        /* eslint-disable */
        return (
            <div><a onClick={() => this.redirect(e?.data)}>{value}</a></div>
        );
        /* eslint-disable */
    };

    onChangeIsHideQuantity = (e) => {
        if(!e) return;
        const { filter } = this.state;
        this.setState({ filter: {...filter, IsHideQuantity: e?.value === true ? 1 : 0} });
    };

    render () {
        const {dataCboInventoryType, dataCboInventory, dataCboTypeCode, dataCboCode, dataGrid, dataColumns,
                  dataCboWarehouse, dataQuantityDecimal
        } = this.props;

        const {loading, search, filter, data} = this.state;
        const disabled                        = Boolean(Object.keys(loading).find(l => loading[l]));
        const btnCRM                          = [
            {
                buttonName: Config.lang("Loc"),
                buttonType: 'button',
                icon:       'fas fa-filter text-yellow',
                align:      'right',
                disabled:   disabled,
                onClick:    () => {
                    this.loadGrid();
                }
            }];

        return (
            <div className="page-container" id={'frmW17F5560'}>
                <EventTracking action={"FORM"} label={"W17F5560"}/>

                <div className="grid-title">{Config.lang('Danh_sach_mat_hang')}</div>
                <Form horizontal={true} style={{overflowX: 'hidden'}}>
                    <FormGroup>
                        <Col sm={12} md={6} lg={4}>
                            <Row className={'mgt5'}>
                                <Col sm={5} md={5} lg={4}>
                                    <Label className={'mgt5'}>{Config.lang('Loai_hang')}</Label>
                                </Col>
                                <Col sm={7} md={7} lg={7}>
                                    <Select
                                        value={dataCboInventoryType.filter(item => filter?.InventoryTypeID.includes(item.value))}
                                        options={dataCboInventoryType}
                                        placeholder={""}
                                        displayExpr={'label'}
                                        valueExpr={'value'}
                                        isDisabled={disabled}
                                        getOptionLabel={option => `${option?.value} - ${option?.label}`}
                                        onChange={(e) => this.handleChange("InventoryTypeID", e)}
                                        isMulti={true}
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col sm={12} md={6} lg={4}>
                            <Row className={'mgt5'}>
                                <Col sm={5} md={5} lg={4}>
                                    <Label className={'mgt5'}>{Config.lang('Kho')}</Label>
                                </Col>
                                <Col sm={7} md={7} lg={7}>
                                    <Select
                                        value={dataCboWarehouse.filter(item => filter?.WareHouseID.includes(item.value))}
                                        options={dataCboWarehouse}
                                        placeholder={""}
                                        displayExpr={'label'}
                                        valueExpr={'value'}
                                        isDisabled={disabled}
                                        getOptionLabel={option => `${option?.value} - ${option?.label}`}
                                        onChange={(e) => this.handleChange("WareHouseID", e)}
                                        isMulti={true}
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col sm={12} md={6} lg={4}>
                            <Row className={'mgt5'}>
                                <Col sm={5} md={5} lg={4}>
                                    <Label className={'mgt5'}>{Config.lang('Mat_hang')}</Label>
                                </Col>
                                <Col sm={7} md={7} lg={7}>
                                    <Select
                                        value={dataCboInventory.filter(item => filter?.InventoryID.includes(item.value))}
                                        options={dataCboInventory}
                                        placeholder={""}
                                        displayExpr={'label'}
                                        valueExpr={'value'}
                                        isDisabled={disabled}
                                        getOptionLabel={option => `${option?.value} - ${option?.label}`}
                                        onChange={(e) => this.handleChange("InventoryID", e)}
                                        isMulti={true}
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col sm={12} md={6} lg={4}>
                            <Row className={'mgt5'}>
                                <Col sm={5} md={5} lg={4}>
                                    <Label className={'mgt5'}>{Config.lang('Nhom_phan_loai')}</Label>
                                </Col>
                                <Col sm={7} md={7} lg={7}>
                                    <Select
                                        value={dataCboTypeCode.filter(item => filter?.TypeCodeID.includes(item.value))}
                                        options={dataCboTypeCode}
                                        placeholder={""}
                                        displayExpr={'label'}
                                        valueExpr={'value'}
                                        isDisabled={disabled}
                                        getOptionLabel={option => `${option?.value} - ${option?.label}`}
                                        onChange={(e) => this.handleChange("TypeCodeID", e)}
                                        isMulti={true}
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col sm={12} md={6} lg={4}>
                            <Row className={'mgt5'}>
                                <Col sm={5} md={5} lg={4}>
                                    <Label className={'mgt5'}>{Config.lang('Phan_loai')}</Label>
                                </Col>
                                <Col sm={7} md={7} lg={7}>
                                    <Select
                                        value={dataCboCode.filter(item => filter?.ICodeID.includes(item.value))}
                                        options={dataCboCode}
                                        placeholder={""}
                                        displayExpr={'label'}
                                        valueExpr={'value'}
                                        isDisabled={disabled}
                                        getOptionLabel={option => `${option?.value} - ${option?.label}`}
                                        onChange={(e) => this.handleChange("ICodeID", e)}
                                        isMulti={true}
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col sm={12} md={6} lg={4}>
                            <CheckBox
                                className={"mgt10"}
                                value={filter.IsHideQuantity === 1}
                                text={Config.lang("Hien_thi_so_luong_bang_0")}
                                onValueChanged={this.onChangeIsHideQuantity}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup>
                        <Col sm={12}>
                            <GridContainer
                                dataSource={dataGrid?.rows || []}
                                totalItems={dataGrid?.total || 0}
                                itemPerPage={this.filter.limit}
                                skipPerPage={this.filter.skip}
                                // onAddNew={this.onAddNew}
                                isPer={4}
                                buttonCRM={btnCRM}
                                listPerPage={[10, 20, 30, 50]}
                                valueSearch={search}
                                selection={{mode: 'multiple'}}
                                onSearch={this.onSearch}
                                showSendMail={data && data.length > 0}
                                onSendMail={() => this.onSendMail(true)}
                                onChangePage={(page) => {
                                    this.onChangePage(page)
                                }}
                                onChangePerPage={(per) => {
                                    this.onChangePerPage(per)
                                }}
                                onSelectionChanged={(e, rowData) => {
                                    this.onSelectionChanged(rowData);
                                }}
                            >
                                <Column
                                    dataField={"InventoryID"}
                                    caption={Config.lang("Ma_hang")}
                                    allowSorting={true}
                                    width={160}
                                    cellRender={this.renderItem}
                                />
                                <Column
                                    dataField={'InventoryName'}
                                    caption={Config.lang("Ten_hang")}
                                    allowSorting={true}
                                    minWidth={220}
                                    cellRender={this.renderItem}
                                />
                                <Column
                                    dataField={'UnitName'}
                                    caption={Config.lang("Ten_don_vi_tinh")}
                                    allowSorting={true}
                                    width={180}
                                />
                                <Column
                                    dataField={'WareHouseID'}
                                    caption={Config.lang("Ma_kho")}
                                    allowSorting={true}
                                    width={180}
                                />
                                <Column
                                    dataField={'WareHouseName'}
                                    caption={Config.lang("Ten_kho")}
                                    allowSorting={true}
                                    width={180}
                                />
                                <Column
                                    dataField={'CQuantityStock'}
                                    caption={Config.lang("So_luong_ton_kho")}
                                    allowSorting={true}
                                    dataType={"number"}
                                    format={{
                                        type: "fixedPoint",
                                        precision: dataQuantityDecimal?.D07_QuantityDecimals || 0
                                    }}
                                    width={180}
                                />
                                {dataColumns && dataColumns.map((col, idx) => {
                                    if (col?.IsModule === 0) return null;
                                    return <Column
                                        key={idx}
                                        dataField={`ICode${col?.IcodeID.trim()}ID`}
                                        caption={col?.IcodeCaption || ""}
                                        allowSorting={true}
                                        width={180}
                                    />
                                })}
                            </GridContainer>
                        </Col>
                    </FormGroup>
                </Form>
                {this.state.showSendMail && <PopupScroll
                    title={Config.lang("Gui_mail")}
                    show={true}
                    animation={true}
                    onHide={() => this.onSendMail(false)}>
                    <SendMail companyID={window.location.search.split('CompanyID=')[1]}
                              data={this.state.dataMail}
                              onHide={() => this.onSendMail(false)}/>
                </PopupScroll>}
            </div>
        )
    }
}

export default connect(state => ({
        dataCboInventoryType: state.w17f5560.dataCboInventoryType,
        dataCboInventory:     state.w17f5560.dataCboInventory,
        dataCboTypeCode:      state.w17f5560.dataCboTypeCode,
        dataCboCode:          state.w17f5560.dataCboCode,
        dataGrid:             state.w17f5560.dataGrid,
        dataColumns:          state.w17f5560.dataColumns,
        dataCboWarehouse:          state.w17f5560.dataCboWarehouse,
        dataQuantityDecimal:          state.w17f5560.dataQuantityDecimal,
    }),
    (dispatch) => ({
        w17f5560Actions: bindActionCreators(w17f5560Actions, dispatch),
        userActions:     bindActionCreators(userActions, dispatch),
    })
)(W17F5560Page);

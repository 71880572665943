/**
 * @copyright 2019 @ DigiNet
 * @author XUANVINH
 * @create 2019/01/11 10:37
 * @update 2019/01/11 10:37
 */

import React from 'react';
import {
    FormGroup,
    Form,
    Col, Row
} from 'react-bootstrap';
import {connect} from "react-redux";
import ButtonSave from "../common/button/button-save";
import PopupView from "../popup/popup-view";
import Proptypes from "prop-types";
import EventTracking from "../common/tracking/event";
import Config from "../../config";
import * as action from "../../actions/w17/w17F1521/w17f1521-actions";
import {bindActionCreators} from "redux";
import notify from "devextreme/ui/notify";
import {CheckBox} from 'devextreme-react';
import PageContainer from "../common/page-container/page-container";
import ButtonSaveNext from "../common/button/button-savenext";
import {Loading} from "../common/loading/loading";

class W17F1521Pop extends React.PureComponent {
    constructor(props) {
        super(props);
        const {data} = props;
        this.state = {
            caseTypeID: props.data ? data.CaseTypeID : '',
            isSaved: false,
            caseTypeName: data ? data.CaseTypeName : '',
            remark: data ? data.Remark : '',
            disabled: data ? data.Disabled === 1 : false,
        };
    }

    onHide() {
        const {onHide} = this.props;
        if (onHide) onHide();
    };

    clearData = () => {
        this.setState({
            caseTypeID: '',
            isSaved: false,
            caseTypeName: '',
            disabled: false,
            remark: ''
        })
    };

    saveData = () => {
        const {mode} = this.props;
        const {caseTypeID, caseTypeName, remark, disabled} = this.state;
        const param = {
            CaseTypeID: caseTypeID,
            CaseTypeName: caseTypeName,
            Remark: remark,
            Disabled: disabled ? 1 : 0
        };

        if (!caseTypeID) {
            Config.popup.show('INFO', Config.lang('CRM_Ma_khong_duoc_de_trong'), null, null, () => {
                setTimeout(() => {
                    document.getElementById('caseTypeIDW17F1521').focus();
                }, 300)
            });
            return false;
        }
        if (!caseTypeName) {
            Config.popup.show('INFO', Config.lang('CRM_Ten_khong_duoc_de_trong'), null, null, () => {
                setTimeout(() => {
                    document.getElementById('caseTypeNameW17F1521').focus()
                }, 300)
            });
            return false;
        }

        this.setState({
            loading: true
        });
        this.props.action.saveData(param, mode, (error) => {
            this.setState({
                loading: false
            });
            if (error) {
                let message;

                message = error.code === 'F1521E003' ? Config.lang('CRM_Ma_da_ton_tai') : Config.lang("CRM_Co_luu_khi_luu");
                Config.popup.show("INFO", message, null, null, () => {
                    if (error.code === 'F1521E003') {
                        setTimeout(() => {
                            document.getElementById('caseTypeIDW17F1521').focus();

                        }, 300)
                    }
                });
                return false;
            }
            if (mode === 'add')
                this.setState({
                    isSaved: true
                });
            notify(Config.lang("CRM_Luu_thanh_cong"), "success", 2000);
        })
    };
    changeCaseTypeID = (e) => {
        this.setState({
            caseTypeID: Config.replaceSymbol(e.target.value)
        })
    };

    changeRemark = e => {
        this.setState({
            remark: e.target.value
        })
    };

    changeCaseTypeName = e => {
        this.setState({
            caseTypeName: e.target.value
        })
    };

    changeDisabled = e => {
        this.setState({
            disabled: e.value
        })
    };

    render() {
        const {isSaved, caseTypeID, loading, caseTypeName, remark, disabled} = this.state;
        const {mode} = this.props;

        return (
            <PopupView
                title={Config.lang('CRM_Cap_nhat_loai_tinh_huong')}
                show={true}
                className={'popup-size-80'}
                animation={true}
                onHide={() => this.onHide()}>
                <EventTracking action={"FORM"} label={"W18F1060Popup"}/>
                <PageContainer>
                    {loading && <Loading/>}
                    <Form id={'W18F1060Popup'} horizontal={true}>
                        <FormGroup className={'mgt5'}>
                            <Col lg={2}>
                                <label className={'control-label'}>{Config.lang("CRM_Ma_loai_tinh_huong")}<span
                                    className={'text-red'}>(*)</span></label>
                            </Col>
                            <Col lg={10}>
                                <Row>
                                    <Col lg={4}><input disabled={mode === 'edit'}
                                                       onChange={this.changeCaseTypeID}
                                                       id={'caseTypeIDW17F1521'} value={caseTypeID}
                                                       className={'form-control'}/>
                                    </Col>
                                    <Col lg={3}>
                                        <label
                                            className={'control-label'}>{Config.lang('CRM_Ten_loai_tinh_huong')}<span
                                            className={'text-red'}>(*)</span></label>
                                    </Col>
                                    <Col lg={4}>
                                        <input disabled={mode === 'add' && isSaved}
                                               id={'caseTypeNameW17F1521'}
                                               onChange={this.changeCaseTypeName}
                                               value={caseTypeName}
                                               className={'form-control'}/>
                                    </Col>
                                </Row>
                            </Col>
                        </FormGroup>
                        <FormGroup>
                            <Col lg={2}>
                                <label className={'control-label'}>{Config.lang('CRM_Khong_su_dung')}</label>
                            </Col>
                            <Col lg={10}>
                                <Row>
                                    <Col lg={12}>
                                        <CheckBox
                                            disabled={mode === 'add' && isSaved}
                                            onValueChanged={this.changeDisabled}
                                            value={disabled}
                                            className={'mgt5'}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </FormGroup>
                        <FormGroup>
                            <Col lg={2}>
                                <label className={'control-label'}>{Config.lang("CRM_Mo_ta")}</label>
                            </Col>
                            <Col lg={10}>
                                <textarea disabled={mode === 'add' && isSaved}
                                          id={'remarkW17F1521'}
                                          value={remark}
                                          className={'form-control'}
                                          rows={5}
                                          onChange={this.changeRemark}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup>
                            <Col lg={12}>
                                <div className={'pull-right'}>
                                    {
                                        mode === 'add' && isSaved &&
                                        <ButtonSaveNext onClick={this.clearData} className={'mgr5'}
                                                        name={Config.lang("CRM_Nhap_tiep")}/>
                                    }
                                    {
                                        !isSaved &&
                                        <ButtonSave onClick={this.saveData} className={'mgr5'}
                                                    name={Config.lang("CRM_Luu")}/>
                                    }

                                </div>
                            </Col>
                        </FormGroup>
                    </Form>
                </PageContainer>
            </PopupView>
        )
    }
}

W17F1521Pop.propTypes = {
    onHide: Proptypes.func,
};

export default connect(null,
    (dispatch) => ({
        action: bindActionCreators(action, dispatch)
    })
)(W17F1521Pop);

/**
 * @copyright 2019 @ DigiNet
 * @author THANHHAI
 * @create 09/03/2021
 * @update 09/03/2021
 */

import React from 'react';
import { bindActionCreators } from 'redux';
import connect from 'react-redux/es/connect/connect';
import {browserHistory} from "react-router";
import moment from "moment"
import _ from "lodash";

import FileUploadAsync from "../../../actions/file-upload-new-async";
import FileRemoveAsync from "../../../actions/file-remove-async";
import FileDeleteAsync from "../../../actions/file-delete-async";
import * as W17F2025Actions from "../../../actions/w17/W17F2025/W17F2025-actions";
import * as userActions from "../../../actions/user-actions";
import Config from '../../../config';

import {Row, Col, Form, FormGroup } from "react-bootstrap";
import ButtonSave from '../../common/button/button-save';
import ButtonAdd from '../../common/button/button-add';
import ButtonCustom from '../../common/button/button-custom';
import ButtonEdit from '../../common/button/button-edit';
import { Column } from 'devextreme-react/tree-list';
import { DateBox} from 'devextreme-react';
import Combo from '../../common/dropdown/combo';
import EventTracking from '../../common/tracking/event';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import W18F1015PopUp from "../../W18/W18F1015";
import GridActionBar from '../../common/action-bar/grid-action-bar';
import GridContainer from '../../common/grid-container/grid-container';
import notify from 'devextreme/ui/notify';
import { Loading } from '../../common/loading/loading';
import NumberFormat from 'react-number-format';

class W17F2025Page  extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state ={
            QuantityDecimals:0,
            iPermission: 0,
            loading:false,
            loadingNewKey:false,
            attachments:[],
            attached: [], //Du lieu da dinh kem,
            showW18f1015: false,
            allowAddMore:false,
            dataGrid:[],
            dataGridTotal:0,
            masterInputData:{
                ObjectID:"",
                ObjectName:"",
                ObjectTypeID:"",
                CaseSourceID:"",
                CollaboratorID:"",
                Description:"",
                EmployeeID:"",
                IsCollaborator:0,
                RequestDate:new Date(),
                RequestID:"",
                StatusID:"",

            }

        }
        this.OldAttachData = [];//Du lieu da dinh kem cu bi xoa;
        this.filter = {
            skip: 0,
            limit: 20,
        }
    }
    getInfo = () => {
        const {location} = this.props
        const url = new window.URLSearchParams(window.location.search);
        let state =  {}
        const keys = [...url.keys()]
        if (keys.length) {
           
            for (let key of keys) {
               state[key] = url.get(key)  
            }
        }
        state = {
            ...state,
            ...( (location && location.state) || {}),
        }
        state.mode = state.mode || "add"
        // if ( (!state.type)) {
        //      browserHistory.push(Config.env.url + '/W17F1025');
        //      return false
        // }
        return state

    };
    async loadPermission() {
        await this.props.userActions.getPermission(Config.profile.UserID, "W17F2025", (iPer)=>{
            this.setState({iPermission: iPer});
        });
    }
    componentDidMount = async () => {
        const {mode,CompanyTypeID,CompanyID,RequestID,CompanyName} = this.getInfo()
        //await this.loadPermission();
        this.setState({iPermission:4})
        //  if (this.state.iPermission <= 0) return;
        if (!this.getInfo()) return;
        setTimeout(() => {
            this.setState({loading: false});
        }, 60000);
        //load cbo 
        this.props.W17F2025Actions.getCboCaseSource()
        this.props.W17F2025Actions.getDMFSetting({},(error,data) => {
            if (data) {
                this.setState({QuantityDecimals: _.get(data,"0.D07_QuantityDecimals",0)})
            }
        })
        this.props.W17F2025Actions.getCboSalesPerson({Language: Config.language || '84',GroupSalesID:""},(error,data) =>{
            if (data && mode === "add") {
                this.onChangeMasterFormInputObject({EmployeeID: _.get(data,"0.SalesPersonID","")})
            }
        })
        this.props.W17F2025Actions.getCboStatus({},(error,data) =>{
            if (data && mode === "add") {
                this.onChangeMasterFormInputObject({StatusID: _.get(data,"0.StatusID","")})
            }
        })
        this.props.W17F2025Actions.getCboCollaborator()
        if (mode === "add") {
            this.loadNewKey()
            this.onChangeMasterFormInputObject({
                ObjectID:CompanyID,
                ObjectName:CompanyName,
                ObjectTypeID:CompanyTypeID,
                RequestID:RequestID,
                //EmployeeID: _.get(Config.profile,"UserID","")
            })
            
        }
        if (mode === "edit" || mode === "view") {
            this.loadFormMaster()
            this.loadAttachments()
        }
       
      
       
    };

    
    
    getBaseParams =() =>{
        const {RequestID} = this.getInfo();
        const {skip,limit} = this.filter
        return {    
            Language: Config.language || '84',
            FormID: 'W17F2025',
            RequestID,
            skip,
            limit,
        };
    } 
    loadAttachments = () => {
        const {RequestID} = this.getInfo()
        const param = {
            KeyID: RequestID,
            TableName : 'W17T2025'
        };
        this.props.W17F2025Actions.getAttachments(param, (error, data) => {
            if (error) {
                let errorCode = error.code || null;
                let message = "";
                switch (errorCode) {
                    case "US004":
                        message = "Tài khoản đăng nhập hoặc mật khẩu không đúng.";
                        break;
                    default:
                        message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                        break;
                }
                Config.popup.show('INFO', message);
            } else {
                if (data) {
                    this.setState({
                        attached: data
                    })
                }
            }
        });
    }
    loadNewKey = () =>{
        const params = {
            Language: Config.language || '84',
        }
        this.setState({loadingNewKey: true});
        this.props.W17F2025Actions.getNewKey(params,(error,data) => {
            
            if (error) {
                let message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                this.setState({loadingNewKey: false});
                return false;
            }
            if (!data) {
                let message =Config.lang("CRM_Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                this.setState({loadingNewKey: false});
                return false;
            }
            this.setState({loadingNewKey: false,masterInputData:{...this.state.masterInputData,RequestID: data.NewKey}});
       })

    }
    loadFormMaster = (reloadMaster =true) => {
        const {mode} = this.getInfo()
        const paramMaster = {
            ...this.getBaseParams(),
        }
        this.setState({loading: true});
        this.props.W17F2025Actions.getForm(paramMaster,(error,data) => {
            if (error || !data) {
                let message =(error && error.message )|| Config.lang("CRM_Loi_chua_xac_dinh");
                this.setState({ loading: false});
                return Config.popup.show('INFO', message);
            }
            let {grid,...masterInputData} = data || {}
            let dataGrid = _.get(grid,"rows",[])
            let dataGridTotal = _.get(grid,"total",0)
            let updateState ={loading:false,dataGrid,dataGridTotal}
            if (reloadMaster) {
                updateState.masterInputData = {
                    ...this.state.masterInputData,
                    ...masterInputData
                }
                if (mode === "add") {
                    updateState.masterInputData.StatusID = _.get(this.props,"cboStatus.0.StatusID","") 
                    updateState.masterInputData.EmployeeID = _.get(this.props,"cboSalesPerson.0.SalesPersonID","") 
                }else{
                    
                    document.getElementById("DescriptionInput").value = updateState.masterInputData.Description || ""
                }
                
            }
            this.setState(updateState)
            
            
        })
    }
    //get
    getSaveParams =() => {
        const {masterInputData,dataGrid} = this.state
        return {
            Language: Config.language || '84',
            RequestID: masterInputData.RequestID || "",
            data: dataGrid ? dataGrid.map((item => {
                return {
                    TransID: item.TransID || "" ,
                    ProductDescription:  item.ProductDescription || "",
                    Quantity:  item.Quantity || 0,
                    //
                    RequestDate: masterInputData.RequestDate ? moment(masterInputData.RequestDate).format("YYYY-MM-DD") : "" ,
                    EmployeeID: masterInputData.EmployeeID || "" ,
                    StatusID:  masterInputData.StatusID || "",
                    ObjectTypeID:  masterInputData.ObjectTypeID || "",
                    ObjectID:  masterInputData.ObjectID || "",
                    CaseSourceID:  masterInputData.CaseSourceID || "",
                    IsCollaborator:  masterInputData.IsCollaborator || 0,
                    CollaboratorID:  masterInputData.CollaboratorID || "",
                    CollaboratorName:  masterInputData.CollaboratorName  || "",
                    Description:  document.getElementById("DescriptionInput").value || "",
                    
                }
            })):[]
            
        }
    }
    loadData = () =>{
        this.loadFormMaster()
        this.loadAttachments()
    }
    //check
    checkSaveParams = (params) =>{
        for (let index = 0; index < params.data.length; index++) {
            const item = params.data[index];
            if (!item.RequestDate) {
                return `${Config.lang("CRM_Ban_chua_nhap_gia_tri")}: ${Config.lang("CRM_Ngay_lap")}!`
            }
            if (!item.StatusID) {
                return `${Config.lang("CRM_Ban_chua_nhap_gia_tri")}: ${Config.lang("CRM_Trang_thai_thuc_hien")}!`
            }
           
            if (!item.EmployeeID) {
                return `${Config.lang("CRM_Ban_chua_nhap_gia_tri")}: ${Config.lang("CRM_Nhan_vien_kinh_doanh")}!`
            }
            // if (!item.ObjectID) {
            //     return `${Config.lang("CRM_Ban_chua_nhap_gia_tri")}: ${Config.lang("CRM_Khach_hang")}!`
            // }
            if (!item.ProductDescription) {
                return `${Config.lang("CRM_Ban_chua_nhap_gia_tri")}: ${Config.lang("CRM_Mo_ta_san_pham")}!`
            }
            if (item.IsCollaborator === 1) {
                if (!item.CollaboratorID) {
                    return `${Config.lang("CRM_Ban_chua_nhap_gia_tri")}: ${Config.lang("CRM_Ma_CTV")}!`
                }
            }
            
           
        }
        if (params.data.length<1) {
            return Config.lang("CRM_Nhap_it_nhat_1_san_pham")
        }
        return false
    }
    checkEdit = (cb) =>{
        const {RequestID} = this.state.masterInputData
        const params ={
            Language: Config.language || '84',
            RequestID,
        }
        this.setState({loading:true})
        this.props.W17F2025Actions.checkEdit(params,(error,data) =>{
            this.setState({ loading: false});
            if (error || !data) {
                let message =(error && error.message )|| Config.lang("CRM_Loi_chua_xac_dinh");
               
                return Config.popup.show('INFO', message);
            }
            if (data.Status === 0) {
                cb && cb()
                return false
            }
            if (data.MsgAsk  === 0) {
                Config.popup.show('INFO', data.Message);
                return false
            }
            Config.popup.show('YES_NO', Config.lang("CRM_Ban_co_chac_chan_muon_sua_du_lieu_nay"),()=>cb&&cb(),()=>{
                Config.popup.show('INFO', data.Message);
            });
            
        })
    }
    //on
    onChangeLocatioState =(state) =>{
        let {location = {}} = this.props
        location.state ={
            ...(location.state || {}),
            ...(state ||{})
        }
        browserHistory.replace(location)
        
    }
    onChangePage(page) {
        this.filter.skip = page * this.filter.limit;
        this.loadFormMaster(false);
    }

    onChangePerPage(per) {
        this.filter.skip = 0;
        this.filter.limit = per;
        this.loadFormMaster(false);
    }
    onClickComback = () =>{
        let state = this.getInfo();
        const { currentTabKey } = this.getInfo();
        state={
            ...state,
            ...state.formCallState,
            currentTabKey
        }
        if (state.formCall) {
            browserHistory.push({ pathname: Config.getRootPath() + state.formCall, state: state });
        } else {
            browserHistory.goBack();
        }
       
    }
    onClickEdit = () =>{
        this.checkEdit(()=>this.onChangeLocatioState({mode: "edit"}))
        
    }
    onClickSave = () =>{
        const {mode} = this.getInfo()
        let params = this.getSaveParams()
        let error = this.checkSaveParams(params)
        if (error) {
            return Config.popup.show('INFO', error);
        }
        this.onSave(params,mode,()=>{
            this.onChangeLocatioState({
                RequestID: params.RequestID,
                mode: "view",
            })
            this.loadData()
            
    })
    }
    onClickAddMore = () =>{
        const {CompanyID,CompanyTypeID,CompanyName} = this.getInfo()
        const masterInputData = {
                                    ...this.state.masterInputData,
                                    ObjectID:CompanyID,
                                    ObjectName:CompanyName,
                                    ObjectTypeID:CompanyTypeID,
                                    CaseSourceID:"",
                                    CollaboratorID:"",
                                    CollaboratornName:"",
                                    Description:"",
                                    EmployeeID: _.get(Config.profile,"UserID",""),
                                    IsCollaborator:0,
                                    RequestDate:new Date(),
                                    RequestID:"",
                                    StatusID: _.get(this.props,"cboStatus.0.StatusID",""),
                                }
        
        
        
        this.setState({masterInputData,
            dataGrid:[],
            dataGridTotal:0,
            attachment:[],
            attached:[],
        })
        this.loadNewKey()
        this.onChangeLocatioState({mode:"add",RequestID:""})
    }
    onClickAddNewGridItem = () =>{
        if (this.gridContainer) {
          
            setTimeout(() => {
                this.gridContainer.instance.addRow();
            }, 300);
        }
    }
    onClickRemoveGridItem = (e) =>{
        Config.popup.show("YES_NO", Config.lang("CRM_Ban_co_chac_chan_muon_xoa_du_lieu_nay"), () => {
            let grid = [...this.state.dataGrid];
            grid.splice(e.rowIndex, 1);
            this.setState({
                dataGrid: grid
            });
        })
    }
    onSaveAttachment =async (KeyID,mode) => {
        try {
            const { attachments,attached } = this.state;
        if (attachments.length) {
            await Promise.all(attachments.map(async item =>{
                const exp = item.file.name.split(".");
                const paramAttach = {
                    "KeyID": KeyID,
                    "FileName": item.file.name,
                    "FileSize": item.file.size,
                    "FileExp": exp[exp.length - 1],
                    "TableName": "W17T2025"
                };
                const upload = await FileUploadAsync(item.file, paramAttach);
                if (upload && upload.code === 200) {
                    item.AttachmentID = upload.KeyID;
                    attached.push(item);
                }else{
                    throw Config.lang("CRM_Loi_chua_xac_dinh")
                }
            }))
            this.setState({
                attachments: [],
                attached: [],
            });
        }
        if (mode === "edit") {
            if (this.OldAttachData.length) {
                await Promise.all(this.OldAttachData.map(async item =>{
                    await Promise.all([
                        FileDeleteAsync({ AttachmentID: item.AttachmentID}),
                        FileRemoveAsync({path: item.URL})
                    ]);
                }))
                this.OldAttachData =[]
            }
        }
        return false
        } catch (error) {
            return error.toString()
        }
    }
    onSave = (params,mode,cb) => {
        
        this.setState({loading:true})
        if (mode === "add") {
            return this.props.W17F2025Actions.add(params,async (error,data) => {
                    if (error || !data) {
                        let message =(error && error.message )|| Config.lang("CRM_Loi_chua_xac_dinh");
                        this.setState({ loading: false});
                        return Config.popup.show('INFO', message);
                    }
                    if (data.Status === 0) {
                        // thanh cong
                        let attachmentError = await this.onSaveAttachment(params.RequestID,mode)
                        if (attachmentError) {
                            Config.popup.show('INFO', attachmentError);
                        }else{
                           notify(Config.lang("CRM_Luu_thanh_cong"),"success",2000)
                        }
                        this.setState({ loading: false,allowAddMore: true});
                    }
                    cb && cb()
                })
        }
        this.props.W17F2025Actions.edit(params,async (error,data) => {
            if (error || !data) {
                let message =(error && error.message )|| Config.lang("CRM_Loi_chua_xac_dinh");
                this.setState({ loading: false});
                return Config.popup.show('INFO', message);
            }
            if (data.Status === 0) {
                // thanh cong
                let attachmentError = await this.onSaveAttachment(params.RequestID,mode)
                if (attachmentError) {
                    Config.popup.show('INFO', attachmentError);
                }else{
                   notify(Config.lang("CRM_Luu_thanh_cong"),"success",2000)
                }
                this.setState({ loading: false,allowAddMore: false});
            }


            cb && cb()
        })
       
    }
    onChangeNumber = (e,name,action) =>{
        const {dataGrid} = this.state
        let newValue = _.get(e,`${action === "update" ? "newData" : "data" }.${name}`,false)
        if (newValue) {
            let rowIndex = e.component.getRowIndexByKey(e.key);
            dataGrid[rowIndex][name] = newValue
            this.setState({dataGrid})
            e.component.refresh();
        }
    }
    onChangeMasterFormInput =(e)=>{
        let {name,value} = e.target
        let newMasterInputData = {...this.state.masterInputData}
        
        switch (name) {
            case "CollaboratorID":
                let Collaborator = this.props.cboCollaborator &&  this.props.cboCollaborator.find(item => item.CollaboratorID === value)
                
                newMasterInputData[name] = value
                newMasterInputData['CollaboratorName'] = Collaborator? Collaborator.CollaboratorName : ""
                break;
        
            default:
                newMasterInputData[name] = value
                break;
        }
        this.setState({masterInputData:newMasterInputData})

    }
    onChangeMasterFormInputObject =(obj)=>{

        this.setState({masterInputData:{...this.state.masterInputData,...obj}})
    }
    onAddAttachment =(e) =>{
        if (!e) return;
        const files = _.get(e, "target.files", []);
        const sizeLimit = 20;
        if (files.length === 0) {
            return null;
        } else if (this.checkFileType(files[0].type) === false) {
            Config.popup.show("INFO", `${Config.lang("CRM_Dinh_dang_file_khong_hop_le")}`);
        } else if ((files[0].size / 1024 / 1024) > Number(sizeLimit)) {
            Config.popup.show("INFO", `${Config.lang("CRM_Dung_luong_File_khong_duoc_lon_hon")} ${sizeLimit} MB`);
        } else {
            let attachments = [...this.state.attachments];
            for (let file of files) {
                file.uploading = true;
                attachments.push({ file: file, FileName: file.name, URL: window.URL.createObjectURL(file) });
            }
            this.setState({ attachments });
        }
    }
    async deleteAttach(file) {
        const {attachments} = this.state;
        let arr = attachments.filter((value) => {
            return value.FileName !== file.FileName;
        });

        this.setState({
            attachments: arr
        });

       
    }
    deleteOldAttach(attachment) {

        this.OldAttachData.push(attachment);
        const attached = this.state.attached;
        let arr;
        arr = attached.filter(function (obj) {
            return obj.AttachmentID !== attachment.AttachmentID;
        });
        this.setState({
            attached: arr
        })

    }
    checkFileType = (filetype) => {
        const allowedExtensions = [
            'text/plain',
            'image/jpeg',
            'image/png',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'application/msword',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'application/pdf'
        ];
        return filetype.length > 0 && allowedExtensions.indexOf(filetype) !== -1

    }
    renderAction =(data) =>{
        const {iPermission} = this.state
        const {mode} = this.getInfo()
        return  <GridActionBar
        isPer={iPermission}
        tooltipEdit={"Sửa"}
        className={(iPermission <= 2 || mode === "view") ? 'isDisabled' : ""}
        classNameIs={iPermission}
        tooltipDelete={Config.lang("CRM_Xoa")}
        onDeleteAction={() => {
            mode !== "view" && this.onClickRemoveGridItem(data)
        }}
    

        
        />
    }
    renderIcon = (file, url) => {
        let ext = file ? file.split('.') : '';
        ext = ext[ext.length - 1];
        if (['doc','docx','xls','xlsx','ppt','pptx','pdf','txt'].includes(ext)) {
            return (<img alt="" src={require(`../../../images/file/icon-${ext}.png`)}/>);

        }else{
             return (<img alt="" src={url} className="img-background"/>);
        }
    };
    //render
    renderToolbar =({mode}) => {
        const {iPermission,allowAddMore} = this.state
        return <Row className="form-group">
                    <Col md={12} sm={12} xl={12} xs={12} lg={12}>
                        <ButtonCustom color={'text-orange'} icon={'fas fa-arrow-alt-left'} isAwesome={true}
                                    name={Config.lang("CRM_Quay_lai")}
                                    onClick={() => {
                                        this.onClickComback()
                                    }}/>
                        <ButtonEdit
                            className={`mgl5 ${iPermission >=3 ? "" : "isDisabled"} ${mode === "view"? "" : "hide"}`}
                            name={Config.lang("CRM_Sua")} onClick={() => {
                                if (iPermission >=3) {
                                    this.onClickEdit()
                                }
                            }}
                        />
                        <ButtonSave className={`mgl5 ${iPermission >=2 ? "" : "isDisabled"} ${!(mode === "view")? "" : "hide"}`}
                            name={Config.lang("CRM_Luu")} onClick={() => {
                                if (iPermission >=2) {
                                  this.onClickSave()
                                }
                            }}
                        />
                        <ButtonAdd className={`mgl5 ${iPermission >=2 ? "" : "isDisabled"} ${(allowAddMore && mode ==="view")? "" : "hide"}`}
                            name={Config.lang("CRM_Nhap_tiep")} onClick={() => {
                                if (iPermission >=2) {
                                  this.onClickAddMore()
                                }
                            }}
                        />
                       
                        <input ref ={ref => this.attachInputRef = ref}  multiple={'multiple'} type="file"
                                           className="media_upload_input hide"
                                           onChange={this.onAddAttachment}/>
                         <ButtonCustom 
                            icon={'fas fa-cloud-upload'} 
                            isAwesome={true}
                            className={mode === "view" ? 'hide mgl5' : 'mgl5'}
                            color={'text-green'} name={Config.lang("CRM_Dinh_kem")}
                            onClick={() => {
                                this.attachInputRef && this.attachInputRef.click()
                            }}
                        />
                        

                    </Col>

                </Row>
    }
    renderFormMaster = (locationState) => {
        const {mode,CallFrom} = locationState ||{}
        const {loadingNewKey,masterInputData,attachments,attached} = this.state
        const {cboCaseSource,cboStatus,cboSalesPerson,cboCollaborator} = this.props
        const disabled = mode === "view"
        return (
            <div>
                {/* line 1 */}
                 <FormGroup>
                <Col md={2} sm={2} xs={6} lg={2}><label
                    className={"control-label"}>{Config.lang("CRM_Ma_nhu_cau")}<span
                        className={'text-red'}> (*)</span> 
                        {
                            mode === "add" && <i  style ={{cursor:"pointer"}} onClick={() =>  this.loadNewKey()} 
                            className={`fas fa-sync mgl20 text-blue ${loadingNewKey? "fa-spin" : ""} `}/>
                        }
                        </label></Col>
                <Col  md={4} sm={4} xs={6} lg={4}>
                    <input className={'form-control'}
                        name="CompanyID"
                        onChange={this.onChangeMasterFormInput}
                        value={masterInputData.RequestID || ""}
                        disabled
                    />
                </Col>

                <Col md={2} sm={2} xs={6} lg={2}><label
                    className={"control-label"}>{Config.lang("CRM_Trang_thai_thuc_hien")}<span
                    className={'text-red'}> (*)</span></label></Col>
                <Col  md={4} sm={4} xs={6} lg={4}>
                <Combo
                            showClearButton={true}
                            showHeader={false}
                            dataSource={cboStatus}
                            placeholder={''}
                            value={masterInputData.StatusID}
                            valueExpr="StatusID"
                            displayExpr="StatusName"
                            onActionWhenSelectChange={data =>{
                                this.onChangeMasterFormInputObject({
                                    StatusID: data ? data.StatusID : "",
                                })
                            }}
                            disabled ={disabled}
                            
                            
                        >
                            <Column dataField={'StatusName'} caption="" width={'150px'}
                                        dataType={'string'}/>
                        </Combo>
                    
                </Col>
            </FormGroup>
                {/* line 2 */}
                <FormGroup>
                    <Col md={2} sm={2} xs={6} lg={2}><label
                                    className="control-label">{Config.lang("CRM_Ngay_lap")}<span
                                    className={'text-red'}> (*)</span></label>
                    </Col>
                    <Col md={4} sm={4} xs={6} lg={4} className={'mgb15'}>
                            <DateBox
                                name = "RequestDate"
                                width={"100%"}
                                dateSerializationFormat={'yyyy-MM-ddTHH:mm:ss'}
                                placeholder={Config.lang("CRM_Ngay_lap")}
                                openOnFieldClick={true}
                                useMaskBehavior={true}
                                showClearButton={true}
                                type={'date'}
                                pickerType={"calendar"}
                                showAnalogClock={false}
                                displayFormat={'dd/MM/y'}
                                value={masterInputData.RequestDate}
                                onValueChanged={e => this.onChangeMasterFormInputObject({RequestDate:e.value})}
                                disabled = {disabled}
                            />
                    </Col>

                    <Col md={2} sm={2} xs={6} lg={2}><label
                    className={"control-label"}>{Config.lang("CRM_Nhan_vien_kinh_doanh")}<span
                    className={'text-red'}> (*)</span></label></Col>
                    <Col  md={4} sm={4} xs={6} lg={4}>
                        <Combo
                            //reset={this.state.reset}
                            showHeader={false}
                            dataSource={cboSalesPerson}
                            value={masterInputData.EmployeeID}
                            valueExpr="SalesPersonID"
                            displayExpr="SalesPersonNameU"
                            onActionWhenSelectChange={(data) => {
                                this.onChangeMasterFormInputObject( {
                                    EmployeeID :data.SalesPersonID
                                })
                            }}
                            disabled ={disabled}
                        >
                            <Column dataField={'SalesPersonID'}
                                    caption=""
                                    minWidth={'150px'}
                                    visible={false}
                                    dataType={'string'}/>
                            <Column dataField={'UserID'} 
                                    caption=""
                                    minWidth={'150px'}
                                    visible={false}
                                    dataType={'string'}/>
                            <Column cellRender={(e) => {
                                if (!e) return null;
                                const imageData = e.row.data.Image;
                        
                                return (
                                    <Row className={'mg0'}>
                                        <Col sm={12} lg={12} className={'pd0'}>
                                            <div className='display_row staff-dropdown align-center'>
                                                <img className="image-sale-person"
                                                    alt={""}
                                                    src={imageData ? imageData : require('../../../images/icon-user-default.png')}
                                                />
                                                <div className='display_col staff-info'>
                                                    <span>{e.row.data.SalesPersonNameU}</span>
                                                    <span>{e.row.data.SalesPersonID}</span>
                                                    <span>{e.row.data.JobPosition}</span>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                );
                            }}
                                    dataField={'SalesPersonNameU'}
                                    caption=""
                                    minWidth={'150px'}
                                    dataType={'string'}/>
                        </Combo>
                    </Col>
                </FormGroup>
                {/*  line 3 */}
                <FormGroup>
                    <Col md={2} sm={2} xs={6} lg={2}><label
                        className={"control-label"}>{Config.lang("CRM_Khach_hang")}<span
                        className={'text-red'}> (*)</span></label></Col>
                    <Col  md={4} sm={4} xs={6} lg={4}>
                    
                        <select className={'form-control'}
                            value=""
                            onChange={(e) => {
                                e.preventDefault();
                                this.setState({
                                    showW18f1015: true
                                })
                            }}
                            onClick={(e) => {
                                e.preventDefault();
                                this.setState({
                                    showW18f1015: true
                                })

                            }}
                            disabled = {disabled || CallFrom ==="RequestTab"}
                            
                        >
                            <option value={""}>{masterInputData.ObjectName || ""}</option>
                        </select>
                        {this.state.showW18f1015 &&
                                            <W18F1015PopUp onHide={(data) => {
                                                this.onChangeMasterFormInputObject({
                                                    ObjectID:data.CompanyID,
                                                    ObjectTypeID: data.CompanyTypeID,
                                                    ObjectName: data.CompanyNameU
                                                })
                                                this.setState({showW18f1015:false})
                                                
                                            }}
                                            formID = "W17F2025"
                                            />}
                    </Col>
                    <Col md={2} sm={2} xs={6} lg={2}><label
                        className={"control-label"}>{Config.lang("CRM_Nguon_goc")}</label></Col>
                    <Col  md={4} sm={4} xs={6} lg={4}>
                        <Combo
                            showClearButton={true}
                            showHeader={false}
                            dataSource={cboCaseSource}
                            placeholder={''}
                            value={masterInputData.CaseSourceID}
                            valueExpr="CaseSourceID"
                            displayExpr="CaseSourceName"
                            onActionWhenSelectChange={data =>{
                                this.onChangeMasterFormInputObject({
                                    CaseSourceID: data ? data.CaseSourceID : "",
                                })
                            }}
                            disabled ={disabled}
                            
                            
                        >
                            <Column dataField={'CaseSourceName'} caption="" width={'150px'}
                                        dataType={'string'}/>
                        </Combo>
                        
                    </Col>

                    
                    
                </FormGroup>
                {/* line 4 */}
                <FormGroup>
                <Col md={2} sm={2} xsHidden lg={2}/>
                    <Col   md={10} sm={10} xs={12} lg={10}>
                        <FormControlLabel
                        style ={{marginLeft:0}}
                            control={<Checkbox  
                        
                                color="primary"
                                value="1"
                                onChange= {e => this.onChangeMasterFormInputObject({IsCollaborator:e.target.checked?1:0})}
                                checked={masterInputData.IsCollaborator === 1}
                                disabled ={mode ==="view"}
                            />}
                            label={Config.lang("CRM_La_cong_tac_vien")}
                        />
                    </Col>
                </FormGroup>
                <FormGroup>
                    <Col md={2} sm={2} xs={6} lg={2}><label
                        className={"control-label"}>{Config.lang("CRM_Ma_CTV")}
                        {masterInputData.IsCollaborator === 1 && <span
                            className={'text-red'}> (*)
                            </span>
                        }
                        </label>
                    </Col>
                    <Col  md={4} sm={4} xs={6} lg={4}>
                            <Combo
                                showClearButton={true}
                                showHeader={false}
                                dataSource={cboCollaborator}
                                placeholder={''}
                                value={masterInputData.CollaboratorID}
                                valueExpr="CollaboratorID"
                                displayExpr="CollaboratorID"
                                onActionWhenSelectChange={data =>{
                                    this.onChangeMasterFormInputObject({
                                        CollaboratorID: data ? data.CollaboratorID : "",
                                        CollaboratorName: data ? data.CollaboratorName : "",
                                    })
                                }}
                                disabled ={disabled || masterInputData.IsCollaborator===0}
                                
                            >
                                 <Column dataField={'CollaboratorID'} caption="" 
                                                dataType={'string'}/>
                                 <Column dataField={'CollaboratorName'} caption=""
                                                dataType={'string'}/>
                            </Combo>
                       
                    </Col>
                        
                    <Col md={2} sm={2} xs={6} lg={2}><label
                    className={"control-label"}>{Config.lang("CRM_Ten_CTV")}</label></Col>
                    <Col  md={4} sm={4} xs={6} lg={4}>
                        <input className={'form-control'}
                            name="CollaboratorName"
                            //onChange={this.onChangeInput}
                            value={masterInputData.CollaboratorName || ""}
                            disabled
                        />
                    </Col>
                    
                    
                </FormGroup>
                <FormGroup>
                <Col md={2} sm={2} xs={6} lg={2}><label
                    className={"control-label"}>{Config.lang("CRM_Dien_giai")}</label></Col>
                <Col   md={4} sm={4} xs={6} lg={4}>
                    <textarea 
                        id ='DescriptionInput'
                        style ={{height:150}}
                        rows={5}
                        className={'form-control'}
                        name="Description"
                        disabled = {disabled}
                        
                    />
                </Col>
                <Col md={2} sm={2} xs={6} lg={2}><label
                    className={"control-label"}>{Config.lang("CRM_Tai_lieu_dinh_kem")}</label></Col>
                <Col   md={4} sm={4} xs={6} lg={4}>
                <Row md={12} sm={12} xs={12} lg={12} style ={mode ==="view"? {height:150,opacity:1, background:"#eee",overflow:"auto"}:{height:150,overflow:"auto"}}
                                         className={'mg0 display_row list-attach'}>
                                        {(attachments.length === 0 && attached.length === 0) && <span
                                            className={'text-no-data'}>{Config.lang('CRM_Khong_co_du_lieu')}</span>}

                                        {attached && attached.map((value, index) => {
                                            return (
                                                <label className={'view-attachment mgr5 control-label small '}
                                                       key={'view-attachment' + index}>
                                                    <a href={Config.env.api + '/file/get?AttachmentID=' + value.AttachmentID}
                                                       download>
                                                        {this.renderIcon(value.FileName, value.URL)}
                                                    </a>
                                                    {value.FileName}
                                                    {
                                                        mode !== "view" &&  <i className={this.state.disabled === true ? 'fas fa-trash-alt text-red deleteAttach hide' : 'fas fa-trash-alt text-red deleteAttach'}
                                                        onClick={() => {
                                                            this.deleteOldAttach(value)
                                                        }}
                                                     />
                                                    }
                                                   
                                                </label>
                                            )
                                        })}

                                        {attachments.map((value, index) => {
                                            if (value.uploading) {
                                                return (<div className={'view-loading'}>
                                                    <i key={index}
                                                       className="fas fa-spinner fa-spin"/>
                                                </div>)
                                            } else return (
                                                <label className={'view-attachment mgr5 control-label small '}
                                                       key={'view-attachment1' + index}>
                                                    {this.renderIcon(value.FileName, value.URL)}
                                                    {value.FileName}
                                                    {
                                                        mode !== "view" && <i className={this.state.disabled === true ? 'fas fa-trash-alt text-red deleteAttach hide' : 'fas fa-trash-alt text-red deleteAttach'}
                                                        onClick={() => {
                                                            this.deleteAttach(value)
                                                        }}/>
                                                    }
                                                </label>
                                            );
                                        })}
                                    </Row>
                              
                </Col>
            </FormGroup>
               
            </div>
        )
    }
    render() {
        const locationState =this.getInfo()
        const {mode}  = locationState || {}
        const {iPermission,dataGrid,dataGridTotal,loading,QuantityDecimals} = this.state
        return (
            <div className="page-container" style={{margin: 0}}>
                {Config && Config.profile &&
                    <EventTracking
                        category={"TRACKING_USER"} 
                        action={"W17F2025"} 
                        label={Config.profile.UserID} 
                    />
                }
                 {loading && <Loading/>}
                <div 
                    className="grid-title" 
                    style={{ marginLeft: '10px' }}
                >
                    {Config.lang("CRM_Cap_nhat_ghi_nhan_nhu_cau_khach_hang")}
                </div>
                <Form horizontal={true} id={'frmW17F1026'} style={{margin: '0 15px'}}>
                    {/* toolbar */}
                    { this.renderToolbar(locationState)}
                    { this.renderFormMaster(locationState)}
                    
                    <ButtonAdd className={`mgl5 ${iPermission >=2  && mode !== "view" ? "" : "isDisabled"} `}
                            name={Config.lang("CRM_Them")} onClick={() => {
                                if (iPermission >=2 && mode !== "view") {
                                  this.onClickAddNewGridItem()
                                }
                            }}
                        />
                   
                    <GridContainer
                    reference = {ref => this.gridContainer = ref}
                    showBorders={true}
                    isPer={this.state.iPermission}                     
                    formID={'W17F2025'}
                    dataSource={dataGrid}
                
                    totalItems={dataGridTotal}
                    listPerPage={[15, 20, 30, 50]}
                    itemPerPage={this.filter.limit}
                    onRowInserted={(e) =>this.onChangeNumber(e,"Quantity")}    
                    onRowUpdating={(e) =>this.onChangeNumber(e,"Quantity","update")}                    
                    onChangePage={(page) => {
                        this.onChangePage(page)
                    }}
                    onChangePerPage={(per) => {
                        this.onChangePerPage(per)
                    }}
                    onInitNewRow={(eMethod) => {
                        eMethod.data.ProductDescription = "";
                       
                    }}
                    filterRow={{
                        visible: true,
                        applyFilter: 'auto'
                    }}
                    editing={{
                        mode: "cell",
                        refreshMode: "reshape",
                        allowUpdating: mode !== "view",
                        texts: { confirmDeleteMessage: "" },
                        startEditAction: "click",
                    }}
                    >
                    <Column
                        width={50}
                        alignment={"center"}
                        allowEditing={false}
                        cellRender={(data) => data.rowIndex + 1}
                    />
                    <Column
                        
                        dataField={'ProductDescription'}
                        caption={Config.lang("CRM_Mo_ta_san_pham")}
                        dataType ="string"
                    />
                    <Column
                        
                        dataField={'Quantity'}
                        caption={Config.lang("CRM_So_luong")}
                        dataType = "number"
                        width={120}
                        alignment = "center"
                        cellRender ={e =>{
                            return <NumberFormat
                                        displayType = "text"
                                        thousandSeparator={true}
                                        value={e.value}
                                    />
                        }}
                        editCellRender = {(e) => {
                            return (
                                <NumberFormat
                                    style={{ width: '100%',
                                        backgroundColor: 'transparent',
                                        border: 'none',
                                        margin: 0,
                                        padding: 7 
                                    }}
                                    decimalScale={QuantityDecimals}
                                    thousandSeparator={true}
                                    isNumericString={true}
                                    value={e.value}
                                    onValueChange={(c) => {
                                       
                                        e.setValue(_.toNumber(c.value))
                                    }}
                                />
                            )
                        }}
                    />
                     <Column
                        cellRender={(data) => this.renderAction(data)}
                       
                        caption={Config.lang("CRM_Hanh_dong")}
                        alignment={'center'}
                        allowSorting={false}
                        showInColumnChooser={false}
                        width={120}
                    />
                   
                    
                </GridContainer>
                </Form>
            </div>
        )
    }

}
export default connect(state => ({
    cboStatus: state.w17f2025.cboStatus,
    cboCaseSource: state.w17f2025.cboCaseSource,
    cboSalesPerson: state.w17f2025.cboSalesPerson,
    getForm: state.w17f2025.getForm,
    cboCollaborator: state.w17f2025.cboCollaborator,

}),
(dispatch) => ({
   
    W17F2025Actions: bindActionCreators(W17F2025Actions, dispatch),
    userActions: bindActionCreators(userActions, dispatch),
})
)(W17F2025Page);

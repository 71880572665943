/**
 * @copyright 2021 @ DigiNet
 * @author ANHTAI
 * @create 20/01/2021
 * @update 04/03/2021
 * @file src/components/header/header.js
 */

import withStyles from "@material-ui/core/styles/withStyles";
import { Column } from "devextreme-react/data-grid";
import _ from "lodash";
import PropTypes from "prop-types";
import React from "react";
import { FormGroup } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import * as w17f2030Actions from "../../actions/w17/w17F2030/w17F2030-actions";
import Config from "../../config";
import ButtonBasic from "../common/button/button-basic";
import GridContainer from "../common/grid-container/grid-container";
import PopupScroll from "../popup/popup-scroll";

const styles = {
    circleBtn: {
        borderRadius: '100%',
        minWidth: 0
    },
    searchTitle: {
        fontWeight: 'bold',
        textTransform: 'uppercase',
        color: '#222B45',
        fontSize: '12px',
        marginTop: 17
    }
};
class W17F2030 extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            modalLoaded: false,
            dataGrid: [],
            totalDataGrid: 0,
            selectedRowKeys: [],
            selectedRowData: []
        };
        this.tmpSelectedRowData = [];
        this.tmpSelectedRowKeys = [];
        this.changePage = false;
        this.filter = {
            Language: Config.language || "84",
            FormID: "W17F2000",
            Type: "AddQuotation",
            DataType: "GridInventoryID",
            DivisionID: "",
            ObjectID: "",
            PriceListID: "",
            CurrencyID: "",
            TranTypeID: "",
            IsQuotationNoneCode: 0,
            limit: 10,
            skip: 0,
            search: ""
        }
    }

    componentDidMount = async () => {
        const { divisionID, priceListID, objectID, currencyID, tranTypeID, paymentTermID, paymentMethodID, isQuotationNoneCode } = this.props;
        this.filter.DivisionID = divisionID;
        this.filter.PriceListID = priceListID;
        this.filter.ObjectID = objectID;
        this.filter.CurrencyID = currencyID;
        this.filter.TranTypeID = tranTypeID;
        this.filter.PaymentTermID = paymentTermID;
        this.filter.PaymentMethodID = paymentMethodID;
        this.filter.IsQuotationNoneCode = isQuotationNoneCode;
        this.loadGrid(true);
    };

    componentWillUnmount = () => {
        this.setState = (state, callback) => {
            return;
        };
    }



    loadGrid = (isFirstLoad = false) => {
        if (!isFirstLoad) {
            this.changePage = true;
        };
        const { search, limit, skip, DivisionID, Language, Type, DataType, ObjectID, PriceListID, CurrencyID,
                  TranTypeID, FormID, PaymentTermID, PaymentMethodID, IsQuotationNoneCode
        } = this.filter;
        const params = {
            FormID,
            Type,
            DivisionID,
            Language,
            DataType,
            ObjectID,
            PriceListID,
            CurrencyID,
            TranTypeID,
            PaymentTermID,
            PaymentMethodID,
            IsQuotationNoneCode,
            skip,
            limit,
            search
        };
        this.setState({ modalLoaded: true });
        this.props.w17f2030Actions.loadDataInventory(params, (errors, data) => {
            if (errors) {
                this.setState({ modalLoaded: false })
                let message = errors.message || Config.lang("CRM_Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            }
            else if (data && data.rows) {
                if (this.props.selectedRow && isFirstLoad) this.tmpSelectedRowKeys = this.props.selectedRow;
                this.setState({
                    modalLoaded: false,
                    dataGrid: data.rows ? data.rows : data,
                    totalDataGrid: data.total
                });
            }
        });
    };

    onChangePage = (page) => {
        this.filter.skip = page * this.filter.limit;
        this.loadGrid();
    };

    onChangePerPage = (per) => {
        this.filter.skip = 0;
        this.filter.limit = per;
        this.loadGrid();
    };

    handleChecked = (currentSelectedRowKeys, keyExpr, mode) => {
        currentSelectedRowKeys.forEach((val) => {
            if (this.tmpSelectedRowKeys.indexOf(val[keyExpr]) < 0) {
                this.tmpSelectedRowData.push(val);
                this.tmpSelectedRowKeys.push(val[keyExpr]);
            }
        });
    }

    handleUnChecked = (currentDeselectedRowKeys, keyExpr) => {
        this.tmpSelectedRowData = this.tmpSelectedRowData.filter((e) => {
            return currentDeselectedRowKeys.indexOf(e[keyExpr]) < 0;
        });
        this.tmpSelectedRowKeys = this.tmpSelectedRowKeys.filter((e) => {
            return currentDeselectedRowKeys.indexOf(e) < 0;
        });
    }

    onSelectionChanged = (e) => { // Fix bug chuyển trang mấy check lần đầu tiên
        const keyExpr = "InventoryID";
        let currentSelectedRowKeys = e.selectedRowsData;
        const currentDeselectedRowKeys = e.currentDeselectedRowKeys;
        if (this.changePage) {
            if (currentSelectedRowKeys.length > 0) {
                this.handleChecked(currentSelectedRowKeys, keyExpr);
            }
            this.changePage = false;
        } else {
            if (currentDeselectedRowKeys.length > 0) {// UnSelect
                this.handleUnChecked(currentDeselectedRowKeys, keyExpr);
            }
            else if (currentSelectedRowKeys.length > 0) {
                this.handleChecked(currentSelectedRowKeys, keyExpr);
            }
        }
        this.setState({ selectedRowData: [...this.tmpSelectedRowData] });
    }

    reLoadGrid = () => {
        const { isW17F2040, divisionID, paymentTermID, paymentMethodID } = this.props;
        let reloadApiName = "reLoadGrid";
        if (isW17F2040) reloadApiName = "reLoadGridW17F2040";
        const params = {
            DivisionID: divisionID,
            Language: this.filter.Language,
            PaymentTermID: paymentTermID || "",
            PaymentMethodID: paymentMethodID || ""
        };
        this.props.w17f2030Actions[reloadApiName](params, (errors, data) => {
            this.setState({ modalLoaded: false });
            if (errors) {
                let message = errors.message || Config.lang("CRM_Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            } else if (data) {
                this.handleSaveAndClose(data);
            }
        });
    }

    handleSaveAndClose = (data) => {
        const dataGridPlus = [...this.props.dataGridMaster, ...data];
        this.tmpSelectedRowKeys = [...new Set(this.tmpSelectedRowKeys)]; // Những thằng check hiện tại
        if (this.props.getAll) {
            const dataF = dataGridPlus.filter(rs => {
                return (rs.IsSelect === undefined && this.tmpSelectedRowKeys.find(item => item === rs.InventoryID)) || rs.__KEY__ !== undefined || !rs.InventoryID;
            });
            if (this.props.onClose) this.props.onClose(dataF, this.tmpSelectedRowKeys);
        } else {
            let unSelectKey = []; // Những thằng bị bỏ check
            let removeUnSelectKeyData = [];
            // Đã thâm vô rồi nhé nhưng mà những thằng mình bỏ check thì sao cậu
            const dataGridMasterKey = dataGridPlus.map(item => item.InventoryID);
            const allTmpSelectedRowKeys = [...new Set(dataGridMasterKey.concat(this.tmpSelectedRowKeys))]; // All tất cả các key
            allTmpSelectedRowKeys.forEach(item => { // Những thằng bỏ check
                if (this.tmpSelectedRowKeys.indexOf(item) < 0) {
                    unSelectKey.push(item);
                }
            })
            if (!_.isEmpty(unSelectKey)) {
                removeUnSelectKeyData = dataGridPlus.filter((obj) => this.tmpSelectedRowKeys.some((InventoryID) => obj.InventoryID === InventoryID));
            } else {
                removeUnSelectKeyData = dataGridPlus;
            }
            const uniqueSelectKeyData = _.uniqBy(removeUnSelectKeyData, e => e.InventoryID); // KIểm tra lần nữa loại bỏ trùng
            if (this.props.onClose) this.props.onClose(uniqueSelectKeyData, this.tmpSelectedRowKeys);
        }
    }

    onChoseIventory = () => {
        const { selectedRowData } = this.state;
        const { divisionID, tranTypeID, priceListID, objectID, currencyID, dataGridMaster } = this.props;
        // Loại bỏ những thằng đã có ở lưới hàng hóa
        const selectedRowDataUnique = selectedRowData.filter((obj) => !dataGridMaster.some((obj2) => obj.InventoryID === obj2.InventoryID));
        if (!_.isEmpty(selectedRowDataUnique)) {
            selectedRowDataUnique.forEach(item => {
                item["Type"] = this.filter.Type;
                item["DataType"] = this.filter.DataType;
                item["ObjectID"] = objectID;
                item["DivisionID"] = divisionID;
                item["CurrencyID"] = currencyID;
                item["TranTypeID"] = tranTypeID;
                item["PriceListID"] = priceListID;
            })
            const params = {
                FormID: this.props.formID,
                data: JSON.stringify(selectedRowDataUnique)
            };
            this.setState({ modalLoaded: true })
            this.props.w17f2030Actions.saveW17F2030(params, (errors, data) => {
                if (errors) {
                    this.setState({ modalLoaded: false })
                    const message = errors.message || Config.lang("CRM_Loi_chua_xac_dinh");
                    Config.popup.show("INFO", message);
                    return false;
                } else if (!_.isEmpty(data)) {
                    if (data.Status === 0) {  //Lưu thành công chạy api để lấy data vừa lưu ra
                        this.reLoadGrid();
                    } else {
                        let messageSave = (data.Message) || Config.lang("CRM_Loi_chua_xac_dinh");
                        Config.popup.show("INFO", messageSave);
                    }
                }
            });
        } else {
            this.handleSaveAndClose(selectedRowData);
        }
    }

    onHide = () => {
        if (this.props.onClose) this.props.onClose();
    }

    onSearch = (e) => {
        this.filter.search = e;
        this.loadGrid();
    };

    render() {
        const { listPerPage, mode = "multiple" } = this.props;
        const { dataGrid, totalDataGrid, modalLoaded } = this.state;
        return (
            <PopupScroll
                show={true}
                animation={true}
                className={"popup-size-80"}
                title={Config.lang("CRM_Thong_tin_hang_hoa")}
                onHide={this.onHide}
                button={
                    <ButtonBasic
                        style={{ margin: '10px 10px 10px 20px' }}
                        onClick={() => this.onChoseIventory()}
                        disabled={_.isEmpty(this.tmpSelectedRowKeys)}> {Config.lang("CRM_Chon")}
                    </ButtonBasic>
                }>
                <div className={"page-container"}
                    id={"frmW17F2030"}>
                    <FormGroup>
                        <GridContainer
                            totalItems={totalDataGrid}
                            itemPerPage={this.filter.limit}
                            skipPerPage={this.filter.skip}
                            dataSource={dataGrid}
                            loading={modalLoaded}
                            keyExpr={"InventoryID"}
                            gridProps={{
                                style: { maxHeight: 420 }
                            }}
                            pagerFullScreen={false}
                            showBorders={false}
                            columnAutoWidth={true}
                            typeShort={window.innerWidth < 768}
                            height={"calc(100vh - 180px)"}
                            selection={{
                                allowSelectAll: mode === "multiple",
                                mode: "multiple",
                                selectAllMode: "",
                                showCheckBoxesMode: "always"
                            }}
                            onSearch={this.onSearch}
                            allowColumnResizing={true}
                            listPerPage={listPerPage}
                            selectedRowKey={this.tmpSelectedRowKeys}
                            onChangePage={this.onChangePage}
                            onChangePerPage={this.onChangePerPage}
                            onSelectionChanged={(e) => this.onSelectionChanged(e)}
                        >
                            <Column
                                width={200}
                                dataField={"InventoryID"}
                                caption={Config.lang("CRM_Ma_hang")}
                                allowEditing={false} />
                            <Column
                                width={200}
                                dataField={"InventoryName"}
                                caption={Config.lang("CRM_Ten_hang_hoa")}
                                allowEditing={false} />
                            <Column
                                width={150}
                                dataField={"TypeInventoryID"}
                                caption={Config.lang("CRM_Loai_hang_hoa")}
                                allowEditing={false} />
                            <Column
                                width={150}
                                dataField={"UnitID"}
                                caption={Config.lang("CRM_DVT")}
                                allowEditing={false} />
                            <Column
                                width={150}
                                dataField={"UnitPriceOnD14"}
                                caption={Config.lang("CRM_DG_goc")}
                                allowEditing={false}
                            />
                             <Column
                                width={150}
                                dataField={"DefaultOQuantityMOQ"}
                                caption={Config.lang("So_luong_toi_thieu")}
                                allowEditing={false}
                            />
                             <Column
                                width={150}
                                dataField={"ShipDateNumIn"}
                                caption={Config.lang("Thoi_gian_giao_hang")}
                                allowEditing={false}
                            />
                            {/* <Column
                                  width={150}
                                  dataField={"Quantity"}
                                  caption={Config.lang("CRM_So_luong")}
                                  dataType={"number"}
                              /> */}
                        </GridContainer>
                    </FormGroup>
                </div>
            </PopupScroll>
        )
    }
}

W17F2030.propTypes = {
    openModal: PropTypes.bool,
    maxWidth: PropTypes.string,
    formID: PropTypes.string,
    objectID: PropTypes.string,
    divisionID: PropTypes.string,
    priceListID: PropTypes.string,
    currencyID: PropTypes.string,
    tranTypeID: PropTypes.string,
    isQuotationNoneCode: PropTypes.number,
    selectedRow: PropTypes.array,
    dataGridMaster: PropTypes.array,
    onClose: PropTypes.func
};

export default compose(connect((state) => ({
}), (dispatch) => ({
    w17f2030Actions: bindActionCreators(w17f2030Actions, dispatch),
})), withStyles(styles))(W17F2030);

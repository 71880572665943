/**
 * @copyright 2019 @ DigiNet
 * @author rocachien
 * @create 2019/01/11 10:37
 * @update 2019/01/11 10:37
 * @file src/components/header/header.js
 */

import React from 'react';
import {connect} from "react-redux";
import {browserHistory} from "react-router";
import {bindActionCreators} from "redux";
import moment from "moment"
import * as W17F2050Actions from "../../../actions/w17/W17F2050/W17F2050-actions";
import * as userActions from "../../../actions/user-actions";
import {Column} from 'devextreme-react/data-grid';
import {Loading} from "../../common/loading/loading";
import GridContainer from "../../common/grid-container/grid-container";
import Config from "../../../config";
import GridActionBar from "../../common/action-bar/grid-action-bar";
import EventTracking from "../../common/tracking/event";
import notify from 'devextreme/ui/notify';
import PageContainer from "../../common/page-container/page-container";
import Link from '../../common/link/link';

class W17F2050Page extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            iPermission: 0,
        };

        this.filter = {
            skip: 0,
            limit: 15,
            StrSearch: '',
        };
    }

    async loadPermission() {
        await this.props.userActions.getPermission(Config.profile.UserID, "W17F2050", (iPer)=>{
            this.setState({iPermission: iPer});
        });
    }


    componentDidMount = async () => {
        await this.loadPermission();
        if (this.state && this.state.iPermission <= 0) return;
       
        this.loadGrid()
        
        
    };

   

    loadGrid = (cb) => {
        const { 
            StrSearch,
            skip,
            limit,} = this.filter
        const params = {
           
            Language: Config.language || '84',
            StrSearch,
            skip,
            limit,
        };
        this.setState({loading: true});

        this.props.W17F2050Actions.loadGrid(params, (error, data) => {
            this.setState({loading: false});
            if (error) {
                let message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                
                return false;
            }
           
        });
    }

    onDelete = (e) => {
        const {ObjectID} = (e && e.row && e.row.data) || {}
        const params = {
            Language: Config.language || "84",
            CodeID:ObjectID || ""
        }
        this.setState({ loading: true});
        
        this.props.W17F2050Actions.deleteGrid(params, (error,data) => {
            if (error || !data) {
                let message = (error && error.message) || Config.lang("CRM_Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                this.setState({ loading: false});
                return false;
            } 
            if (data.Status === 0) {
                notify(Config.lang("CRM_Du_lieu_da_xoa_thanh_cong"), "success", 2000);
                this.loadGrid();
                return false
            }
            Config.popup.show("INFO", data.Message);
            this.setState({loading:false})
        });
        
    }

    
    onEdit(e) {

        const {ObjectID} = (e && e.row && e.row.data) || {}
       
        const params = {
            Language: Config.language || "84",
            CodeID:ObjectID || ""
        }
        this.setState({loading: true});
        
        this.props.W17F2050Actions.checkEdit(params, (error,data) => {
       
            if (error || !data) {
                let message =( error && error.message) || Config.lang("CRM_Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                this.setState({loading: false});
                return false;
            } 
            if (data.Status === 0) {
                return this.onRedirect(Config.getRootPath() + 'W17F2051',{mode: 'edit',ObjectID:params.CodeID,})
            }
            if (data.Status === 1) {
                if (data.MsgAsk === 0) {
                    this.setState({ loading: false});
                    Config.popup.show("INFO", data.Message);
                    return 
                }
                this.setState({ loading: false});
                Config.popup.show("YES_NO", Config.lang("CRM_Ban_co_chac_chan_muon_sua_du_lieu_nay"), () => {
                    this.onRedirect(Config.getRootPath() + 'W17F2051',{mode: 'edit',ObjectID:params.CodeID,})
                },()=>{
                    Config.popup.show("INFO", data.Message)
                });
                
                
                
            }
            
        });
 
    }

 
    onRedirect =(pathname,state ={},method = "push") =>{
        const formCallState = (this.props.location && this.props.location.state) || {}
        state.formCallState = formCallState
        browserHistory[method] && browserHistory[method]({pathname,state})    
    }
    onAddNew() {
        this.onRedirect(Config.getRootPath() + 'W17F2051',{mode:"add"})
    }



    
    onChangePage(page) {
        this.filter.skip = page * this.filter.limit;
        this.loadGrid();
    }

    onChangePerPage(per) {
        this.filter.skip = 0;
        this.filter.limit = per;
        this.loadGrid();
    }

    onSearch(text) {
        this.filter.skip = 0;
        this.filter.StrSearch = text || "";
        this.loadGrid();
    }
    renderAction(e) {
        const {iPermission} = this.state
        return (
            <GridActionBar
                isPer={iPermission}
                tooltipEdit={"Sửa"}
                className={iPermission <= 2 ? 'isDisabled' : ""}
                classNameIs={iPermission}
                onEditAction={() => {
                    this.onEdit(e)
                }}
                tooltipDelete={Config.lang("CRM_Xoa")}
                onDeleteAction={() => {
                    this.onDelete(e)
                }}
               
            />
        );
    }

    render() {
        if (this.state.iPermission <= 0) return null;
        const {dataGrid ={}} = this.props
        // dataGrid.rows =[{
        //     ObjectID:"ObjectID",
        //     ObjectNameU:"ObjectNameU",
        //     RefInformationU:"RefInformationU",
        //     BirthDate:new Date(),
        //     Gender:"Gender",
        //     IDCardNo:"IDCardNo",
        //     IDCardIssueDate:new Date(),
        //     IDCardIssuePlace:"IDCardIssuePlace",
        //     TelNo:"TelNo",
        //     Email:"Email",
        //     AddressLine1U:"AddressLine1U",
        //     ObjectAddressU:"ObjectAddressU",
        //     BankAccountNo:"BankAccountNo",
        //     BankBranchNameU:"BankBranchNameU",
        // }]
        return (
            <PageContainer>
                {Config && Config.profile &&
                <EventTracking category={"TRACKING_USER"} action={"W17F2050"} label={Config.profile.UserID}/>}
                {dataGrid && <GridContainer
                    title={Config.lang("CRM_Danh_sach_cong_tac_vien")}
                    isPer={this.state.iPermission}
                    formID={'W17F2050'}
                    dataSource={dataGrid.rows}
                    totalItems={dataGrid.total}
                    listPerPage={[15, 20, 30, 50]}
                    itemPerPage={this.filter.limit}
                    columnResizingMode={'widget'}
                  
                    onAddNew={() => this.onAddNew()}
                    onSearch={(text) => {
                        this.onSearch(text)
                    }}
                    onChangePage={(page) => {
                        this.onChangePage(page)
                    }}
                    onChangePerPage={(per) => {
                        this.onChangePerPage(per)
                    }}>
                    <Column
                        cellRender={(data) => this.renderAction(data)}
                        dataField={'CompanyID'}
                        caption={Config.lang("CRM_Hanh_dong")}
                        alignment={'center'}
                        allowSorting={false}
                        showInColumnChooser={false}
                        width={120}
                        fixed={true}
                    />
                    <Column
                        dataField={'ObjectID'}
                        caption={Config.lang("CRM_Ma")}
                        sortOrder={'asc'}
                        minWidth={150}
                        cellRender ={e =>{
                            
                            return <Link 
                                getData = {()=> ({
                                    pathname: Config.getRootPath() + 'W17F2051',
                                    state: {mode: 'view',ObjectID:e.value,
                                    }
                                })}
                                 > {e.value || ""} </Link>
                        }}
                    />
                    <Column
                        dataField={'ObjectNameU'}
                        caption={Config.lang("CRM_Ten")}
                        minWidth={150}
                    />
                    <Column
                        dataField={'RefInformationU'}
                        caption={Config.lang("CRM_Ten_tat")}
                        minWidth={150}
                    />
                    <Column
                        cellRender={(e) => {
                            let date = e.value ? moment(e.value).format("DD/MM/YYYY") : '';
                            return date
                        }}
                        dataField={'BirthDate'}
                        caption={Config.lang("CRM_Ngay_sinh")}
                        minWidth={150}
                        dataType ="Date"
                    />
                    <Column
                        dataField={'Gender'}
                        caption={Config.lang("CRM_Gioi_tinh")}
                        minWidth={150}
                    />
                    <Column
                        dataField={'IDCardNo'}
                        caption={Config.lang("CRM_So_CCCD_/_CMND")}
                        minWidth={150}
                    />
                    <Column
                        dataField={'IDCardIssueDate'}
                        caption={Config.lang("CRM_Ngay_cap")}
                        minWidth={150}
                        dataType ="Date"
                        cellRender={(e) => {
                            let date = e.value ? moment(e.value).format("DD/MM/YYYY") : '';
                            return date
                        }}
                    />
                    <Column
                        dataField={'IDCardIssuePlace'}
                        caption={Config.lang("CRM_Noi_cap")}
                        minWidth={150}
                    />
                    <Column
                        dataField={'TelNo'}
                        caption={Config.lang("CRM_So_dien_thoai")}
                        minWidth={150}
                    />
                    <Column
                        dataField={'Email'}
                        caption={Config.lang("CRM_Email")}
                        minWidth={150}
                    />
                    <Column
                        dataField={'AddressLine1U'}
                        caption={Config.lang("CRM_Dia_chi_thuong_tru")}
                        minWidth={150}
                    />
                    <Column
                        dataField={'BankAccountNo'}
                        caption={Config.lang("CRM_So_tai_khoan")}
                        minWidth={150}
                    />
                    <Column
                        dataField={'BankBranchNameU'}
                        caption={Config.lang("CRM_Chi_nhanh_ngan_hang")}
                        minWidth={150}
                    />
                    
                    
                </GridContainer>}
                {this.state.loading && <Loading/>}
            </PageContainer>
        )
    }
}

export default connect(state => ({
        dataGrid: state.w17f2050.loadGrid,
    }),
    (dispatch) => ({
        W17F2050Actions: bindActionCreators(W17F2050Actions, dispatch),
        userActions: bindActionCreators(userActions, dispatch)
    })
)(W17F2050Page);

/**
 * @copyright 2019 @ DigiNet
 * @author kimlong
 * @create 2019/01/11 10:37
 * @update 2019/01/11 10:37
 * @file src/components/header/header.js
 */

import React from 'react';
import {FormGroup, Row, Col} from 'react-bootstrap';
import notify from 'devextreme/ui/notify';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import ButtonSave from "../common/button/button-save";
import ButtonNotSave from "../common/button/button-notsave";
import Config from '../../config/index';
import {
    Validator,
    RequiredRule
} from 'devextreme-react/validator';
import {
    Button,
    CheckBox,
    TextArea,
    TextBox,
    NumberBox
} from 'devextreme-react';
import PropTypes from 'prop-types';
import EventTracking from "../common/tracking/event";
import * as w17Actions from "../../actions/w17-actions";

const cssForm = {margin: '0 15px 0 15px'};

class W17F1556Page extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            listTypeID: this.props.data.listTypeID,
            codeID: this.props.data.codeID,
            codeName: this.props.data.codeName,
            remark: this.props.data.remark,
            displayOrder: this.props.data.displayOrder,
            isDefault: this.props.data.isDefault,
            disabled: this.props.data.disabled,
            formState: this.props.formState,
            isSubmit: false
        };
        this.timer = null;
        this.timeDeplay = Config.getSetting("TIME_SUBMIT_DELAY") || 3000;
    };

    componentWillUnmount() {
        if (this.timer) clearTimeout(this.timer);
    }

    onEventSave() {
        this.onFormSubmit();
    }

    onFormSubmit(e) {
        // e.preventDefault();
        if (this.state.formState === 'add') {
            const uName = Config.profile.UserID || '';
            const uLanguage = Config.language || '';
            const query = {
                "Language": uLanguage,
                "UserID": uName,
                "FormID": 'W17F1555',
                "Key01ID": this.state.listTypeID,
                "Key02ID": this.state.codeID,
            };
            this.props.w17Actions.checkAddW17F1556(query, (error, data) => {
                let message = "";
                if (data) {
                    const result = data[0];
                    if (result !== null) {
                        let status = result.Status;
                        let msgAsk = result.MsgAsk;
                        message = result.Message;
                        if (status === 0) {
                            this.excuteSave();
                        } else {
                            if (msgAsk === 0) {
                                Config.popup.show('INFO', message);
                            } else {
                                Config.popup.show("YES_NO", message,
                                    () => {
                                        this.excuteSave();
                                    }
                                );
                            }
                        }
                    } else {
                        Config.popup.show('INFO', Config.lang("CRM_Store_kiem_tra_bi_loi"));
                    }
                } else {
                    message = Config.lang("CRM_Loi_chua_xac_dinh");
                    Config.popup.show('INFO', message);
                }
            });
        } else {
            this.excuteSave();
        }
    }

    excuteSave() {
        const formState = this.state.formState === 'add' ? "0" : "1";
        const listTypeID = this.state.listTypeID;
        const codeID = this.state.codeID;
        const codeName = this.state.codeName;
        const remark = this.state.remark;
        const displayOrder = this.state.displayOrder;
        const disabled = this.state.disabled === false ? 0 : 1;
        const isDefault = this.state.isDefault === false ? 0 : 1;
        const param = {
            "mode": formState,
            "listTypeID": listTypeID,
            "codeID": codeID,
            "codeName": codeName,
            "remark": remark,
            "displayOrder": displayOrder,
            "isDefault": isDefault,
            "disabled": disabled,
        };
        this.props.w17Actions.actionW17F1556(param, (error, data) => {
            if (error) {
                let message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            }

            if (data && data.code === 200) {
                // Show message success and close popup
                this.setState({isSubmit: false});
                notify(Config.lang("CRM_Luu_thanh_cong"), "success", 2000);
                this.props.onReloadGrid();
                // Auto close when nothing to do
                clearTimeout(this.timer);
                this.timer = setTimeout(() => {
                    this.setState({isSubmit: false});
                    this.props.onHide();
                }, this.timeDeplay);
            }
        });
    }

    render() {
        return (
            <form style={cssForm} onSubmit={(e) => this.onFormSubmit(e)}>
                {Config && Config.profile &&
                <EventTracking category={"TRACKING_USER"} action={"W17F1556"} label={Config.profile.UserID}/>}

                <FormGroup>
                    <Row>
                        <Col sm={8}>
                            <label className={'control-label-above'}>{Config.lang("CRM_Ma")} <span
                                style={{color: 'red'}}><b>*</b></span></label>
                            <TextBox name={"CodeID"}
                                     value={this.state.codeID}
                                     disabled={this.state.formState === 'edit'}
                                     onChange={(e) => this.onTextValueChanged(e)}
                                     className="input-devextreme"
                                     maxLength={20}
                            >
                                <Validator>
                                    <RequiredRule message={Config.lang("CRM_Gia_tri_bat_buoc_nhap")}/>
                                </Validator>
                            </TextBox>
                        </Col>
                        <Col sm={4}>
                            <label className={'control-label-above'}>{Config.lang("CRM_Thu_tu_hien_thi")} </label>
                            <NumberBox
                                id={"DisplayOrder"}
                                name={"DisplayOrder"}
                                value={this.state.displayOrder}
                                min={0}
                                showSpinButtons={false}
                                onValueChanged={(e) => this.onDisabledChanged(e)}
                                className="input-devextreme"
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col sm={12}>
                            <label className={'control-label-above'}>{Config.lang("CRM_Ten")} <span
                                style={{color: 'red'}}><b>*</b></span></label>
                            <TextBox name={"CodeName"}
                                     value={this.state.codeName}
                                     onChange={(e) => this.onTextValueChanged(e)}
                                     className="input-devextreme">
                                <Validator>
                                    <RequiredRule message={Config.lang("CRM_Gia_tri_bat_buoc_nhap")}/>
                                </Validator>
                            </TextBox>
                        </Col>
                    </Row>

                    <Row>
                        <Col sm={12}>
                            <label className={'control-label-above'}>{Config.lang("CRM_Ghi_chu")}</label>
                            <TextArea name={"Remark"}
                                      height={90}
                                      value={this.state.remark}
                                      text={this.state.remark}
                                      maxLength={this.state.remark.maxLength}
                                // valueChangeEvent={this.state.remark}
                                      onChange={(e) => this.onTextValueChanged(e)}
                                      onPaste={(e) => this.onTextValueChanged(e)}
                                      className="input-devextreme"
                            ></TextArea>
                        </Col>
                    </Row>
                </FormGroup>

                <FormGroup className={"jus-between"}>
                    <div>
                        <CheckBox
                            width={200}
                            text={Config.lang("CRM_Ngung_hoat_dong")}
                            name={"Disabled"}
                            id={"Disabled"}
                            disabled={this.state.formState === 'add'}
                            onValueChanged={(e) => this.onDisabledChanged(e)}
                            value={!this.state.disabled || this.state.disabled === '0' ? false : true}
                        />
                        <CheckBox
                            width={200}
                            text={Config.lang("CRM_Mac_dinh")}
                            name={"IsDefault"}
                            id={"IsDefault"}
                            onValueChanged={(e) => this.onDisabledChanged(e)}
                            value={!this.state.isDefault || this.state.isDefault === '0' ? false : true}
                        />
                    </div>
                    <div>

                        <ButtonSave onClick={() => this.onEventSave()} className={"mgr5"} name={Config.lang("CRM_Luu")}/>
                        <ButtonNotSave name={Config.lang("CRM_Khong_luu")} onClick={() => {this.props.onHide()}}/>
                        <Button id="btnSubmit" useSubmitBehavior={true} className="hide"/>
                    </div>
                </FormGroup>


            </form>

        )
    }

    onTextValueChanged(e) {
        // console.log('============onTextValueChanged===========', e);
        switch (e.event.target.name) {
            case "CodeID":
                let str = Config.replaceSymbol(e.event.target.value);
                this.setState({codeID: str});
                break;
            case "CodeName":
                this.setState({codeName: e.event.target.value});
                break;
            case "Remark":
                this.setState({remark: e.event.target.value});
                break;
            default:
                break;
        }
    }


    onDisabledChanged(e) {
        // console.log(e);
        switch (e.element.id) {
            case "Disabled":
                if (this.state.isDefault !== true)
                    this.setState({disabled: e.value});
                else
                    this.setState({disabled: e.value, isDefault: !e.value});
                break;
            case "IsDefault":
                if (this.state.disabled !== true)
                    this.setState({isDefault: e.value});
                else
                    this.setState({disabled: !e.value, isDefault: e.value});
                break;
            case "DisplayOrder":
                this.setState({displayOrder: e.value});
                break;
            default:
                break;
        }

    }
}

W17F1556Page.propTypes = {

    onHide: PropTypes.func,
    onReloadGrid: PropTypes.func
};


export default connect(state => ({
        actionW17F1556: state.w17.actionW17F1556,
        checkStore: state.w17.checkStore
    }),
    (dispatch) => ({
        w17Actions: bindActionCreators(w17Actions, dispatch)
    })
)(W17F1556Page);

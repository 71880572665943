/**
 * @copyright 2019 @ DigiNet
 * @author kimlong
 * @create 2019/01/11 10:37
 * @update 2019/01/11 10:37
 * @file src/components/header/header.js
 */

import React from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";

import Config from '../../config/index';
import * as w17Actions from "../../actions/w17-actions";
import * as userActions from "../../actions/user-actions";
import Detail from "../W17/W17F1556";

import {FormGroup, Row, Col} from 'react-bootstrap';

import GridContainer from "../common/grid-container/grid-container";
import Combo from "../common/dropdown/combo";
import {Column} from 'devextreme-react/data-grid';
import PopupView from "../popup/popup-view";
import EventTracking from "../common/tracking/event";
import GridActionBar from "../common/action-bar/grid-action-bar";
import notify from "devextreme/ui/notify";


const cssForm = {marginTop: '10px'};

class W17F1555Page extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            iPermission: 0,
            showPopup: false,
            formState: 'view',
            rows: [],
            row: {
                listTypeID: '',
                codeID: '',
                codeName: '',
                remark: '',
                displayOrder: 0,
                isDefault: false,
                disabled: false,
                totalRecord: 0
            },
            listTypeID:''
        };
        this.ComboType = [];
        // this.listTypeID = '';
        this.totals = 0;
        this.dataGrid = [];
        this.timer = null;
        this.filter = {
            skip: 0,
            limit: 20,
            search: ''
        };
    };

    componentDidMount = async () => {
        await this.loadPermission();
        if(this.state.iPermission <= 0) return;
        this.loadCombo();
    };

    async loadPermission() {
        await this.props.userActions.getPermission(Config.profile.UserID, "D17F1555",(iPer)=>{
            this.setState({iPermission: iPer});
        });
    }

    loadCombo() {
        const uLanguage = Config.language || '84';
        let param = {
            "language": uLanguage
        };
        this.props.w17Actions.loadW17F1555_Combo(param, (error, data) => {
            if (error) {
                let message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            } else if (data && data.data && data.data.length > 0) {
                this.ComboType = data.data.map(o => {
                    const item = {
                        ListTypeID: o.ListTypeID
                        , ListTypeName: o.ListTypeName
                    };
                    return item;
                });
                // this.listTypeID = data.data[0].ListTypeID;
                this.setState({
                    listTypeID:data.data[0].ListTypeID
                });
                this.loadGrid();
            }

        });

    }

    loadGrid() {
        const param = {
            "listTypeID": this.state.listTypeID,
            "limit": this.filter.limit,
            "skip": this.filter.skip,
            "search": this.filter.search
        };
        this.props.w17Actions.loadW17F1555(param, (error, data) => {
            if (error) {
                let message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            }
            else if (data && data.data && data.data.length > 0) {
                this.dataGrid = data.data.map(o => {
                    const item = {
                        ListTypeID: o.ListTypeID
                        , CodeID: o.CodeID
                        , CodeName: o.CodeName
                        , Remark: o.Remark
                        , DisplayOrder: o.DisplayOrder
                        , IsDefault: o.IsDefault === 0 ? false : true
                        , Disabled: o.Disabled === 0 ? false : true
                    };
                    return item;
                });
                this.totals = parseInt(data.data[0].TotalRecord);
            } else {
                this.dataGrid = [];
                this.totals = 0;
            }
        });
    }

    onEdit(row) {
        // console.log("==== Edit W17F1555=====");
        const uName = Config.profile.UserID || '';
        const uLanguage = Config.language || '';
        const query = {
            "Language": uLanguage,
            "UserID": uName,
            "FormID": 'W17F1555',
            "Key01ID": row.ListTypeID,
            "Key02ID": row.CodeID,
        };
        const rowEdit = {
            listTypeID: row.ListTypeID,
            codeID: row.CodeID,
            codeName: row.CodeName,
            remark: row.Remark,
            displayOrder: row.DisplayOrder,
            isDefault: row.IsDefault,
            disabled: row.Disabled,
        };
        this.props.w17Actions.checkEditW17F1555(query, (error, data) => {
            let message = "";
            if (data) {
                const result = data[0];
                if (result !== null) {
                    let status = result.Status;
                    let msgAsk = result.MsgAsk;
                    message = result.Message;
                    if (status === 0) {
                        this.setState({row: rowEdit, showPopup: true, formState: 'edit'});
                    } else {
                        if (msgAsk === 0) {
                            Config.popup.show('INFO', message);
                        } else {
                            Config.popup.show("YES_NO", message,
                                () => {
                                    this.setState({row: rowEdit, showPopup: true, formState: 'edit'});
                                }
                            );
                        }
                    }
                } else {
                    Config.popup.show('INFO', Config.lang("CRM_Store_kiem_tra_bi_loi"));
                }
            } else {
                message = Config.lang("CRM_Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
            }
        });
    }

    onAddNew() {
        // console.log("==== onAddNew =====");
        this.setState({showPopup: true});
        var rowNew = {
            listTypeID: this.state.listTypeID,
            codeID: '',
            codeName: '',
            remark: '',
            displayOrder: 0,
            isDefault: false,
            disabled: false,
        };
        this.setState({row: rowNew, formState: 'add'});
    }


    onCheckDelete(query, cb) {
        this.props.w17Actions.checkDeleteW17F1555(query, (error, data) => {
            let message = "";
            if (data) {
                const result = data[0];
                if (result !== null) {
                    let status = result.Status;
                    let msgAsk = result.MsgAsk;
                    message = result.Message;
                    if (status === 0) {
                        cb && cb();
                    } else {
                        if (msgAsk === 0) {
                            Config.popup.show('INFO', message);
                        } else {
                            Config.popup.show("YES_NO", message,
                                () => {
                                    cb && cb();
                                }
                            );
                        }
                    }
                } else {
                    Config.popup.show('INFO', Config.lang("CRM_Store_kiem_tra_bi_loi"));
                }
            } else {
                message = Config.lang("CRM_Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
            }
        });
    };

    onDelete(row) {
        const uName = Config.profile.UserID || '';
        const uLanguage = Config.language || '';
        Config.popup.show("YES_NO", `${Config.lang("CRM_Ban_co_chac_chan_muon_xoa_du_lieu_nay")}`, () => {
            const query = {
                "Language": uLanguage,
                "UserID": uName,
                "FormID": 'W17F1555',
                "Key01ID": row.ListTypeID,
                "Key02ID": row.CodeID,
            };
            this.onCheckDelete(query, () => {
                const param = {
                    "listTypeID": row.ListTypeID,
                    "CodeID": row.CodeID,
                };
                this.props.w17Actions.deleteW17F1555(param, (error, data) => {
                    if (error) {
                        let message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                        Config.popup.show('INFO', message);
                        return false;
                    }
                    if (data && data.code === 200) {
                        notify(Config.lang("CRM_Du_lieu_da_xoa_thanh_cong"), "success", 2000);
                        this.loadGrid();
                    }
                });
            });
        });
    }

    hideAddNewPopup() {
        // console.log("==== hideAddNewPopup =====");
        this.setState({showPopup: false});
    }

    onChangeCombo(data) {
        // console.log('== onChangeCombo => keyID: ', data);
        // this.listTypeID = data.ListTypeID;
        this.setState({
            listTypeID : data.ListTypeID
        });
        this.filter = {
            skip: 0,
            limit: 20,
            search: ''
        };

        this.loadGrid();
    }

    onChangePage(page) {
        // console.log('== onChangePage => page: ', page);
        this.filter.skip = page * this.filter.limit;
        this.loadGrid();
    }

    onChangePerPage(per) {
        // console.log('== onChangePerPage => per: ', per);
        this.filter.skip = 0;
        this.filter.limit = per;
        this.loadGrid();
    }

    onSearch(text) {
        // console.log('== onSearch => text: ', text);
        this.filter.skip = 0;
        this.filter.search = text;
        this.loadGrid();
    }

    renderAction(e) {
        if (!e) return null;
        return (
            <GridActionBar
                isPer={this.state.iPermission}
                tooltipEdit={Config.lang("CRM_Sua")}
                onEditAction={() => {
                    this.onEdit(e.row.data)
                }}

                tooltipDelete={Config.lang("CRM_Xoa")}
                onDeleteAction={() => {
                    this.onDelete(e.row.data)
                }}/>
        );
    }

    render() {
        return (
            <div className="page-container">
                {Config && Config.profile &&
                <EventTracking category={"TRACKING_USER"} action={"W17F1555"} label={Config.profile.UserID}/>}

                <FormGroup style={cssForm}>
                    <Row className={'mgr0'}>
                        <Col sm={5}>
                            <Combo
                                placeholder={Config.lang("CRM_Nhom_kinh_doanh")}
                                showHeader={false}
                                dataSource={this.ComboType}
                                value={this.state.listTypeID}
                                valueExpr="ListTypeID"
                                displayExpr="ListTypeName"
                                onActionWhenSelectChange={(value) => {
                                    this.onChangeCombo(value)
                                }}
                            >
                                <Column dataField={'ListTypeID'} caption="" visible={false} dataType={'string'}/>
                                <Column dataField={'ListTypeName'} caption="" width={'100%'}/>
                            </Combo>
                        </Col>

                    </Row>
                </FormGroup>
                <FormGroup>
                    <GridContainer
                        title={Config.lang("CRM_Quan_ly_danh_muc")}
                        isPer={this.state.iPermission}
                        dataSource={this.dataGrid}
                        keyExpr={'CodeID'}
                        showBorders={false}
                        showColumnLines={true}
                        selection={{mode: 'single'}}
                        allowColumnReordering={true}
                        rowAlternationEnabled={true}
                        onAddNew={() => {
                            this.onAddNew()
                        }}
                        totalItems={this.totals}
                        itemPerPage={this.filter.limit}
                        onSearch={(text) => {
                            this.onSearch(text)
                        }}
                        onChangePage={(page) => {
                            this.onChangePage(page)
                        }}
                        onChangePerPage={(per) => {
                            this.onChangePerPage(per)
                        }}
                    >
                        <Column dataField={'ListTypeID'} caption="" visible={false} width={300} dataType={'string'}
                                fixed={true}/>
                        <Column dataField={'DisplayOrder'} caption={Config.lang("CRM_STT")} minWidth={50}
                                alignment='center' width={100}/>
                        <Column dataField={'CodeID'} caption={Config.lang("CRM_Ma")} width={150} dataType={'string'}/>
                        <Column dataField={'CodeName'} caption={Config.lang("CRM_Ten")} width={300}/>
                        <Column dataField={'Remark'} caption={Config.lang("CRM_Ghi_chu")} minWidth={300}
                                width={"100%"}/>
                        <Column dataField={'IsDefault'} caption={Config.lang("CRM_Mac_dinh")} width={100}
                                dataType={'boolean'}/>
                        <Column dataField={'Disabled'} caption={Config.lang("CRM_KSD")} width={100}
                                dataType={'boolean'}/>
                        <Column
                            cellRender={(data) => this.renderAction(data)}
                            dataField={'Action'}
                            caption={Config.lang("CRM_Hanh_dong")}
                            alignment={'center'}
                            allowSorting={false}
                            showInColumnChooser={false}
                            width={120}
                            fixed={true}
                        />
                    </GridContainer>
                </FormGroup>
                {this.state.showPopup && <PopupView
                    title={Config.lang("CRM_Cap_nhat_danh_muc")}
                    show={true}
                    animation={true}
                    onHide={() => this.hideAddNewPopup()}>
                    <Detail data={this.state.row} formState={this.state.formState} onHide={() => this.hideAddNewPopup()}
                            onReloadGrid={() => this.loadGrid()}/>
                </PopupView>}

            </div>
        )
    }
}

export default connect(state => ({
        loadW17F1555: state.w17.loadW17F1555,
        loadW17F1555_Combo: state.w17.loadW17F1555_Combo,
        deleteW17F1555: state.w17.deleteW17F1555,
        checkStore: state.w17.checkStore
    }),
    (dispatch) => ({
        w17Actions: bindActionCreators(w17Actions, dispatch),
        userActions: bindActionCreators(userActions, dispatch)
    })
)(W17F1555Page);
